import React, { useState } from "react";
import { Page, Card, TextField, Button, Stack, InlineError, FormLayout } from "./Polaris";
import { useApiKey, useFetch } from "../contexts/Auth";
import { useRedirect } from "../contexts/Route";

function isValueValid(content) {
  return content && content.length > 2;
}

export default () => {
  const apiKey = useApiKey();
  const [url, setUrl] = useState("");
  const [isValid, setIsValid] = useState(true);
  const redirect = useRedirect();
  const params = new URLSearchParams(window.location.search);
  const fetch = useFetch();
  return (
    <Page>
      <Card>
        <Card.Section>
          <Button url={`https://www.shopify.com/admin/apps/${apiKey}`} primary>
            Login with Shopify
          </Button>
        </Card.Section>
        <Card.Section title="Shop Name">
          <FormLayout>
            <FormLayout.Group>
              <TextField
                value={url}
                onChange={setUrl}
                suffix=".myshopify.com"
                prefix="https://"
                placeholder="mystore.myshopify.com"
                error={!isValid && "Store name is required"}
              />
              <Button primary onClick={() => {
                setIsValid(isValueValid(url));
                if (isValueValid(url)) {
                  return fetch(`/api/login?shop=${url}${params.get("referral") ? `&referral=${encodeURIComponent(params.get("referral"))}` : ""}`)
                    .then(({ url: redirectUrl }) => {
                      redirect(redirectUrl);
                    });
                }
              }}>Install</Button>
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
    </Page>
  );
};
