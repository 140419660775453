import React, { useContext, createContext, useMemo } from "react";
import { useShop, useIsEmbedded } from "./Auth";
import { BrowserRouter, Switch, Route, useHistory, useLocation } from "react-router-dom";

export function locationsAreEqual(url, history) {
  // Local link
  if (url.indexOf("//") == 0 || url.indexOf("/") != 0)
    return false;
  url = new URL(url, window.location.href);
  return url.pathname == history.location.pathname && url.search == history.location.search;
}

export function isValidRoute(route, shop) {
  return (shop && route.access !== "anonymous") || (!shop && route.access === "anonymous") || route.access === "all";
}

export const useRedirect = () => {
  const history = useHistory();
  const isEmbedded = useIsEmbedded();
  const shopOrigin = useShop();
  return (url, newWindow) => {
    var groups = /^shopify:\/\/admin(.*?)$/.exec(url);
    const mappedUrl = groups ? `https://admin.shopify.com/store/${shopOrigin?.replace(/\.myshopify\.com/, "")}${groups[1]}` : url;
    if (/^(https?:)?\/\//.test(mappedUrl)) {
      if (newWindow)
        window.open(mappedUrl)
      else if (isEmbedded) {
        // Must be this way, due to Shopify's app bridge retaining control over the parent frame.
        const a = document.createElement('a');
        a.target = "_top";
        a.href = url;
        a.click();
      } else
        window.location = mappedUrl;
    } else if (!locationsAreEqual(url, history)) {
      if (newWindow)
        window.open(url);
      else
        history.push(url);
    }
  };
};

const RouteContextInternal = createContext({ validRoutes: [], displayRoutes: [] });

export function RouteContext({ routes, children }) {
  const shop = useShop();

  const routeContextData = useMemo(() => {
    const validRoutes = routes.filter((r) => isValidRoute(r, shop)).map(({ items = [], ...rest }) => ({
      ...rest,
      items: items.filter((i) => isValidRoute(i, shop)),
    }));
    validRoutes.forEach((r) => r.items.forEach((i) => i.parent = r));

    const displayRoutes = validRoutes
      .filter(({ path, name }) => !/:/.test(path) && name)
      .map(({ items = [], path, ...rest }) => ({
        ...rest,
        items: items.filter(({ path: subPath }) => !/:/.test(subPath)),
        path: path || items.find((item) => item.path)?.path,
      }));

    return { validRoutes, displayRoutes };
  }, [shop, routes]);

  return (
    <BrowserRouter>
      <RouteContextInternal.Provider value={routeContextData}>
        {children}
      </RouteContextInternal.Provider>
    </BrowserRouter>
  );
}

export function useValidRoutes() {
  return useContext(RouteContextInternal).validRoutes;
}

export function useDisplayRoutes() {
  return useContext(RouteContextInternal).displayRoutes;
}

export function useRoute() {
  // Get metainformation abotu the current route.
  const location = useLocation();
  const routes = useValidRoutes();
  const path = location.pathname.replace(/\/$/, "")
  return routes.flatMap((r) => [r, ...(r.items || [])]).filter((i) => 
    i.path && 
    (
      (!i.exact && path.indexOf(i.path) == 0) || 
      (i.exact && path == i.path)
    ) 
  ).sort((a,b) => b.path.length - a.path.length);
}

export function ContentForRoute() {
  const validRoutes = useValidRoutes();

  return (
    <Switch>
      {validRoutes.flatMap(({ exact = false, path, render, items = [] }) => [
        path && <Route exact={exact} path={path} render={render} key={path} />,
        ...items
          .filter(({ path: subPath }) => subPath != null)
          .map(({ exact: subExact = false, path: subPath, render: subRender }) => (
            <Route exact={subExact} path={subPath} render={subRender} key={subPath} />
          )),
      ])}
    </Switch>
  );
}

export default RouteContext;
export { useLocation, useHistory, Prompt, useParams } from "react-router-dom";
