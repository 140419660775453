/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";

import { Link, Banner } from "./Polaris";
import { useProfile } from "../contexts/Auth";
import { useRedirect } from "../contexts/Route";

export default () => {
  const [profile] = useProfile();
  const activeSubscriptions = profile?.shop?.subscriptions;
  const activeSubscription = (activeSubscriptions || []).find((s) => s.status ==='active');
  const pendingSubscription = (activeSubscriptions || []).find((s) => s.status === "pending");
  const pendingUsageCharge = (activeSubscriptions || []).find((s) => s.update_capped_amount_url ?  s.update_capped_amount_url !== "" && s.update_capped_amount_url !== null : false);
  const redirect = useRedirect();

  return (<>
    {activeSubscription && new Date(activeSubscription.trial_end_date) > new Date() && (
      <Banner title={`Trial expires in ${activeSubscription[0].trial_days_left} days`} status="info" />
    )}
    {pendingSubscription && <Banner title="Changes to your subscription" status={activeSubscription ? "warning" : "error"} action={{
      content: "Approve Charge",
      onAction: () => { redirect(pendingSubscription.confirmation_url); }
    }}>
      There is a pending charge on your account that must be accepted to activate your new subscription. Please click{" "}
      <Link external url={pendingUsageCharge.confirmation_url}>
        here
      </Link>{" "}
      to be redirected to Shopify to accept the charge. Note, you will need appropriate permissions, if you encounter an error
      please ask the Shopify account owner to perform with action.
    </Banner>}
    {pendingUsageCharge && (
      <Banner title="Changes to your usage charge limit" status={activeSubscription ? "warning" : "error"} action={{
        content: "Approve Update",
        onAction: () => { redirect(pendingUsageCharge.update_capped_amount_url); }
      }}>
        There is a pending usage charge cap update on your account that must be accepted. Please click{" "}
        <Link external url={pendingUsageCharge.update_capped_amount_url}>
          here
        </Link>{" "}
        to be redirected to Shopify to accept the charge. <b>You will not be charged at this time.</b> This will only update your potential usage charge ceiling. Note, you will need appropriate permissions, if you encounter an error
        please ask the Shopify account owner to perform with action.
      </Banner>
    )}
  </>);
};
