import React, { useState, useEffect } from "react";
import { Card, Button, Spinner, Select, Tabs, FormLayout, Stack, Form, Icon, TextField, Page, EmptyState, Modal, TextContainer } from "./Polaris";
import { DeleteMajor } from "../Icons";
import { Prompt } from "../contexts/Route";
import { MultiTableForm, MultiTable } from "./MultiTable";
import { useProfile, useFetch } from "../contexts/Auth";
import { useCreateModal } from "../contexts/Overlay";

export function canRead(user, permission) {
  return user.permissions !== 0 && (user.permissions === null || user.permissions[permission]);
}

export function canWrite(user, permission) {
  return user.permissions !== 0 && (user.permissions === null || user.permissions[permission] === "write");
}

export function canDo(user, permission, action) {
  return user.permissions !== 0 && (user.permissions === null || user.permissions[permission] === action);
}

export function NeedPermissionPage() {
  return (
    <Page>
      <Card sectioned>
        <EmptyState heading="You don't have permission to access this page.">
          <p>Please contact the Shopify account owner for more details.</p>
        </EmptyState>
      </Card>
    </Page>
  );
}

export function PermissionGuard({ permission, children, loading }) {
  const [profile] = useProfile();
  if (profile && !canRead(profile.user, permission)) return <NeedPermissionPage />;
  if (!profile || loading)
    return (
      <Page>
        <Stack alignment="center" distribution="center">
          <Spinner size="large" />
        </Stack>
      </Page>
    );
  if (typeof children === "function") return children();
  return children;
}

export function PermissionGroupEditor({ permissions, permissionGroup, setPermissionGroup }) {
  return [
    <Card.Section title="Name">
      <TextField
        value={permissionGroup.name}
        onChange={(val) => {
          setPermissionGroup({ ...permissionGroup, name: val });
        }}
      />
    </Card.Section>,
    <Card.Section title="Permissions">
      <MultiTable
        headings={["Name", "Description", "Access"]}
        rows={permissions.map((p) => [
          p.name,
          p.description || `Allows access to ${p.name.toLowerCase()}.`,
          <Select
            disabled={permissionGroup.global}
            onChange={(val) => {
              setPermissionGroup({ ...permissionGroup, [p.handle]: val });
            }}
            options={
              p.options || [
                { label: "No Access", value: "none" },
                { label: "Read", value: "read" },
                { label: "Write", value: "write" },
              ]
            }
            value={permissionGroup[p.handle] || "none"}
          />,
        ])}
      />
    </Card.Section>,
  ];
}

export function PermissionGroupTable({ users, permissions, permissionGroups, setPermissionGroups, editingGroup, setEditingGroup }) {
  return (
    <MultiTableForm
      resourceName={{ singular: "permission", plural: "permissions" }}
      onNew={() => {
        setEditingGroup({});
      }}
      onSave={() => {
        const { idx: originalIdx, ...newEditingGroup } = editingGroup;
        if (originalIdx !== undefined) {
          setPermissionGroups(permissionGroups.map((e, idx) => (idx !== originalIdx ? e : newEditingGroup)));
        } else {
          setPermissionGroups([...permissionGroups, newEditingGroup]);
        }
        setEditingGroup(null);
      }}
      onRowClick={(e, idx) => {
        setEditingGroup({ ...permissionGroups[idx], idx });
      }}
      editingForm={
        <PermissionGroupEditor permissions={permissions} permissionGroup={editingGroup} setPermissionGroup={setEditingGroup} />
      }
      isEditing={editingGroup !== null}
      hideTable={editingGroup !== null}
      headings={["Name", "Applied Users", ""]}
      rows={permissionGroups.map((g, idx1) => [
        g.name,
        users.filter((u) => g.id && u.permission_id === g.id).length,
        !g.global ? (
          <Stack distribution="trailing">
            <Button
              onClick={(e) => {
                setPermissionGroups(permissionGroups.filter((_, idx2) => idx2 !== idx1));
                e.stopPropagation();
              }}
            >
              <Icon source={DeleteMajor} color="base" />
            </Button>
          </Stack>
        ) : (
          ""
        ),
      ])}
    />
  );
}

export function PermissionSelector({ permissionGroups, value, ...props }) {
  return (<Select
    value={value == null ? "null" : `${value}`}
    options={[
      { label: "No Access", value: "0" },
      ...permissionGroups.map((pg) => ({ label: pg.name, value: `${pg.id}` })),
      { label: "Full Access", value: "null" },
    ]}
    {...props}
  />);
}


export function UserPermissionsEditor({ permissionGroups, users, setUsers }) {
  return (
    <MultiTable
      resourceName={{ singular: "user", plural: "users" }}
      headings={["Email", "Name", "Role", "Permissions"]}
      rows={users.map((u, idx1) => [
        u.email,
        `${u.first_name || ''} ${u.last_name || ''}`,
        (u.account_owner && "Owner") ||
          (u.origin == "api" && "API") ||
          (/(eshopadmin|moddapps)/.test(u.email) && "Support") ||
          (u.collborator && "Collborator") ||
          "Staff",
        <Stack distribution="trailing">
          <PermissionSelector
            permissionGroups={permissionGroups}
            onChange={(val) => {
              setUsers(
                users.map((e, idx2) => (idx1 === idx2 ? { ...e, permission_id: val === "null" ? null : parseInt(val, 10) } : e))
              );
            }}
            disabled={u.origin == "shopify" && (u.account_owner || /(eshopadmin|moddapps)/.test(u.email))}
            value={u.permission_id}
          />
          {u.origin == "api" && <Button onClick={() => {
            setUsers(users.filter((i) => i.id != u.id));
          }}><Icon source={DeleteMajor} color="base" /></Button>}
        </Stack>,
      ])}
    />
  );
}


export function PermissionsPage({ blockNavigationText = "You have unsaved changes. Are you sure you want to discard them?" }) {
  const [tab, setTab] = useState(0);
  const [permissionGroups, setPermissionGroups] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [defaultPermissionId, setDefaultPermissionId] = useState(null);
  const [users, setUsers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [editingGroup, setEditingGroup] = useState(null);
  const [editingAPI, setEditingAPI] = useState(null);
  const [profile] = useProfile();

  const authFetch = useFetch();
  const createModal = useCreateModal();

  useEffect(() => {
    if (!users) {
      authFetch("/api/permissions").then((r) => {
        setUsers(r.users);
        setPermissionGroups(r.permission_groups);
        setPermissions(r.permissions);
        setDefaultPermissionId(r.default_permission_id);
        setIsLoading(false);
      });
    }
  }, [authFetch, users, setIsLoading, setUsers, setPermissionGroups, setPermissions, setDefaultPermissionId]);


  const shouldBlockNavigation = isChanged;

  return (
    <PermissionGuard permission="permissions" loading={isLoading}>
      {() => (
        <Page
          title="Permissions"
          breadcrumbs={
            editingGroup
              ? [
                  {
                    content: "Permissions",
                    onAction: () => {
                      setEditingGroup(null);
                    },
                  },
                ]
              : []
          }
          subtitle="Specify permissions that users have on the application."
          isChanged={isChanged}
          isLoading={isLoading}
          primaryAction={
            !editingGroup && {
              content: "Save",
              disabled: !isChanged,
              loading: isLoading,
              onAction: () => {
                setIsLoading(true);
                authFetch("/api/permissions", {
                  json: {
                    users,
                    permission_groups: permissionGroups,
                    default_permission_id: defaultPermissionId,
                  },
                  method: "PUT"
                }).then((r) => {
                  setIsChanged(false);
                  setUsers(r.users);
                  setPermissionGroups(r.permission_groups);
                  setDefaultPermissionId(r.default_permission_id);
                  setIsLoading(false);
                });
              },
            }
          }
        >
          <Modal
            open={editingAPI != null}
            onClose={() => { setEditingAPI(null); }}
            title="Provision API Token"
            secondaryActions={[{ content: "Close", onAction: () => { setEditingAPI(null); } }]}
            primaryAction={{ content: "Provision", onAction: () => {
              setEditingAPI(null);
              authFetch("/api/permissions", { json: editingAPI }).then((r) => {
                createModal({
                  title: "API Acccess Token",
                  render: () => (
                    <Modal.Section>
                      <TextContainer>
                        <p>Make sure you keep track of this token; you will not be able to view it again.</p>
                        <TextField label="Access Token" value={r.token} readOnly/>
                      </TextContainer>
                    </Modal.Section>
                  )
                })
                setUsers([...users, r]);
              });
            } }}
          >
            <Modal.Section>
              <FormLayout>
                <TextField value={editingAPI && editingAPI.first_name} onChange={(val) => { setEditingAPI({ ...editingAPI, first_name: val }) }} label="API Designation" placeholder="Add some sort of identifier for your API token."/>
                <TextField value={editingAPI && editingAPI.email} onChange={(val) => { setEditingAPI({ ...editingAPI, email: val }) }} label="Contact Email" placeholder="Each API token must be associated with an email."/>
                <PermissionSelector permissionGroups={permissionGroups} value={editingAPI && editingAPI.permission_id} onChange={(val) => { setEditingAPI({ ...editingAPI, permission_id: val }) }} label="Permissions"/>
              </FormLayout>
            </Modal.Section>
          </Modal>
          {shouldBlockNavigation && <Prompt message={() => blockNavigationText} />}
          <Tabs
            tabs={[
              {
                id: "users",
                content: "Users",
              },
              {
                id: "permissions",
                content: "Permissions",
              }
            ]}
            selected={tab}
            onSelect={(val) => {
              setEditingGroup(null);
              setTab(val);
            }}
          >
            {tab === 0 && (
              <Card title="Permissions List">
                <Card.Section title="Default Permissions">
                  <PermissionSelector
                    onChange={(val) => {
                      setDefaultPermissionId(val === "null" ? null : parseInt(val, 10));
                      setIsChanged(true);
                    }}
                    value={defaultPermissionId}
                    permissionGroups={permissionGroups}
                  />
                </Card.Section>
                <Card.Section title="User Permissions" actions={[{content: 'Provision API Token', disabled: !profile || !profile.user.support, onAction: () => {
                  setEditingAPI({ email: profile.user.email, permission_id: defaultPermissionId });
                }}]}>
                  <UserPermissionsEditor
                    permissionGroups={permissionGroups}
                    users={users}
                    setUsers={(u) => {
                      setUsers(u);
                      setIsChanged(true);
                    }}
                  />
                </Card.Section>
              </Card>
            )}
            {tab === 1 && (
              <PermissionGroupTable
                editingGroup={editingGroup}
                setEditingGroup={setEditingGroup}
                users={users}
                permissions={permissions}
                permissionGroups={permissionGroups}
                setPermissionGroups={(val) => {
                  setPermissionGroups(val);
                  setIsChanged(true);
                }}
              />
            )}
          </Tabs>
        </Page>
      )}
    </PermissionGuard>
  );
}
