import React, { useState } from "react";

import { Banner } from "./Polaris";
import { useProfile, useFetch } from "../contexts/Auth";

function MessageBanner({ location, style }) {
  // For snappy dismissal.
  const [dismissed, setDismissed] = useState({});
  const [context, , invalidateProfile] = useProfile();
  const authFetch = useFetch();
  const messages =
    context.user &&
    typeof context.user === "object" &&
    context.user.messages.filter((m) => !dismissed[m.id] && (!location || m.location === location));
  return (
    messages &&
    messages.map((message) => (
      <div key={message.title} style={style}>
        <Banner
          title={message.title}
          status={message.type || "warning"}
          onDismiss={() => {
            setDismissed({ ...dismissed, [message.id]: true });
            authFetch(`/api/profile/messages/${message.id}/dismiss`, { method: "POST" }).then(() => {
              invalidateProfile();
            });
          }}
        >
          {message.message}
        </Banner>
      </div>
    ))
  );
}

export default MessageBanner;
