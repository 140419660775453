import React, { useCallback, useContext, useState, useEffect, useRef, useMemo } from "react";
import { createPortal } from 'react-dom';
import { Modal as PolarisModal, Popover as PolarisPopover, Toast as PolarisToast } from "../components/Polaris";

const OverlayManagerContext = React.createContext(null);

export function OverlayManager({ children }) {
  const [toasts, setToasts] = useState([]);
  const toastTimer = useRef(null);
  const [mounted, setMounted] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modal, setModal] = useState(null);
  const [lastModal, setLastModal] = useState(null);

  useEffect(() => {
    if (toastTimer.current)
      clearTimeout(toastTimer.current);
    if (toasts.length > 0) {
      toastTimer.current = setTimeout(() => {
        toastTimer.current = null;
        setToasts(toasts.slice(1));
      }, toasts[0].duration);
    }
  }, [toasts]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const showToast = useCallback((toast) => {
    setToasts((toasts) => [...toasts, toast]);
    return toast;
  }, []);

  useEffect(() => {
    if (modal)
      setLastModal(modal);
  }, [modal]);

  const payload = useMemo(() => ({setModal, showToast, mounted, setModalOpen}), [setModal, showToast, mounted, setModalOpen]);
  
  return (
    <OverlayManagerContext.Provider value={payload}>
      <div className={`modd-modal-overlays${(modalOpen || modal) && ' modd-open' || ''}`}>
        <Modal 
          open={modal != null} 
          {...(lastModal ? lastModal : {})}
          primaryAction={modal?.primaryAction && { ...modal.primaryAction, onAction: () => { const result = modal.primaryAction.onAction && modal.primaryAction.onAction(); if (result.then) return result.then(() => { setModal(null); }); else setModal(null); } }} 
          secondaryActions={modal?.secondaryActions && modal?.secondaryActions?.map((s) => { return { ...s, onAction: () => { const result = s.onAction && s.onAction(); if (result?.then) result.then(() => { setModal(null); }); else setModal(null); } } })}
          onClose={() => { setModal(null); if(modal?.onClose) modal?.onClose() }} 
        />
      </div>
      {children}
      <div className='modd-toast-overlay'></div>
      {toasts[0] && <Toast {...toasts[0]}>{toasts[0].content}</Toast>}
    </OverlayManagerContext.Provider>
  );
}

export function useCreateModal() {
  const { setModal } = useContext(OverlayManagerContext);
  return useCallback((e) => {
    setModal(e);
  }, [setModal]);
}

export function useCreateToast() {
  const { showToast } = useContext(OverlayManagerContext);
  return useCallback((e) => showToast({ duration: 2000, ...e }), [showToast]);
}

export function Toast({ open = true, ...props }) {
  const [openState, setOpenState] = useState(false);
  const { mounted } = useContext(OverlayManagerContext);
  useEffect(() => {
    if (open) {
      setOpenState("initial");
      setTimeout(() => {
        setOpenState("full");
      }, 50);
    } else if (openState) {
      setOpenState("initial");
      setTimeout(() => {
        setOpenState(false);
      }, 250);
    }
  }, [open]);
  if (mounted)
    return createPortal(<PolarisToast open={openState} {...props}/>, document.querySelector('.modd-toast-overlay'));
  return null;
}

export function Modal({ open, onClose, activator, ...props }) {
  const { mounted, setModalOpen } = useContext(OverlayManagerContext);
  const [openState, setOpenState] = useState(false);
  const escHandler = useRef(null);
  const closeHandler = useRef(null);
  useEffect(() => {
    if (open) {
      setOpenState("initial");
      setTimeout(() => {
        setOpenState("full");
      }, 50);
      setModalOpen(true);
      if (escHandler.current)
        document.body.removeEventListener('keyup', escHandler.current);
      escHandler.current = (e) => { if (e.key == "Escape") onClose(); };
      document.body.addEventListener('keyup', escHandler.current);
      if (closeHandler.current)
        document.querySelector('.modd-modal-background').removeEventListener('click', closeHandler.current);
      closeHandler.current = (e) => { onClose(); };
      document.querySelector('.modd-modal-background').addEventListener('click', closeHandler.current);
      return () => {
        setModalOpen(false);
        if (escHandler.current)
          document.body.removeEventListener('keyup', escHandler.current); 
        if (closeHandler.current)
          document.querySelector('.modd-modal-background').removeEventListener('click', closeHandler.current);
      };
    }  else if (openState) {
      setOpenState("initial");
      setTimeout(() => {
        setOpenState(false);
      }, 250);
      setModalOpen(false);
    }
  }, [open]);
  if (mounted) {
    return <>
      {activator}
      {createPortal(<div className={`modd-modal-overlay${open && " modd-open" || ''}`}><div className='modd-modal-background'/><PolarisModal open={openState} onClose={onClose} {...props}/></div>, document.querySelector('.modd-modal-overlays'))}
    </>;
  }
  return activator ? activator : null;
}

Modal.Section = PolarisModal.Section;
