import React, { useCallback, useState, useEffect, useMemo } from "react";
import { Column1Major, DropdownMinor } from "../Icons";
import { useFetch, useProfile, useShop, useIsEmbedded } from "../contexts/Auth";
import TrialBanner from "./TrialBanner";
import { Stack, Button, Frame, Navigation, Icon, Popover, ActionList } from "./Polaris";
import { ContentForRoute, useDisplayRoutes, useRedirect, useLocation, isValidRoute } from "../contexts/Route";
import { OverlayManager } from "../contexts/Overlay";

function TopNavigationButton({ route, title, preferredAlignment, ...props }) {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const redirect = useRedirect();
  if (!route.items || route.items.length == 0)
    return <Button className='modd-top-navigation-button' {...props} url={route.path}>{title || route.name}</Button>
  const activator = (<Button pressed={popoverVisible} className='modd-top-navigation-button' {...props} pressed={popoverVisible} onClick={() => { setPopoverVisible(!popoverVisible) }} disclosure="select">{title || route.name}</Button>);
  return (<Popover
    active={popoverVisible}
    activator={activator}
    preferredAlignment={preferredAlignment}
    onClose={() => { setPopoverVisible(false); }}
  >
    <ActionList
      actionRole="menuitem"
      {...(!Array.isArray(route) ? {
        items: route.items.map((i) => {
          return { content: i.name, onAction: () => { 
            setPopoverVisible(false);
            redirect(i.url || i.path);
          } };
        })
      } : { 
        sections: route.map((r) => {
          return { title: r.name, items: r.items.map((i) => {
            return { content: i.name, onAction: () => { 
              setPopoverVisible(false);
              redirect(i.url || i.path);
            } };
          }) }
        })
      }) }
    />
  </Popover>);
}

export function TopNavigation({ title, routes, ...props }) {
  const [profile] = useProfile();
  const [mobilePopoverVisible, setMobilePopoverVisible] = useState(false);
  const location = useLocation();
  const shop = useShop();
  const displayRoutes = useDisplayRoutes();
  routes ??= displayRoutes;

  if (profile == null)
    return <></>;
    
  return (<div className="modd-top-nav">
    <Stack distribution="flexEnd" spacing="extraLoose" className="modd-display-desktop" {...props}>
      {routes.map((r, idx) => <TopNavigationButton preferredAlignment={idx == routes.length - 1 && "right"} key={`${r.name}-${r.path || r.url}`} route={r}/>)}
    </Stack>
    <Stack className='modd-display-mobile' distribution="flexEnd">
      <TopNavigationButton key='navigation-mobile' title={title || "Navigation"} route={routes}/>
    </Stack>
  </div>);
}

export function SideNavigation({ embedded, routes }) {
  const [profile] = useProfile();
  const location = useLocation();
  const shop = useShop();
  const displayRoutes = useDisplayRoutes();
  routes ??= displayRoutes;
  const isEmbedded = useIsEmbedded();
  embedded ??= isEmbedded;

  routes = routes.filter((r) => r.name && isValidRoute(r, shop));

  const nonEmbeddedNavigation = useMemo(() => !embedded && <Navigation location={location.pathname}>
    <Navigation.Section
      items={routes.map(({ name, exact, path, icon = Column1Major, items = [] }) => ({
        label: name,
        exactMatch: exact,
        url: path || items?.find((i) => i.path)?.path || null,
        icon,
        items: items.filter((i) => isValidRoute(i, shop)).map(
          ({ name: subName, exact: subExact = false, path: subPath, url: subUrl, icon: subIcon = Column1Major }) => ({
            label: subName,
            exactMatch: subExact,
            url: subUrl || subPath,
            icon: subIcon,
          })
        ),
      }))}
    />
  </Navigation>, [routes, location]);

  const primaryLink = routes.filter(({ path }) => path && path === "/").map(({ name }) => ({ content: name, url: "/" }))[0];
  const secondaryLinks = routes.filter((e) => e !== primaryLink);
  const embeddedNavigation = useMemo(() => embedded && (<ui-nav-menu key={location.pathname + location.search}>
    {primaryLink && <a key="primary-link" href={primaryLink.url} rel="home">${primaryLink.name}</a>}
    {secondaryLinks.flatMap(({ name, path, external, items }) => {
      const validItems = (items || []).filter(
        ({ subPath, access: subAccess }) =>
          !/:/.test(subPath) &&
          (shop || subAccess === "all") &&
          !(shop && subAccess === "anonymous")
      );
      const isActive = location.pathname == path || validItems.filter((i) => (i.path || i.url) == location.pathname).length > 0;
      const element = (<a key={`secondary-links-${name || ''}-${path || ''}`}  href={path || (validItems[0] && (validItems[0].url || validItems[0].path)) || "/"}>{name}</a>);
      return [element, ...(isActive ? validItems.map(({ name, path, onAction, url, external }) => {
        return <a key={`tertiary-links-${name || ''}-${path || ''}`} href={url || path}>&nbsp;&nbsp;&nbsp;{name}</a>;
      }) : [])];
    })}
  </ui-nav-menu>), [routes, location])
  
  return embeddedNavigation || nonEmbeddedNavigation || <></>;
}

export default ({ children, sideNavigation, topNavigation }) => {
  const location = useLocation();
  const [profile] = useProfile();
  const shop = useShop();
  const embedded = useIsEmbedded();

  if (!profile && shop)
    return null;

  return (<div style={{ display: "flex", flexDirection: "column" }} className='modd-full-screen'>
    {embedded && topNavigation}
    {embedded && sideNavigation}
    <Frame style={{ flexGrow: "1" }} navigation={!embedded && sideNavigation}>
        <div style={{ overflow: "hidden", maxWidth: "calc(100% - 0.2rem)" }}>
          <TrialBanner />
        </div>
        {children || <ContentForRoute/>}
    </Frame>
  </div>);
};
