import React from "react";
import { AuthProvider, useProfile, useFetch } from "./contexts/Auth";
import { PolarisContext } from "./components/Polaris";
import { Zendesk } from "./components/Zendesk";
import { OpenReplay } from "./components/OpenReplay";

import "./styles.scss";

export function getEnvironment() {
  return document.querySelector('meta[name="environment"]')?.content || "development";
}

function App({
  apiKey = (document.querySelector('meta[name="shopify-api-key"]') || {}).content,
  shop,
  urlToken,
  embedded,
  openReplay,
  zendesk,
  children
}) {
  const urlParams = new URLSearchParams(window.location.search);
  embedded ??= urlParams.get("embedded");
  // This bit with tokens entirely for watchtower logins; regular Shopify supplies `id_token`, and a token via the app bridge.
  urlToken ??= urlParams.get("token");
  if (!embedded) {
    if (urlToken)
      sessionStorage.setItem("token", urlToken)
    else
      urlToken = sessionStorage.getItem("token");
  }
  shop ??= urlParams.get("shop") ?? (urlToken && JSON.parse(atob(urlToken.split(/\./)[1])).dest.replace(/^\w+:\/\//, ""));
  return (<AuthProvider apiKey={apiKey} shop={shop} embedded={embedded} urlToken={urlToken}>
    <PolarisContext>
      {children}
    </PolarisContext>
    {openReplay || <OpenReplay/>}
    {zendesk || <Zendesk/>}
  </AuthProvider>);
}

export default App;
