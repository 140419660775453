/* eslint-disable no-console, react/jsx-props-no-spreading */

import React, { useContext, useState, useCallback, useEffect } from "react";

import { Button, Card, Modal, TextContainer, Spinner, Stack, toDate } from "./Polaris";
import { MultiTable } from "./MultiTable";

import { useProfile, useFetch } from "../contexts/Auth";
import { useRedirect, useLocation, useHistory } from "../contexts/Route";
import { useCreateModal } from "../contexts/Overlay";

export default ({ subscriptions }) => {
  const authFetch = useFetch();
  const location = useLocation();
  const redirect = useRedirect();
  const createModal = useCreateModal();
  const [profile, profileError, invalidateProfile] = useProfile();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get("confirm")) {
      authFetch(`/api/charges/${query.get("confirm")}/confirm`).then(() => {
        invalidateProfile();
      })
    }
  }, [authFetch, location, invalidateProfile]);

  return subscriptions && subscriptions.length > 0 ? (
    <Card title="Subscription History" actions={[{ onAction: invalidateProfile, content: "Reload" }]}>
      <MultiTable
        headings={["Name", "Status", "Amount", "Billing", "Created", "Trial Days", "Trial Ends", "Cancelled", ""]}
        rows={subscriptions && subscriptions.map((subscription) => [
          subscription.name,
          subscription.state,
          `$${subscription.cost}`,
          subscription.annual ? "Annualy" : "Monthly",
          toDate(subscription.date_subscribed),
          subscription.trial_days,
          toDate(subscription.trial_end_date),
          subscription.date_unsubscribed ? toDate(subscription.date_unsubscribed) : "N/A",
          <Stack wrap={false} distribution="trailing">
            {!subscription.active && (
              <Button key="activate" onClick={() => { redirect(subscription.confirmation_url); }} primary disabled={!profile.user.account_owner}>
                Activate{!profile.user.account_owner ? " (Requires Owner)" : ""}
              </Button>
            )}
            {profile && profile.user && profile.user.support && (
              <Button key="cancel" destructive onClick={() => {
                createModal({
                  title: "Cancel Subscription?",
                  content: <p>Are you sure you want to cancel your subscription? This action can’t be undone.</p>,
                  primaryAction: {
                    content: "Cancel",
                    destructive: true,
                    onAction: (() => authFetch(`/api/subscriptions/${subscription.id}/unsubscribe`, { method: "POST" }).then((r) => {
                      invalidateProfile();
                    }))
                  }
                })
              }}>
                Cancel
              </Button>
            )}
          </Stack>
        ])}
      />
    </Card>
  ) : (
    <></>
  );
};
