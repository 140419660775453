import React, {useState, useEffect, useCallback, useMemo, useRef, Children } from 'react';
import {
  Layout,
  Heading,
  Button,
  Label,
  Select,
  FormLayout,
  RadioButton,
  Badge,
  ButtonGroup,
  Icon,
  Spinner,
  TextField,
  Tooltip,
  Thumbnail,
  Banner,
  OptionList,
  DateTimePicker,
  toLocaleDateTime
} from 'admin-frontend';
import {  MultiTableForm, MultiTable, rearrangeArray, Auth, useRedirect, Stack, useCreateToast, Link, Card } from "admin-frontend";
import { useSpotFetch } from "../../useSpotFetch";
import { FieldSpecifier, productFields, getOperators, JSONField } from "../../components/Fields"
import { Page, PlaceholderImage } from "../../components/Page"
import { DeleteMajor, RiskMajor, CircleInformationMajor, AddMajor, DragHandleMinor, CancelSmallMinor } from 'admin-frontend';
import { ResourcePickerButton } from "../../components/ResourcePicker";
import { useSpotAPI, useSpot } from "../../components/API";
import { localeFormat, LocalizedBadgeList } from "../../components/Localization";
import { UpgradeBanner, SDKUpgradeBanner } from "../../components/UpgradeBanner";
import { BannerPickerButton, BannerTile } from "../Banners";
import { useQuickStartStatus, useThemes } from "../../components/StatusContext";
import { useParams } from "react-router-dom";
import { ValueSelector } from '../../components/ValueSelector';


export function Scheduling() {
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [schedules, setSchedules] = useState(null);
  const [rulesets, setRulesets] = useState(null);
  const [logs, setLogs] = useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);
  const [collections, setCollections] = useState({});
  const [editingSchedules, setEditingSchedules] = useState(null);

  const authFetch = useSpotFetch();
  const spotAPI = useSpotAPI();

  
  useEffect(() => {
    if (!rulesets) {
      authFetch('/api/global/rulesets').then((r) => {
        setRulesets(r.rulesets);
      });
    }
  }, [authFetch]);

  const rulesetHash = rulesets && Object.fromEntries(rulesets.map((r) => [r.id, r]));

  useEffect(() => {
    if (!schedules) {
      setIsLoading(true);
      authFetch("/api/setup/scheduling", { query: { synched: 0 } })
        .then((r) => {
          setSchedules(r.schedules);
          setIsLoading(false);
        });
    }
  }, [authFetch, schedules]);
  useEffect(() => {
    if (!schedules) {
      setIsLoading(true);
      authFetch("/api/setup/scheduling", { query: { synched: 1 } })
        .then((r) => {
          setLogs(r.schedules);
          setIsLoading(false);
        });
    }
  }, [authFetch, schedules]);

  useEffect(() => {
    const toGet = ((schedules || []).concat(logs || [])).filter((r) => (r.target_id && /collection/.test(r.target_type) && !collections[r.target_id]));
    if (toGet.length > 0) {
      spotAPI.s().category("spot-internal").targets(["collections"]).id("in", toGet.map((t) => t.target_id)).e().done(function(c) {
        setCollections({ ...collections, ...Object.fromEntries(c.map((c) => [c.id, c])) });
      });
    }
  }, [schedules, logs, collections]);

  const translateTarget = (schedule, collections) => {
    if (schedule.target_type == "default")
      return "Site-Wide Default Rule";
    if (schedule.target_type == "search")
      return "Search-Specific Rule";
    if (schedule.target_type == "collection" || schedule.target_type == "collection-view")
      return collections && collections[schedule.target_id] ? collections[schedule.target_id].title : schedule.target_id;
    return schedule.target_type;
  };

  return (<Page
    disableThemes
    isFullyLoading={!schedules}
    permission="rules"
    resourceName={{
      singular: "operation",
      plural: "operations"
    }}
    isChanged={isChanged}
    onBack={editingSchedules && (() => { setEditingSchedules(null); setIsChanged(false); })}
    isFullyLoading={isLoading}
  >
    {editingSchedules && (
      <Card>
        {editingSchedules.map((editingSchedule, idx1) => {
          const setEditingSchedule = ((e1) => {
            setEditingSchedules(editingSchedules.map((e2, idx2) => idx1 == idx2 ? e1 : e2));
          });
          return (<Card.Section title={"Operation " + (idx1 + 1)} actions={idx1 > 0 && [{ content: <Button plain square onClick={() => { setEditingSchedules(editingSchedules.filter((e) => e != editingSchedule)); }}><Icon source={CancelSmallMinor}/></Button> }]}>
            <FormLayout>
              <FormLayout.Group>
                <DateTimePicker label='Scheduled At' min={new Date()} value={editingSchedule.scheduled_at} onChange={(val) => { 
                  setIsChanged(true); 
                  setEditingSchedule({ ...editingSchedule, scheduled_at: val }) 
                }}/>
                <Select label="Type" value={editingSchedule.target_type} onChange={(val) => { setEditingSchedule({ ...editingSchedule, target_type: val }); }} options={[
                  { label: "Set Site-Wide Rule", value: "default" },
                  { label: "Set Search-Specific Rule", value: "search" },
                  { label: "Set Collection Rule", value: "collection" },
                  { label: "Set Collection Primary View", value: "collection-view" }
                ]}/>
              </FormLayout.Group>
              {(editingSchedule.target_type == "collection-view" || editingSchedule.target_type == "collection") && <div>
                <Label>Affected Collections</Label>
                <ResourcePickerButton value={Array.isArray(editingSchedule.target_id) ? editingSchedule.target_id : [editingSchedule.target_id].filter((f) => f != null)} onSelection={(collections) => { setEditingSchedule({ ...editingSchedule, target_id: collections.selection.map((c) => c.id) }); }} selectMultiple={true} resourceType="collection"/>
              </div>}
              {editingSchedule.target_type == "collection-view" && <TextField label="View Handle" value={editingSchedule.target_string} error={!/^[a-z0-9\-_]*$/.test(editingSchedule.target_string) && "Please enter only [a-z0-9_-]."} onChange={(val) => { setEditingSchedule({ ...editingSchedule, target_string: val }); }}/>}
              {editingSchedule.target_type != "collection-view" && <Select label="Display Rule"
                options={[
                  { label: "Situation Default", value: "0" },
                  ...rulesets.map((r) => { return { label: r.handle, value: r.id + "" } })
                ]}
                value={editingSchedule.ruleset_id + ""}
                onChange={(val) => { setEditingSchedule({ ...editingSchedule, ruleset_id: parseInt(val) }) }}
              />}
            </FormLayout>
          </Card.Section>);
        })}
        <Card.Section>
          <FormLayout>
            <FormLayout.Group>
              <Button fullWidth key="add" onClick={() => {
                setEditingSchedules([...editingSchedules, { ...editingSchedules[editingSchedules.length - 1] }]);
              }}>+ Add Scheduled Operation</Button>
              <Button key="submit" fullWidth disabled={editingSchedules.filter((r) => r.scheduled_at == null).length > 0} onClick={() => { 
                setIsLoading(true);
                authFetch("/api/setup/scheduling", { json: { schedules: editingSchedules } }).then((r) => {
                  setEditingSchedules(null);
                  setSchedules(null);
                  setIsLoading(false);
                }) } 
              } primary>{editingSchedules.length > 1 ? "Schedule Operations" : "Schedule Operation"}</Button>
            </FormLayout.Group>
          </FormLayout>
        </Card.Section>
      </Card>
    )}
    {!editingSchedules && (
      <>
        <MultiTableForm
          onNew={() => { setEditingSchedules([{ ruleset_id: 0, target_type: "default" }]); setIsChanged(true); }}
          resourceName={{
            singular: "operation",
            plural: "operations"
          }}
          bulkActions={[{content: "Delete operation", onAction: (idxs) => {  
            setIsLoading(true);
            const ids = idxs.map((i) => schedules[i].id);
            authFetch('/api/setup/scheduling', { method: "DELETE", json: { id: ids } }).then((r) => {
              setSchedules(schedules.filter((o) => !ids.includes(o.id)));
              setIsLoading(false);
            });
          } }]}
          headings={["Type", "Target", "Rule", "Scheduled At"]}
          rows={schedules && schedules.map((r) => [
            "Set Rule",
            translateTarget(r, collections),
            r.target_type == "collection-view" ? 
              r.target_string :
              (r.ruleset_id == 0 ? "Situation Default" : (rulesetHash && rulesetHash[r.ruleset_id] || { handle: "Unknown" }).handle),
            toLocaleDateTime(r.scheduled_at),
          ])}
          onRowClick={(row, idx) => { setEditingSchedules([{ ...schedules[idx] }]); } }
      />
      {!editingSchedules && <Card
        title="Scheduling History"
        sectioned
      >
        <MultiTable
          headings={["Type", "Target", "Rule", "Scheduled At", "Ocurred At"]}
          resourceName={{
            singular: "operation",
            plural: "operation logs"
          }}
          rows={logs && logs.map((r) => [
            "Set Rule",
            translateTarget(r, collections),
            r.target_type == "collection-view" ? 
              r.target_string :
              (r.ruleset_id == 0 ? "Situation Default" : (rulesetHash && rulesetHash[r.ruleset_id] || { handle: "Unknown" }).handle),
            toLocaleDateTime(r.scheduled_at),
            toLocaleDateTime(r.synched_at)
          ])}
        />
      </Card>}
    </>)}
  </Page>);
}

export function ConditionTable({ split, rules, setRules, specifiers = {}, collection = null }) {
  const dragTable = useRef(null);
  const dragging = useRef(null);

  function computeIndex(li) {
    if (li.matches(".condition-table"))
      return [];
    if (li.matches('ul')) {
      li = li.parentElement;
      if (li.matches(".condition-table"))
        return [];
    }
    const ul = li.parentElement;
    return [...computeIndex(ul.parentElement), Array.from(ul.children).indexOf(li)];
  }

  useEffect(() => {
    function getTarget(rules, target) {
      if (target.length == 1)
        return rules[target[0]];
      return getTarget(rules[target[0]].condition, target.slice(1));
    }

    function insertTarget(rules, insertion, target) {
      if (insertion && insertion.length == 0)
        return [...rules, target];
      return rules.map((rule, idx) => insertion[0] == idx ? { ...rule, condition: insertTarget(rule.condition, insertion.slice(1), target) } : rule);
    }

    function stripTarget(rules, remove) {
      if (remove.length == 1)
        return rules.filter((rule,idx) => remove[0] != idx);
      return rules.map((rule, idx) => remove[0] == idx ? { ...rule, condition: stripTarget(rule.condition, remove.slice(1)) } : rule);
    }

    if (dragTable.current) {
      const move = (e) => {
        if (dragging.current) {
          if (e.buttons == 0)
            return release();
          dragging.current.element.style.transform = `translate(${e.pageX - dragging.current.point.x}px, ${e.pageY - dragging.current.point.y}px)`;
          const currentParent = dragging.current.element.closest('.conditions');
          const closest = dragging.current.targets.filter((target) => e.pageY >= target.y && e.pageY < (target.y + target.height)).reverse()[0];
          if (closest != dragging.current.closest) {
            if (dragging.current.closest)
              dragging.current.closest.element.classList.remove('dropping');
            if (closest)
              closest.element.classList.add('dropping');
            dragging.current.closest = closest;
          }
        }
      };
      const release = () => {
        if (dragging.current) {
          dragging.current.element.classList.remove('dragging');
          dragging.current.element.style.transform = '';
          let newRules;
          if (dragging.current.closest) {
            dragging.current.closest.element.classList.remove('dropping');
            const sourceLi = computeIndex(dragging.current.element);
            const targetUl = computeIndex(dragging.current.closest.element);
            const rule = getTarget(rules, sourceLi);
            newRules = insertTarget(rules, targetUl, rule);
            newRules = stripTarget(newRules, sourceLi);
          }
          dragging.current = null;
          document.body.removeEventListener('mousemove', move);
          document.body.removeEventListener('mouseup', release);
          if (newRules) {
            setRules(newRules);
          }
        }
      };
      const mousedown = (e) => {
        if (e.target.closest(".draghandle")) {
          e.preventDefault();
          dragging.current = {
            closest: null,
            element: e.target.closest('li'),
            targets: Array.from(e.target.closest('.condition-table').querySelectorAll('.conditions')).map((e) => {
              const rect = e.getBoundingClientRect();
              return { element: e, y: rect.y + window.scrollY, height: rect.height };
            }),
            point: { x: e.pageX, y: e.pageY }
          };
          dragging.current.element.classList.add('dragging');
          document.body.addEventListener('mousemove', move);
          document.body.addEventListener('mouseup', release);
        }
      };
      dragTable.current.addEventListener('mousedown', mousedown);
      return () => {
        if (dragTable.current)
          dragTable.current.removeEventListener('mousedown', mousedown);
      };
    }
  }, [dragTable, setRules, rules]);

  return (<div ref={dragTable} className='condition-table'><Conditions collection={collection} split={split} specifiers={specifiers} rules={rules} setRules={setRules}/></div>);
}

export function Conditions({ split, rules, setRules, specifiers = {}, depth = 0, collection = null }) {
  return (<ul className='conditions'>{rules.length > 0 ? rules.map((rule, idx) => {
    const composite = rule.relation == "and" ? "and" : (rule.relation == "or" ? "or" : null);
    const field = !composite && productFields.filter((f) => f.handle === rule.column[0])[0];
    let operand = null;
    if (field) {
      if (field.type == "product" || field.type == "collection" || field.type == "variant") {
        operand = (<ResourcePickerButton
          key={`operand-${idx}`}
          resourceType={field.type}
          showHidden={true}
          split={split}
          selectMultiple={true}
          value={rule.condition}
          onSelection={({ selection }) => {
            setRules(rules.map((r) => { return r == rule ? { ...r, condition: selection.map((i) => (Array.isArray(i) ? i[0] : i)[field.type == "product" ? "split_id" : "id"]) } : r }));
          }}
        />);
      } else if (field.type !== "bool" && rule.relation !== "is empty" && rule.relation !== "is not empty" && rule.relation !== "exists" && rule.relation !== "doesn't exist")  {
        operand = (
          <ValueSelector
            collection={collection}
            field={rule.column || []}
            onChange={(val) => {
              rule.condition = val;
              setRules(
                rules.map((r) => {
                  return r == rule ? { ...r, condition: val } : r;
                })
              );
            }}
            value={rule.condition}
            multiple={rule.relation == "equal to" || rule.relation == "not equal to"}
          />
        );
      }
    }

    return (<li key={`li-${idx}`}>
        <Stack wrap={false} alignment="center">
          <div   className='draghandle'>
            <Icon color="default" source={DragHandleMinor}/>
          </div>
          <Stack.Item fill>
            <Stack wrap={false} alignment="center">
              <Stack.Item fill={rule.column[0] == "default"} style={{flexBasis: "500px"}}>
                <FieldSpecifier
                  {...specifiers}
                  split={split}
                  field={rule.column}
                  extraFields={[
                    {name: "Compound", handle: "compound", level: "product", specifiers: [], public: true},
                    {name: "Situation Default", handle: "default", level: "product", specifiers: [], public: true}
                  ]}
                  onChange={(val) => {
                    const field = productFields.filter((f) => f.handle === val[0])[0];
                    if (val == "default") {
                      rule.relation = null;
                      rule.condition = null;
                    } else if (val != "compound") {
                      var operators = getOperators(field);
                      if (!operators.includes(rule.relation))
                        rule.relation = operators[0];
                      if (field.type === "bool" || rule.relation === "is empty" || rule.relation === "is not empty" || field.type === "exists" || field.type === "doesn't exist")
                        rule.condition = ""
                    } else {
                      rule.relation = "and";
                      rule.condition = [];
                    }
                    rule.column = val;
                    setRules(rules.map((r) => { return r == rule ? { ...rule } : r }));
                  }}
                />
              </Stack.Item>
              {rule.column[0] != "default" && <Stack.Item fill={rule.column[0] == "compound"} style={{flexBasis: "500px"}}>
                <Select options={rule.column[0] == "compound" ? [{ label: "AND", value: "and" }, { label: "OR", value: "or" }] : getOperators(field)} onChange={(val) => {
                  setRules(rules.map((r) => r == rule ? { ...rule, relation: val } : r));
                }} value={rule.relation || ""}/>
              </Stack.Item>}
              {operand && <Stack.Item fill>
                {operand ? [operand] : []}
              </Stack.Item>}
            </Stack>
          </Stack.Item>
          <Button type="button" square onClick={() => {
            setRules(rules.filter((r) => r != rule));
          }}><Icon source={DeleteMajor}/></Button>
      </Stack>
      {rule.column[0] == "compound" && (<Conditions collection={collection} split={split} depth={depth+1} rules={rule.condition} setRules={(newRules) => { setRules(rules.map((r) => r == rule ? { ...r, condition: newRules } : r)); }}/>)}
    </li>);
  }) : (depth > 0 && <li className='no-conditions'>No internal conditions, drag conditions here to nest.</li>)}</ul>);
}



export function translateRules(rules) {
  return rules.map((condition) => {
    const normalizedCondition = condition.condition || ''
    var rule = {}
    var target = rule
    for (var i = 0; i < condition.column.length - 1; i++) {
      target[condition.column[i]] = {}
      target = target[condition.column[i]];
    }
    if (condition.column[0] == "default")
      return "default";
    var operator = condition["relation"].replaceAll(/\s+/g, "_");
    if (operator == "and" || operator == "or")
      target[operator] = translateRules(condition.condition);
    else {
      target[condition.column[condition.column.length - 1]] = {}
      if (operator === "is_true")
        target[condition.column[condition.column.length - 1]] = true;
      else if (operator === "is_false")
        target[condition.column[condition.column.length - 1]] = false;
      else if (operator === "is_empty" || operator === "is_not_empty")
        target[condition.column[condition.column.length - 1]] = (operator === "is_empty" ? null : { "!=": null });
      else if (operator === "exists" || operator === "doesn't exist")
        target[condition.column[condition.column.length - 1]] = (operator === "exists" ? { "exists": true } : { "exists": false });
      else if (operator === "less_than")
        target[condition.column[condition.column.length - 1]]["<"] = normalizedCondition;
      else if (operator === "less_than_or_equal_to")
        target[condition.column[condition.column.length - 1]]["<="] = normalizedCondition;
      else if (operator === "greater_than")
        target[condition.column[condition.column.length - 1]][">"] = normalizedCondition;
      else if (operator === "greater_than_or_equal_to")
        target[condition.column[condition.column.length - 1]][">="] = normalizedCondition;
      else if (operator === "equal_to" && Array.isArray(normalizedCondition))
        target[condition.column[condition.column.length - 1]]["in"] = normalizedCondition;
      else if (operator === "not_equal_to" && Array.isArray(normalizedCondition))
        target[condition.column[condition.column.length - 1]]["not_in"] = normalizedCondition;
      else
        target[condition.column[condition.column.length - 1]][operator] = normalizedCondition;
    }
    return rule
  }).filter((condition) => condition != null);
}

function RulesetCharacteristic({ title, children }) {
  return <Stack vertical alignment="stretch">
    <Heading>{title}</Heading>
    {Children.toArray(children).filter((c) => c)}
    <br/>
  </Stack>;
}

export function RulesetEditor({ onBack, onSave, currentTheme, setIsLoading, isLoading, parent, currentLocale, rulesets, onPort, subtitle, title, ruleset, collection, isChanged, setRuleset, facetGroups, badgeGroups, sortOptions, banners, swatches, splits, merges, recommendations, personalizations, boostRules, error, setError }) {
  const [pinnedProducts, setPinnedProducts] = useState({});
  const [antipinnedProducts, setAntipinnedProducts] = useState({});
  const [pinsShown, setPinsShown] = useState(false);
  const [bannersShown, setBannersShown] = useState(false);
  const [showAllMetadata, setShowAllMetadata] = useState(false);
  const [extraMetadata, setExtraMetadata] = useState(null);

  useEffect(() => {
    setExtraMetadata(ruleset.extra ? JSON.stringify(ruleset.extra) : "");
  }, [ruleset]);

  const rulesetHash = rulesets && Object.fromEntries(rulesets.map((r) => [r.id, r]));
  const createToast = useCreateToast();

  const authFetch = useSpotFetch();
  const [profile] = Auth.useProfile();
  const [selectedRecommendation, setSelectedRecommendation] = useState(null);
  const spotAPI = useSpotAPI();
  const spot = useSpot();
  const isSplit = spot.spotDOM.split() && spot.spotDOM.split() != "none" && spot.spotDOM.split() != "auto" && spot.spotDOM.split();
  const isMerged = spot.spotDOM.merge() && spot.spotDOM.merge() != "none" && spot.spotDOM.merge() != "auto" && spot.spotDOM.merge();
  function completelyFlattenRule(rules) {
    return [...rules, ...rules.filter((r) => r.column[0] == "compound").flatMap((r) => r.condition)];
  }
  const hasSituationDefaultRule = ruleset && completelyFlattenRule(ruleset.rules).filter((r) => r.column[0] == "default").length > 0;
  const hasBoostRulesAndPins = ruleset && ((ruleset.pins && ruleset.pins.length > 0) || (ruleset.antipins && ruleset.antipins.length > 0)) && ruleset.boost_rule_ids && ruleset.boost_rule_ids.length > 0;

  function renderDisjunctive(disabled, disjunctive) {
    return (<Stack alignment="center">
      <span>Products must match: </span>
      <RadioButton
        // id="mustMatch"
        key={"all"}
        disabled={disabled}
        checked={!ruleset.disjunctive}
        onChange={() => { setRuleset({ ...ruleset, disjunctive: false });  }}
        label="all conditions"
      />
      <RadioButton
        // id="mustMatch"
        key={"any"}
        disabled={disabled}
        checked={ruleset.disjunctive}
        onChange={() => {setRuleset({ ...ruleset, disjunctive: true }); }}
        label="any condition"
      />
    </Stack>);
  }

  const recommendationsHash = (!recommendations || Array.isArray(recommendations)) ? Object.fromEntries((recommendations || []).map((r) => [r.id, r])) : recommendations;
  const boostRuleHash = (!boostRules || Array.isArray(boostRules)) ? Object.fromEntries((boostRules || []).map((r) => [r.id, r])) : boostRules;

  const remainingRecommendationIds = Object.keys(recommendationsHash).map((r) => parseInt(r)).filter((r) => !ruleset.recommendation_ids || ruleset.recommendation_ids.indexOf(r) == -1);
  const recommendationsAdder = remainingRecommendationIds.length > 0 && [
    <Select fullWidth key={"recommendation"} value={(selectedRecommendation || 0) + ""} onChange={(val) => {
      setSelectedRecommendation(val != "0" ? parseInt(val) : null);
    }} options={[
      ...(!selectedRecommendation ? [{ label: "Select Recommendation", value: "0" }] : []),
      ...(remainingRecommendationIds.map((rid) => {
        return { label: localeFormat(null, recommendationsHash[rid].name), value: rid + "" };
      }))
    ]}/>,
    <Button key={"add"} square disabled={!selectedRecommendation} onClick={() => {
      setRuleset({ ...ruleset, recommendation_ids: [...(ruleset.recommendation_ids || []), selectedRecommendation] });
      setSelectedRecommendation(remainingRecommendationIds.filter((r) => r != selectedRecommendation)[0]);
    }} primary><Icon source={AddMajor}/></Button>
  ];



  const setSplit = useCallback((splitId) => {
    if (splitId != ruleset.split_id && (ruleset.rules.filter((r) => r.column[0] == "split_id").length > 0) || ((ruleset.pins && ruleset.pins.length > 0) || (ruleset.antipins && ruleset.antipins.length > 0))) {
      setRuleset({ ...ruleset, merge_id: splitId ? "none" : ruleset.merge_id, split_id: splitId, pins: [], antipins: [], rules: ruleset.rules.filter((r) => r.column[0] != "split_id") });
    } else {
      setRuleset({ ...ruleset, merge_id: splitId ? "none" : ruleset.merge_id, split_id: splitId });
    }
  }, [spotAPI, ruleset, setIsLoading]);

  const isPrimaryView = parent == null;
  const hasPins = (ruleset.pins && ruleset.pins.length > 0) && (ruleset.antipins && ruleset.antipins.length > 0);

  let jsonError = null;
  let parsedExtraMetadata = null;
  try {
    if (extraMetadata)
      parsedExtraMetadata = JSON.parse(extraMetadata);
  } catch (e) {
    jsonError = e.toString();
  }

	return (
    <>
    <Layout.Section>
      <Card title={!isPrimaryView ? "View" : "Metadata"} sectioned actions={[{ content: (!showAllMetadata ? "Show All Metadata" : "Hide Metadata"), onAction: () => { setShowAllMetadata(!showAllMetadata); } }]}>
        <FormLayout>
          {(!isPrimaryView || ruleset.global) && <TextField label="View Handle" error={error && error.handle} connectedRight={<div style={{marginTop: "6px"}}><Tooltip content="Handles must be all lower case, and only contain letters, numbers, hyphens or underscores." dismissOnMouseOut>
              <Icon source={CircleInformationMajor} color="base"/>
            </Tooltip></div>} onChange={(val) => {
              setError({ ...error, handle: !/^[a-z0-9_\-]*$/.test(val) ? "Please ensure that you handle only contains lower-case letters, numbers, underscores or hyphenns." : null });
              setRuleset({ ...ruleset, handle: val });
            }} value={ruleset.handle} placeholder={ruleset.global ? "ruleset-handle" : "view-handle"}/>}
          <TextField label="Description" multiline={4} placeholder="Optional freeform description that describes this display rule. Does not affect functionality." onChange={(val) => {
            setRuleset({ ...ruleset, description: val })
          }} value={ruleset.description}/>
          {showAllMetadata && <JSONField label="Extra" multiline={4} placeholder="null" value={ruleset.extra} onChange={(value) => { setRuleset({ ...ruleset, extra: value }); }} label="Extra" multiline={4} placeholder="Optional JSON to include in your rule data."/>}
        </FormLayout>
      </Card>
      <Card title="Conditions">
        {collection && (<Card.Section title={collection.type == "custom" ? "Manually Specified Shopify Collection" : "Automatic Shopify Collection"}>
          {collection.type != "custom" && <>
            {renderDisjunctive(true, collection.disjunctive)}
            <MultiTable
              condensed
              headings={["Field","Operator","Operand", ""]}
              rows={[
                ...(collection && collection.rules ? collection.rules.map((r) => [
                  productFields.filter((f) => f.handle === r.column).map((f) => f.name)[0] || (r.column === "variant_inventory" ? "Inventory Quantity" : (r.column === "variant_price" ? "Price" : (r.column == "product_metafield_definition" ? (
                    r.namespace ? `Product Metafield (${r.namespace}: ${r.key})` : "Product Metafield"
                  ) : r.column))),
                r.relation, r.condition, ""]) : []),
              ]}
              resourceName={{singular:"condition",plural:"conditions"}}
            />
          </>}
        </Card.Section>)}
        <Card.Section title={collection && "Additional"}>
          {hasSituationDefaultRule && <><SDKUpgradeBanner minimumSDKVersion={"SDK20231122"} feature='"Situation Default" rules' status="warning"/><br/></>}
          {ruleset.rules.length > 0 && renderDisjunctive(false, ruleset.disjunctive)}
          <ConditionTable collection={collection} split={isSplit} merge={isMerged} rules={ruleset.rules} setRules={(rules) => { setRuleset({ ...ruleset, rules: rules, conditions: translateRules(rules) }); }}/>
          <Button onClick={() => {
            setRuleset({ ...ruleset, rules: [...ruleset.rules, {
              column: ["title"],
              relation: "contains",
              condition: ""
            }], refresh: new Date() });
          }}>Add a Condition</Button>
        </Card.Section>
      </Card>
      <Card title="Ad-Hoc Product Reordering" actions={[{ content: (pinsShown ? "Hide Details" : "Show Details"), onAction: () => { setPinsShown(!pinsShown); } }]}>
        {(isSplit || isMerged) && <Card.Section><SDKUpgradeBanner minimumSDKVersion={"SDK20240111"} feature='ad-hoc product reordering in split collections' status="warning"/></Card.Section>}
        {hasBoostRulesAndPins && <Card.Section><SDKUpgradeBanner minimumSDKVersion={"SDK20240819"} feature='Boost Rules and Pins' status="warning"/></Card.Section>}
        {!pinnedProducts && <Card.Section><Stack><Spinner size="large"/></Stack></Card.Section>}
        {pinnedProducts && <Card.Section title={(ruleset.pins || []).length + " Products Pinned to Top"} actions={pinnedProducts && [
          { content: (<ResourcePickerButton 
            key="pin"
            allowNoSelection={true}
            large={true}
            split={isSplit}
            merge={isMerged}
            beforeQueryRun={((sq) => { return (collection ? sq.collection(parseInt(collection.id)) : sq).merge(spot.spotDefault.substituteDefaultConditions(ruleset.conditions || [])) })}
            selectMultiple={400} 
            primary 
            onUnidentified={(pins) => {
              const pinHash = Object.fromEntries(pins.map((p) => [p, true]));
              setRuleset({ ...ruleset, pins: ruleset.pins.filter((p) => !pinHash[p]) });
              createToast({ content: "We noticed that a few pins are no longer valid for this collection, and trimmed them." });
            }}
            onIdentified={(pins) => {
              setPinnedProducts({ ...pinnedProducts, ...Object.fromEntries(pins.map((p) => [(Array.isArray(p) ? p[0] : p).split_id, p])) });
            }}
            label="Pin Product(s)" 
            value={ruleset.pins}
            preferred_images={ruleset.preferred_images}
            fullWidth={false} 
            resourceType={"product"} 
            onSelection={({ selection }) => {
              setPinnedProducts({ ...pinnedProducts, ...Object.fromEntries(selection.map((p) => [(Array.isArray(p) ? p[0] : p).split_id, p])) });
              setRuleset({
                ...ruleset,
                pins: selection.map(
                  (p) => (Array.isArray(p) ? p[0] : p).split_id
                ),
                preferred_images: Object.fromEntries(selection.map((p) => {
                  const pNormal = Array.isArray(p) ? p[0] : p
                  return [(pNormal).split_id, (pNormal).image?.id]
                })),
              });
            }}/>
          ) },
          ...(ruleset.pins && ruleset.pins.length > 0 ? [{ content: (<Button key="clear" onClick={() => { setRuleset({ ...ruleset, pins: [] }); }} plain>Clear</Button>) }] : [])
        ]}>
          {pinsShown && pinnedProducts && ruleset.pins && ruleset.pins.length > 0 && (<MultiTable
            newable={true}
            onDragDrop={(source, destination) => {
              setRuleset({ ...ruleset, pins: rearrangeArray(ruleset.pins, source, destination) });
            }}
            resourceName={{singular: "product pin", plural: "product pins"}}
            headings={["Image", "Title", ""]}
            rows={ruleset.pins && ruleset.pins.filter((id) => pinnedProducts[id]).map((id, idx1) => {
              const p = pinnedProducts[id];
              return [
                (p.image ? <img alt={p.title} src={spotAPI.getSizedImage(spotAPI.getProductImage(p), "64x64")}/> : <PlaceholderImage width={64} height={64}/>),
                spotAPI.getProductTitle(p),
                (<Button square  key="clear" onClick={() => {
                  setRuleset({ ...ruleset, pins: ruleset.pins.filter((e, idx2) => idx2 !== idx1) });
                }}><Icon source={DeleteMajor} color="base" /></Button>)
              ]
            })}
          />)}
        </Card.Section>}
        <Card.Section title={(ruleset.antipins || []).length + " Products Sent to Bottom"} actions={antipinnedProducts && [
          { content: (<ResourcePickerButton
            key="antipin"
            allowNoSelection={true}
            merge={isMerged}
            split={isSplit}
            beforeQueryRun={((sq) => { return (collection ? sq.collection(parseInt(collection.id)) : sq).merge(spot.spotDefault.substituteDefaultConditions(ruleset.conditions || [])) })} 
            selectMultiple={10} 
            label="Send Product(s) to Bottom"
            fullWidth={false}
            resourceType={"product"}
            value={ruleset.antipins}
            onIdentified={(antipins) => {
              setAntipinnedProducts({ ...antipinnedProducts, ...Object.fromEntries(antipins.map((p) => [(Array.isArray(p) ? p[0] : p).split_id, p])) });
            }}
            onUnknown={(antipins) => {
              const antipinHash = Object.fromEntries(antipins.map((p) => [p, true]));
              setRuleset({ ...ruleset, antipins: ruleset.antipins.filter((p) => !antipinHash[p]) });
              createToast({ content: "We noticed that a few antipins are no longer valid for this collection, and trimmed them." });
            }}
            onSelection={({ selection }) => {
              setRuleset({ ...ruleset, antipins: selection.map((p) => (Array.isArray(p) ? p[0] : p).split_id) });
            }}
          />) },
          ...(ruleset.antipins && ruleset.antipins.length > 0 ? [{ content: (<Button key="clear" onClick={() => { setRuleset({ ...ruleset, antipins: [] }); }} plain>Clear</Button>) }] : [])
        ]}>
          {!antipinnedProducts && <Stack><Spinner size="large"/></Stack>}
          {antipinnedProducts && pinsShown && ruleset.antipins && ruleset.antipins.length > 0 && (
            <MultiTable
              newable={true}
              onDragDrop={(source, destination) => {
                setRuleset({ ...ruleset, antipins: rearrangeArray(ruleset.antipins, source, destination) });
              }}
              resourceName={{singular: "product sent to bottom", plural: "products sent to bottom"}}
              headings={["Image", "Title", ""]}
              rows={ruleset.antipins && ruleset.antipins.filter((id) => antipinnedProducts[id]).map((id, idx1) => {
                const p = antipinnedProducts[id];
                return [
                  (p.image ? <img alt={p.title} src={spotAPI.getSizedImage(spotAPI.getProductImage(p), "64x64")}/> : <PlaceholderImage width={64} height={64}/>),
                  spotAPI.getProductTitle(p),
                  (<Button square key="clear" onClick={() => {
                    setRuleset({ ...ruleset, antipins: ruleset.antipins.filter((e, idx2) => idx2 !== idx1) });
                  }}><Icon source={DeleteMajor} color="base" /></Button>)
                ]
              })}
            />)}
          </Card.Section>
        </Card>
        <Card title="Banners" actions={[{ content: (bannersShown ? "Hide Details" : "Show Details"), onAction: () => { setBannersShown(!bannersShown); } }]}>
          {!banners && <Card.Section><Stack><Spinner size="large"/></Stack></Card.Section>}
          {banners && <Card.Section title={(ruleset.banners || []).length + " Banners"} actions={banners && [
            { 
              content: (<BannerPickerButton primary onSelection={(selection) => { 
                setRuleset({ ...ruleset, banners: [...(ruleset.banners || []), ...selection.selection.map((banner) => { return { banner_id: banner.id, size: "medium", position: (ruleset.banners ||[]).length } })] }) 
              }}/>) 
            },
            ...(ruleset.banners && ruleset.banners.length > 0 ? [{ content: (<Button onClick={() => { setRuleset({ ...ruleset, banners: [] }); }} plain>Clear</Button>) }] : [])
          ]}>
            {bannersShown && ruleset.banners && ruleset.banners.length > 0 && (<MultiTable
              newable={true}
              resourceName={{singular: "banner", plural: "banners"}}
              headings={["Banner", "Position", "Size", ""]}
              rows={ruleset.banners && ruleset.banners.map((banner, idx1) => {
                const b = banners.filter((b) => b.id == banner.banner_id)[0];
                return [
                  <BannerTile banner={b} swatches={swatches}/>,
                  <TextField min={1} type="number" style={{ width: "4rem" }} onChange={(v) => { setRuleset({ ...ruleset, banners: ruleset.banners.map((b2) => b2.banner_id == b.id ? { ...b2, position: parseInt(v) - 1 } : b2) }); }} value={(banner.position + 1) + ""}/>,
                  <Select value={b.size} onChange={(val) => { setRuleset({ ...ruleset, banners: (ruleset.banners.map((b2) => b2.banner_id == b.id ? { ...b2, size: val } : b2)) }); }} options={[{ label: "Small", value: "small" }, { label: "Medium", value: "medium" }, { label: "Large", value: "large" }]}/>,
                  (<Button onClick={() => {
                    setRuleset({ ...ruleset, banners: ruleset.banners.filter((e, idx2) => idx2 !== idx1) });
                  }}><Icon source={DeleteMajor} color="base" /></Button>)
                ]
              })}
            />)}</Card.Section>}
        </Card>
    </Layout.Section>
    <Layout.Section secondary>
      <Card sectioned>
        <RulesetCharacteristic key="facet-group" title={<Link url={"/catalog/groups" + (currentTheme ? "?theme_id=" + encodeURIComponent(currentTheme.id) : "")}>Facet Group</Link>}>
          <Select
            value={ruleset.facet_group_id === null || ruleset.facet_group_id === undefined ? "null" : (ruleset.facet_group_id || "0")}
            onChange={(val) => {
              ruleset.facet_group_id = val !== "null" ? parseInt(val) : null;
              setRuleset({ ...ruleset });
            }}
            options={[{label: "No Facets", value: "0"}, {label: "Situation Default", value: "null"}, ...Object.values(facetGroups).map((g) => { return { label: g.name, value: g.id }; })]}/>
        </RulesetCharacteristic>
        <RulesetCharacteristic key="sort-order"  title={<Link url={"/catalog/sort_options" + (currentTheme ? "?theme_id=" + encodeURIComponent(currentTheme.id) : "")}>Sort Order</Link>}>
          <Select
            value={`${ruleset.sort_option_id}` || ""}
            onChange={(val) => {
            setRuleset({ ...ruleset, sort_option_id: val !== "null" ? val : null });
          }}
          options={[{label: "Situation Default", value: "null" }, ...sortOptions.map((s, idx) => { return { label: typeof(s.label) === "object" ? s.label[Object.keys(s.label).sort()[0]] : s.label, value: `${idx}` }; })]}/>
        </RulesetCharacteristic>
        <RulesetCharacteristic key="splits"  title={<Link url={"/merchandising/splits" + (currentTheme ? "?theme_id=" + encodeURIComponent(currentTheme.id) : "")}>Split</Link>}>
          <Select value={ruleset.split_id || "0"} onChange={(val) => {
            setSplit(val !== "0" ? val : null);
          } } options={[{label: "Situation Default", value: "0"}, {label: "None", value: "none"}, {label: "Auto", value: "auto"}, ...Object.values(splits).map((s) => { return { label: s.handle, value: s.handle }; })]}/>
        </RulesetCharacteristic>
        <RulesetCharacteristic key="merge"  title={<Link url={"/merchandising/merges" + (currentTheme ? "?theme_id=" + encodeURIComponent(currentTheme.id) : "")}>Merge</Link>}>
          <Select value={ruleset.merge_id || "0"} onChange={(val) => {
            setRuleset({ ...ruleset, merge_id: val !== "0" ? val : null, split_id: val ? "none" : ruleset.split_id });
          } } options={[{label: "Situation Default", value: "0"}, {label: "None", value: "none"}, ...Object.values(merges).map((s) => { return { label: s.handle, value: s.handle }; })]}/>
        </RulesetCharacteristic>
        <RulesetCharacteristic key="recommendations" title={<Link url={"/merchandising/recommendations" + (currentTheme ? "?theme_id=" + encodeURIComponent(currentTheme.id) : "")}>Recommendations</Link>}>
          <MultiTable
            condensed
            onDragDrop={(src, dst) => {
              setRuleset({ ...ruleset, recommendation_ids: rearrangeArray(ruleset.recommendation_ids, src, dst) });
            }}
            noEmpty={true}
            rows={[
              ...(ruleset.recommendation_ids || []).map((rid, ridx) => {
                const recommendation = recommendationsHash[rid];
                const name = recommendation ? (typeof(recommendation.name) === "object" ? recommendation.name[Object.keys(recommendation.name).sort()[0]] : recommendation.name) : rid;
                return [
                  name,
                  (<Button square onClick={() => {
                    setRuleset({ ...ruleset, recommendation_ids: ruleset.recommendation_ids.filter((e, idx) => idx != ridx) });
                  }}><Icon source={DeleteMajor}/></Button>)
                ]
              })
            ]}
            footers={recommendationsAdder}
          />
        </RulesetCharacteristic>
        {!hasPins && <RulesetCharacteristic key="boost-rules"  title={<Link url={"/merchandising/boost_rules" + (currentTheme ? "?theme_id=" + encodeURIComponent(currentTheme.id) : "")}>Boost Rules</Link>}>
          <Select value={ruleset.boost_rule_ids ? (ruleset.boost_rule_ids.length > 0 ? (ruleset.boost_rule_ids[0] + "") : "0") : "null"} onChange={(val) => { 
            if (val === "null")
              setRuleset({ ...ruleset, boost_rule_ids: null });
            else if (val === "0")
              setRuleset({ ...ruleset, boost_rule_ids: [] });
            else
              setRuleset({ ...ruleset, boost_rule_ids: [parseInt(val)] });
          }} options={[{label: "Situation Default", value: "null"}, {label: "No Boost", value: "0"}, ...Object.keys(boostRuleHash).map((id, idx) => { return { label: localeFormat(null, boostRuleHash[id].name), value: id + "" }; })]}/>
        </RulesetCharacteristic>}
        <RulesetCharacteristic key="personalization"  title={<Link url={"/search/personalization" + (currentTheme ? "?theme_id=" + encodeURIComponent(currentTheme.id) : "")}>Personalization</Link>}>
          <Select value={(ruleset.personalization_id != null ? ruleset.personalization_id : "null") + ""} onChange={(val) => {
            setRuleset({ ...ruleset, personalization_id: val !== "null" ? parseInt(val) : null });
          } } options={[{label: "Situation Default", value: "null"}, {label: "No Personalization", value: "0"}, ...(personalizations ? personalizations.map((s) => { return { label: s.name, value: s.id + "" }; }) : [])]}/>
        </RulesetCharacteristic>
        <RulesetCharacteristic key="badges" title={<Link url={"/merchandising/badges" + (currentTheme ? "?theme_id=" + encodeURIComponent(currentTheme.id) : "")}>Badges</Link>}>
          <Select
            value={ruleset.badge_group_id === null || ruleset.badge_group_id === undefined ? "null" : (ruleset.badge_group_id || "0")}
            onChange={(val) => {
              ruleset.badge_group_id = val !== "null" ? parseInt(val) : null;
              setRuleset({ ...ruleset });
            }}
            options={[{label: "No Badges", value: "0"}, {label: "Situation Default", value: "null"}, ...Object.values(badgeGroups).map((g) => { return { label: g.name, value: g.id }; })]}/>
        </RulesetCharacteristic>
      </Card>
    </Layout.Section>
    {ruleset.global && <Layout.Section><Card title="Applied Collections" actions={[{content: (<ResourcePickerButton key="apply"
      beforeQueryRun={((sq) => { return sq.id("not_in", ruleset.collections.map((c) => c.id)); })}
      selectMultiple={true}
      onClear={false}
      primary
      label="Apply to collection(s)"
      fullWidth={false}
      resourceType="collection"
      transform={(fn, items) => {
        authFetch("/api/collections", { query: { ids: items.map((i) => i.id) } }).then((r) => {
          var collectionHash = Object.fromEntries(r.collections.map((c) => [c.id, c]));
          fn(items.map((i) => { return { ...i, ...(collectionHash[i.id] ? { ruleset_id: collectionHash[i.id].ruleset_id, updated_at: collectionHash[i.id].updated_at } : {}) }; }));
        });
      }}
      headings={["", "", "Collection", "Display Rule"]}
      row={(item) => {
        return [
          (item.image ? (<Thumbnail
            source={item.image}
            size="medium"
            alt={item.title}
          />) : <PlaceholderImage/>),
          item.title,
          !item.ruleset_id ? "Situation Default" : (<Stack><span style={{marginRight: "8px"}}>{(rulesetHash[item.ruleset_id] ? rulesetHash[item.ruleset_id].handle : "Already Assigned")}</span> <Tooltip content="Assigning this display rule to this collection will override the existing one. Make sure you want this before proceeding." dismissOnMouseOut>
            <Icon source={RiskMajor} color="warning"/>
          </Tooltip></Stack>)
        ];
      }}
      onSelection={({ selection }) => {
        setRuleset({ ...ruleset, collections: [...ruleset.collections, ...selection.map((c) => { return { id: c.id, title: c.title, handle: c.handle, type: c.rules ? "smart" : "custom", updated_at: c.updated_at }; })] });
      }}/>)}]}
    >
      {!ruleset.collections && <Card.Section><Stack><Spinner size="large"/></Stack></Card.Section>}
      {ruleset.collections && <Card.Section>
        <MultiTable
          newable={true}
          resourceName={{singular: "applied collection", plural: "applied collections"}}
          headings={["Image", "Title", ""]}
          rows={ruleset.collections.map((c, idx1) => [
            (c.image ? <img alt={c.title} src={spotAPI.getSizedImage(c.image, "64x64")}/> : <PlaceholderImage/>),
            c.title,
            (<Button onClick={() => {
              setRuleset({ ...ruleset, collections: ruleset.collections.filter((e, idx2) => idx2 !== idx1).map((c) => { return { id: c.id, title: c.title, handle: c.handle, image: c.image, type: c.rules ? "smart" : "custom", updated_at: c.updated_at }; }) });
            }}><Icon source={DeleteMajor} color="base"/></Button>)
          ])}
        />
      </Card.Section>}
    </Card></Layout.Section>}
    </>
  );
}

export function Rulesets() {
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [rulesets, setRulesets] = useState(null);
  const [ruleset, setRuleset] = useState(null);
  const [splits, setSplits] = useState(null);
  const [merges, setMerges] = useState(null);
  const [facetGroups, setFacetGroups] = useState(null);
  const [badgeGroups, setBadgeGroups] = useState(null);
  const [personalizations, setPersonalizations] = useState(null);
  const [sortOptions, setSortOptions] = useState(null);
  const [recommendations, setRecommendations] = useState(null);
  const [boostRules, setBoostRules] = useState(null);
  const [banners, setBanners] = useState(null);
  const [swatches, setSwatches] = useState(null);
  const [siteWideRulesetId, setSiteWideRulesetId] = useState("0");
  const [searchRulesetId, setSearchRulesetId] = useState("0");
  const [theme, setTheme] = useState(null);
  const [error, setError] = useState({});
  const [counts, setCounts] = useState(null);

  const [profile] = Auth.useProfile();

  const authFetch = useSpotFetch();
  const redirect = useRedirect();

  const { rulesetId } = useParams();

  const spotAPI = useSpotAPI();

  const [quickStartStatus, setQuickStartStatus] = useQuickStartStatus();

  const updateRulesets = useCallback((theme) => {
    return authFetch("/api/global/rulesets", { query: theme ? { theme_id: theme.id } : {} }).then((r) => {
      var collectionIds = [...new Set(r.rulesets.flatMap((ruleset) => ruleset.collections).map((c) => c.id))];
      spotAPI.s().id("in", collectionIds).rows(1000).targets(["collections"]).e().done(function(collections) {
        var collectionRulesetHash = Object.fromEntries(collections.map((c) => [c.id, c]));
        r.rulesets.forEach((ruleset) => {
          ruleset.collections.forEach((c) => {
            c.handle = collectionRulesetHash[c.id] ? collectionRulesetHash[c.id].handle : "unknown";
            c.title = collectionRulesetHash[c.id] ? collectionRulesetHash[c.id].title : "Unknown Collection";
            c.image = collectionRulesetHash[c.id] ? collectionRulesetHash[c.id].image : null;
          });
        });
        setIsLoading(false);
        setSplits(r.splits);
        setMerges(r.merges);
        setFacetGroups(r.facet_groups);
        setBadgeGroups(r.badge_groups);
        setSortOptions(r.sort_options);
        setBanners(r.banners);
        setSwatches(r.swatches);
        setRulesets(r.rulesets);
        setRecommendations(r.recommendations);
        setBoostRules(r.boost_rules);
        setPersonalizations(r.personalizations);
        setSiteWideRulesetId(r.general_rule_id ? r.general_rule_id + "" : "0");
        setSearchRulesetId(r.search_rule_id ? r.search_rule_id + "" : "0");
        setCounts(r.counts);
      });
    });
  }, [authFetch, setIsLoading, setSplits, setMerges, setFacetGroups, setBadgeGroups, setSortOptions]);

  useEffect(() => {
    if (!rulesets)
      updateRulesets(theme)
  }, [rulesets, updateRulesets, theme]);

  const siteWideRulesetOptions = rulesets && [{label: (theme ? "Global Default Display Rule" : "None"), value: "0"}, ...rulesets.map((r) => { return { label: r.handle, value: "" + r.id } })];
  const searchRulesetOptions = rulesets && [{label: "Site-Wide Default", value: "0"}, ...rulesets.map((r) => { return { label: r.handle, value: "" + r.id } })];

  const siteWideLabel = (<ButtonGroup><span>Site-Wide Display Rule</span>{siteWideRulesetId === "0" && (
    <Tooltip content="You will almost always want a site-wide ruleset. Without one, by default, your customers probably won't see any of your facets." dismissOnMouseOut>
      <Icon source={RiskMajor} color="warning"/>
    </Tooltip>)}</ButtonGroup>);

  const siteWideRuleset = rulesets && siteWideRulesetId && rulesets.filter((e) => e.id === parseInt(siteWideRulesetId))[0];
  const searchRuleset = rulesets && searchRulesetId && rulesets.filter((e) => e.id === parseInt(searchRulesetId))[0];
  const searchSortId = searchRuleset && searchRuleset.sort_option_id !== null ? searchRuleset.sort_option_id : (siteWideRuleset && siteWideRuleset.sort_option_id != null ? siteWideRuleset.sort_option_id : null);
  const siteWideSortId = siteWideRuleset && siteWideRuleset.sort_option_id !== null && siteWideRuleset.sort_option_id;
  const searchSortRule = searchSortId !== null && sortOptions && sortOptions.filter((e,idx) => idx === parseInt(searchSortId))[0] ;
  const siteWideSortRule = siteWideSortId !== null && sortOptions && sortOptions.filter((e,idx) => idx === parseInt(siteWideSortId))[0] ;
  const hasNonSearchSearchRuleset = searchSortRule && (searchSortRule.property !== "search" && searchSortRule.property !== null);
  const hasReachedLimit = profile && rulesets && profile.shop.maximums.display_rule !== null && rulesets.length >= profile.shop.maximums.display_rule;
  const hasNonSituationDefaultSiteWideRuleset = siteWideRuleset && siteWideRuleset.sort_option_id !== null;
  const hasNoFacetsInSiteWideDefault = siteWideRuleset && siteWideRuleset.facet_group_id === null && facetGroups.length > 0;


  const saveRuleset = function(duplicate) {
    if (ruleset) {
      if (/^\s*$/.test(ruleset.handle)) {
        setError({ ...error, handle: "Requires a handle." });
        return;
      }
      if (!/^[a-z0-9\-_]+$/.test(ruleset.handle)) {
        setError({ ...error, handle: "Handle must only constist of lower-case letters, numbers, hyphens or underscores." });
        return;
      }
    }
    setIsLoading(true);
    if (ruleset) {
      let id = !duplicate ? ruleset.id : null;
      authFetch("/api/global/rulesets" + (id ? "/" + id : ""), { json: { ...ruleset, id: id, theme_id: theme ? theme.id : null } }).then((r) => {
        setIsChanged(false);
        setIsLoading(false);
        setRulesets(id ? rulesets.map((e, idx) => { return e.id === id ? { ...r, applied: e.applied } : e; }) : [...rulesets, { ...r, applied: 0 }]);
        setQuickStartStatus({ ...quickStartStatus, rulesets: true });
        redirect('/merchandising/rulesets');
      });
   } else {
      authFetch("/api/global/rulesets/default", { json: {
        general_rule_id: siteWideRulesetId || null,
        search_rule_id: searchRulesetId || null,
        theme_id: (theme ? theme.id : null)
      } }).then((r) => {
        setIsChanged(false);
        setIsLoading(false);
        setSiteWideRulesetId(r.general_rule_id ? r.general_rule_id + "" : "0");
        setSearchRulesetId(r.search_rule_id ? r.search_rule_id + "" : "0");
      });
   }
  }


  const needsToRedirect = rulesets && ((rulesetId && (!ruleset || (rulesetId != "new" && ruleset.id != rulesetId) || (rulesetId == "new" && ruleset.id)) || (!rulesetId && ruleset)));

  useEffect(() => {
    if (needsToRedirect && merges && splits && facetGroups && sortOptions && recommendations && boostRules && siteWideRulesetId && searchRulesetId && counts) {
      const selectedRuleset = rulesetId && rulesetId != "new" && rulesets.filter((r) => r.id == rulesetId)[0];
      setError({});
      setRuleset(selectedRuleset ? selectedRuleset : (rulesetId ? { rules: [], global: true, pins: [], antipins: [], collections: [] } : null));
    }
  }, [rulesetId, needsToRedirect, merges, splits, facetGroups, badgeGroups, sortOptions, recommendations, boostRules, siteWideRulesetId, searchRulesetId, counts]);


  return (<Page
    permission="rules"
    resourceName={{singular: "Display Rules", plural: "Display Rule"}}
    isChanged={isChanged}
    isLoading={isLoading}
    audit={ruleset && ruleset.id && {resource: "Ruleset", id: ruleset.id}}
    isFullyLoading={!rulesets}
    setTheme={(newTheme) => {
      if (newTheme !== theme) {
        setTheme(newTheme);
        setIsChanged(true);
        setIsLoading(true);
        setRulesets(null);
      }
    }}
    theme={theme}
    getResourceCount={(themeId) => counts && counts[themeId]}
    onDelete={() => {
      setIsLoading(true);
      authFetch("/api/global/rulesets", { method: "DELETE", json: { ids: rulesets.map((r) => r.id) } }).then((r) => {
        authFetch("/api/global/rulesets/default", { json: { general_rule_id: 0, search_rule_id: 0, theme_id: theme ? theme.id : null } }).then((r) => {
          setTheme(null);
          setRulesets(null);
        });
      });
    }}
    onBack={ruleset && (() => { redirect('/merchandising/rulesets'); })}
    onSave={(() => { saveRuleset(false); })}
    tertiaryActions={ruleset && [{
      content: "Duplicate Display Rule",
      onAction: () => { saveRuleset(true); }
    }]}
    disableThemes={ruleset ? true : false}
    overrideThemes
  >
    {(currentTheme, currentLocale) => (<Layout>
      {!ruleset && (<Layout.Section>
        {hasNonSituationDefaultSiteWideRuleset && [<Banner title="Your site-wide display rule is sorting results unusually." status="warning">
          Your site-wide display rule you have set up is sorting by "{localeFormat(currentLocale, siteWideSortRule.label)}".
          This is unusual; generally you want it to be "Situation Default", as this will mirror Shopify. You should make sure that you really want this to be the case.
        </Banner>,<br/>]}
        {hasNonSearchSearchRuleset && [<Banner title="Your search display rule is sorting results unusually." status="warning">
          Your search display rule you have set up isn't sorting by the your search heuristic; it's sorting by "{localeFormat(currentLocale, searchSortRule.label)}".
          This is unusual; you should make sure that you really want this to be the case.
        </Banner>,<br/>]}
        {hasNoFacetsInSiteWideDefault && [<Banner title="Your site-wide display rule has no facets." status="warning">
          Your site-wide display rule you have set up doesn't have any facets associated with it.
          This is unusual; you should make sure that you really want this to be the case.
        </Banner>,<br/>]}
        {hasReachedLimit && [<UpgradeBanner bundleType="display_rule"/>,<br/>]}
        <Card title="Display Rule Defaults">
          <Card.Section>
            <FormLayout>
              <FormLayout.Group>
                <Select
                  key="global-rule"
                  label={siteWideLabel}
                  onChange={(val) => { setSiteWideRulesetId(val); setIsChanged(true); }}
                  options={siteWideRulesetOptions}
                  value={siteWideRulesetId}
                />
                <Select
                  key="search-rule"
                  label="Search Display Rule"
                  onChange={(val) => { setSearchRulesetId(val); setIsChanged(true); }}
                  options={searchRulesetOptions}
                  value={searchRulesetId}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card.Section>
      </Card></Layout.Section>)}
      {ruleset && (<RulesetEditor
        setRuleset={(ruleset) => {
          setRuleset(ruleset);
          setIsChanged(true);
        }}
        rulesets={rulesets}
        error={error}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        setError={setError}
        ruleset={ruleset}
        personalizations={personalizations}
        facetGroups={facetGroups}
        badgeGroups={badgeGroups}
        sortOptions={sortOptions}
        banners={banners}
        swatches={swatches}
        splits={splits}
        merges={merges}
        currentTheme={theme}
        recommendations={recommendations}
        boostRules={boostRules}
      />)}
      {!ruleset && (<Layout.Section><MultiTableForm
        bulkActions={[{
          content: "Delete rulesets",
          onAction: (ids) => {
            authFetch("/api/global/rulesets", { method: "DELETE", json: { ids: ids.map((idx) => rulesets[idx].id) }}).then((r) => {
              let deleted = Object.fromEntries(ids.map((i) => [i,1]));
              setRulesets(rulesets.filter((e, idx) => !deleted[idx]));
            });
          }
        }]}
        onNew={!hasReachedLimit && (() => { redirect('/merchandising/rulesets/new'); })}
        resourceName={{singular: "display rule", plural: "display rule set"}}
        headings={["Handle", "Applied Situations"]}
        onRowClick={(row, idx) => { redirect('/merchandising/rulesets/' + rulesets[idx].id); }}
        rows={rulesets.filter((r) => r.theme_id === (theme ? theme.id : null)).map((r) => [
          r.handle,
          (<LocalizedBadgeList maxToDisplay={3} locale={currentLocale} values={[
            ...(r.id == siteWideRulesetId ? [<Badge status="success">Site Wide Default</Badge>] : []),
            ...(r.id == searchRulesetId ? [<Badge status="attention">Search Default</Badge>] : []),
            ...(r.collections.map((c) => c.title).sort()),
            ...(r.applied.overrides.length ? [(<Badge status="warning">{r.applied.overrides.length + " Search Override" + (r.applied.overrides.length > 1 ? "s" : "")}</Badge>)] : [])
          ]} emptyTooltip={"Because this display rule is not associated to any situation, it can only be accessed ad-hoc via a URL, or programatically."}/>)
        ])}
      /></Layout.Section>)}
    </Layout>)}
  </Page>);
}


