import React, { useEffect, useState, useRef } from "react";
import { useProfile, useFetch } from "../contexts/Auth";
import { Button } from "./Polaris";

export function Zendesk({ name = document.title || "ModdApps", id = '00feeab5-5427-4dc2-803e-8b6184aa27cc' }) {
  const [profile, profileError] = useProfile();
  const authFetch = useFetch();
  const [requiresBackup, setRequiresBackup] = useState(null);
  const backupCheckInterval = useRef(null);

  useEffect(() => {
    if (profile && profile.shop && name && !profile.user.watchtower) {
      const zendeskProduct = name.replace(/\s/g, '_');
      window.zESettings = {
        webWidget: {
          chat: {
            tags: [
              zendeskProduct,
              `${zendeskProduct}_${(profile?.shop?.subscriptions[0]?.plan_name?.replace(/\s/g, '_') || 'not_subscribed')}`,
              `days_installed_${profile.shop.days_installed}`,
              profile.shop.shop_origin,
              `shopify_shop_id_${profile.shop.shopify_shop_id}`,
              ...(profile.user ? [
                profile.user.account_owner && 'account_owner',
                profile.user.collaborator && 'collaborator_account',
                `shopify_user_id_${profile.user.shopify_user_id}`
              ] : [])
            ].filter((t) => t),
            departments: {
              enabled: [],
            },
          },
          contactForm: {
            fields: [
              { id: 360053683873, prefill: { '*': zendeskProduct.toLowerCase() } },
              { id: 360053724933, prefill: { '*': profile.shop.shop_origin } },
              { id: "email", prefill: { '*': profile?.user?.email } },
            ],
          },
        },
        authenticate: {
          chat: {
            jwtFn: (callback) => {
              authFetch('/api/zendesk_jwt').then((response) => {
                callback(response.token);
              });
            },
          },
        }
      };
      if (!document.querySelector('#ze-snippet')) {
        const script = document.createElement('script');
        script.src =`https://static.zdassets.com/ekr/snippet.js?key=${id}`;
        script.id = 'ze-snippet';
        script.defer = true;
        script.onload = () => {
          window.zE(() => {
            window.zE.identify({ 
              name: `${profile.user.first_name} ${profile.user.last_name}`,
              email: profile.user.email
            });
          });
        };
        document.body.appendChild(script);
      }
      try {
        window.localStorage.setItem('testStorage', 'true');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Failed Zendesk localStorage test. Polyfilling.');
        Object.defineProperty(window, 'localStorage', {
          value: new Proxy(
            {},
            {
              set(obj, k, v) {
                // eslint-disable-next-line no-param-reassign
                obj[k] = String(v);
                return obj[k];
              },
            }
          ),
        });
      }
      setRequiresBackup(true);
      if (backupCheckInterval.current) {
        clearInterval(backupCheckInterval.current);
        backupCheckInterval.current = null;
      }
      backupCheckInterval.current = requiresBackup !== false && setInterval(() => {
        if (
          document.querySelector('iframe#launcher') &&
          document.querySelector('iframe#launcher').contentDocument.querySelector('button[data-testid=launcher]')
        ) {
          setRequiresBackup(false);
          clearInterval(backupCheckInterval.current);
        } else {
          setRequiresBackup(true);
        }
      }, 500);
      return () => { 
        if (backupCheckInterval.current) 
          clearInterval(backupCheckInterval.current); 
      };
    }
  }, [authFetch, profile, profileError]);
  
  return (requiresBackup === true && <Button style={{ fontWeight: "700", position: "fixed", padding: "16px 20px", fontSize: "15px", backgroundColor: "#9f1fb7", bottom: "24px", right: "24px", borderRadius: "64px", border: "0" }} primary className='modd-zendesk-backup-button' onClick={() => {  
      window.open(
      `https://static.zdassets.com/web_widget/latest/liveChat.html?v=10#key=moddapps.zendesk.com&settings=${
        btoa(JSON.stringify(window.zESettings || { webWidget: { chat: {}, contactForm: {} } }))
      }&locale=en-ca&title=Web%20Widget%20Live%20Chat`,
      'ChatWindow',
      'width=400,height=675'
    );
  }}>Need Help?</Button>);
}

