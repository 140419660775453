import React from "react";
// Generated with: perl -e 'use File::Slurp; print "export function " . ($_ =~ s/\.svg$//r) .  "() { return " . scalar(read_file($_)) . "; }\n" for (glob("*.svg"))' > ../Icons.jsx
export function AbandonedCartMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.707 1.293a1 1 0 0 0-1.414 1.414l1.293 1.293-1.293 1.293a1 1 0 0 0 1.414 1.414l1.293-1.293 1.293 1.293a1 1 0 1 0 1.414-1.414l-1.293-1.293 1.293-1.293a1 1 0 0 0-1.414-1.414l-1.293 1.293-1.293-1.293z"/><path fillRule="evenodd" d="M1 1a1 1 0 0 1 1-1h1.5a1.5 1.5 0 0 1 1.5 1.5v8.5h11.133l.877-6.141a1 1 0 1 1 1.98.282l-.939 6.571a1.5 1.5 0 0 1-1.485 1.288h-11.566v2h10a3 3 0 1 1-2.83 2h-5.34a3 3 0 1 1-3.83-1.83v-12.17h-1a1 1 0 0 1-1-1zm13 16a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm-11 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0z"/></svg>; }
export function AccessibilityMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 18c-7.666 0-9.939-7.648-9.962-7.726a1 1 0 0 1 0-.548c.023-.078 2.296-7.726 9.962-7.726 7.633 0 9.927 6.617 9.948 6.684a1 1 0 1 1-1.895.635 8.348 8.348 0 0 0-8.053-5.319c-5.467 0-7.513 4.772-7.948 6 .434 1.225 2.479 6 7.948 6a1 1 0 0 1 0 2zm0-4c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4a1 1 0 1 1-2 0c0-1.103-.897-2-2-2s-2 .897-2 2 .897 2 2 2a1 1 0 0 1 0 2zm5 4a.997.997 0 0 1-.707-.293l-2-2a1 1 0 0 1 1.414-1.414l1.293 1.293 3.293-3.293a1 1 0 0 1 1.414 1.414l-4 4a.995.995 0 0 1-.707.293z"/></svg>; }
export function ActivitiesMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18.111.221a3.04 3.04 0 0 1 1.002.66h-.003a2.992 2.992 0 0 1 .89 2.18c-.224 14.324-4.22 16.692-16.931 16.94h-.06c-.797 0-1.548-.31-2.12-.88a2.989 2.989 0 0 1-.889-2.184c.226-14.322 4.22-16.689 16.932-16.937.404-.005.805.07 1.179.221zm-10.232 7.659a1 1 0 0 1 1.414 0l.708.707 1.414-1.414-.707-.707a1 1 0 0 1 1.414-1.414l.707.707 1.464-1.465a1 1 0 1 1 1.415 1.414l-1.465 1.465.707.707a1 1 0 0 1-1.414 1.414l-.707-.707-1.414 1.413.707.707a1 1 0 1 1-1.414 1.415l-.708-.707-1.415 1.414.707.707a1 1 0 1 1-1.412 1.414l-.707-.707-1.464 1.464a1 1 0 0 1-1.415-1.414l1.465-1.464-.707-.707a1 1 0 1 1 1.414-1.415l.707.707 1.413-1.414-.707-.707a1 1 0 0 1 0-1.414z"/></svg>; }
export function AddCodeMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 9c0 .266-.105.52-.293.707l-.293.293.293.293a.996.996 0 0 1 .293.707v4a1 1 0 0 0 1 1 1 1 0 0 1 0 2c-1.654 0-3-1.346-3-3v-3.586l-.707-.707a1 1 0 0 1 0-1.414l.707-.707v-3.586c0-1.654 1.346-3 3-3a1 1 0 0 1 0 2 1 1 0 0 0-1 1v4zm16.707.293a1.001 1.001 0 0 1 0 1.414l-.707.707v3.586c0 1.654-1.346 3-3 3a1 1 0 1 1 0-2 1 1 0 0 0 1-1v-4c0-.266.105-.52.293-.707l.293-.293-.293-.293a.996.996 0 0 1-.293-.707v-4a1 1 0 0 0-1-1 1 1 0 1 1 0-2c1.654 0 3 1.346 3 3v3.586l.707.707zm-6.707-.293a1 1 0 0 1 0 2h-2v2a1 1 0 0 1-2 0v-2h-2a1 1 0 0 1 0-2h2v-2a1 1 0 1 1 2 0v2h2z"/></svg>; }
export function AddImageMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M19 2.5a1.5 1.5 0 0 0-1.5-1.5h-15a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h7.5v-2h-6.503c-.41 0-.64-.46-.4-.79l3.553-4.051c.19-.21.52-.21.72-.01l1.63 1.851 3.06-4.781a.5.5 0 0 1 .84.02l.72 1.251a5.98 5.98 0 0 1 2.38-.49h3v-7.5zm-11.5 2c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm12.207 10.793a1 1 0 0 0-.707-.293h-2v-2a1 1 0 0 0-2 0v2h-2a1 1 0 0 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 0 0 .707-1.707z"/></svg>; }
export function AddMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 3h1v-2h-1.5a1.5 1.5 0 0 0-1.5 1.5v1.5h2v-1zm3 0h3v-2h-3v2zm5 0h3v-2h-3v2zm-2 16h-3v-2h3v2zm2 0h3v-2h-3v2zm6-15v-1h-1v-2h1.5a1.5 1.5 0 0 1 1.5 1.5v1.5h-2zm-14 13v-1h-2v1.5a1.5 1.5 0 0 0 1.5 1.5h1.5v-2h-1zm13 0h1v-1h2v1.5a1.5 1.5 0 0 1-1.5 1.5h-1.5v-2zm-6-11a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2h-2a1 1 0 1 1 0-2h2v-2a1 1 0 0 1 1-1zm-9 3v-3h2v3h-2zm0 2v3h2v-3h-2zm16-2v-3h2v3h-2zm0 2v3h2v-3h-2z"/></svg>; }
export function AddNoteMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.379 0a1.5 1.5 0 0 1 1.06.44l4.122 4.12a1.5 1.5 0 0 1 .439 1.062v4.378a6 6 0 0 0-6 6v4h-6.5a1.5 1.5 0 0 1-1.5-1.5v-17a1.5 1.5 0 0 1 1.5-1.5h6.879zm-5.379 5h8v2h-8v-2zm5 4h-5v2h5v-2zm-5 4h3v2h-3v-2zm14.707 2.293a1 1 0 0 0-.707-.293h-2v-2a1 1 0 0 0-2 0v2h-2a1 1 0 0 0 0 2h2v2a1 1 0 0 0 2 0v-2h2a1 1 0 0 0 .707-1.707z" fillRule="evenodd"/></svg>; }
export function AddProductMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 1h7a1 1 0 0 1 1 1v7a.999.999 0 0 1-.29.71l-.29.29h-2.42a6 6 0 0 0-6 6v2.42l-.29.29a1 1 0 0 1-1.42 0l-7-7a.999.999 0 0 1 0-1.42l9-9a1.001 1.001 0 0 1 .71-.29zm3.667 4.747a1.5 1.5 0 1 0 1.666-2.494 1.5 1.5 0 0 0-1.666 2.494zm5.04 9.546a1 1 0 0 0-.707-.293h-2v-2a1 1 0 0 0-2 0v2h-2a1 1 0 0 0 0 2h2v2a1 1 0 0 0 2 0v-2h2a1 1 0 0 0 .707-1.707z"/></svg>; }
export function AdjustMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 5a1 1 0 0 1 1-1h2a1 1 0 0 1 0 2h-2a1 1 0 0 1-1-1Zm6 0a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1Zm0 10a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1Zm-6 0a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1Zm12-5a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1Zm-12 0a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1Z"/><path fillRule="evenodd" d="M7 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm6 7a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"/><path fillRule="evenodd" d="M13 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm-6 7a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"/></svg>; }
export function AffiliateMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5.116 5.407a2.5 2.5 0 1 0-1.916.575l.788 2.624a5.5 5.5 0 1 0 7.406 7.406l2.624.787a2.5 2.5 0 1 0 .575-1.915l-2.625-.788a5.474 5.474 0 0 0-.936-3.714l4.554-4.554a2.5 2.5 0 1 0-1.414-1.414l-4.554 4.554a5.475 5.475 0 0 0-3.714-.936l-.788-2.625zm4.884 8.093a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"/></svg>; }
export function AlertMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm-1-9a1 1 0 0 0 2 0v-2a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"/></svg>; }
export function AnalyticsMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15.5 3a1.5 1.5 0 0 0-1.5 1.5v12a1.5 1.5 0 0 0 1.5 1.5h1a1.5 1.5 0 0 0 1.5-1.5v-12a1.5 1.5 0 0 0-1.5-1.5h-1zm-7.5 5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v8a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-8zm-6 4a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v4a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-4z"/></svg>; }
export function AnalyticsMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 11a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0v-5Zm4-5a1 1 0 1 1 2 0v10a1 1 0 1 1-2 0v-10Zm4-2a1 1 0 1 1 2 0v12a1 1 0 1 1-2 0v-12Zm-12 4a1 1 0 0 1 2 0v8a1 1 0 1 1-2 0v-8Z"/></svg>; }
export function AppExtensionMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 17h-4.5a1.5 1.5 0 0 1-1.5-1.5v-4.5h6v6zm8-6v4.5a1.5 1.5 0 0 1-1.5 1.5h-4.5v-6h6zm-8-2h-6v-4.5a1.5 1.5 0 0 1 1.5-1.5h4.5v6zm10-7v5c0 .55-.45 1-1 1s-1-.45-1-1v-2.58l-4.29 4.29c-.2.19-.45.29-.71.29-.26 0-.51-.1-.71-.29-.39-.39-.39-1.03 0-1.42l4.29-4.29h-2.58c-.55 0-1-.45-1-1s.45-1 1-1h5c.08 0 .17.01.25.04.05 0 .09.02.14.04.12.05.23.12.32.21s.16.2.21.32c.02.05.04.09.04.14.03.08.04.17.04.25z"/></svg>; }
export function AppsMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 9h-8v-6.5a1.5 1.5 0 0 1 1.5-1.5h6.5v8zm0 2v8h-6.5a1.5 1.5 0 0 1-1.5-1.5v-6.5h8zm2 0v8h6.5a1.5 1.5 0 0 0 1.5-1.5v-6.5h-8zm4-10a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2h-2a1 1 0 1 1 0-2h2v-2a1 1 0 0 1 1-1z"/></svg>; }
export function AppsMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 17a2 2 0 0 1-2-2v-4h6v6h-4Zm6 0v-6h6v4a2 2 0 0 1-2 2h-4Zm-8-12v4h6v-6h-4a2 2 0 0 0-2 2Zm8 1a1 1 0 0 1 1-1h1v-1a1 1 0 1 1 2 0v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 0 1-1-1Z"/></svg>; }
export function ArchiveMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.5 1a1.5 1.5 0 0 0-1.5 1.5v1.5h18v-1.5a1.5 1.5 0 0 0-1.5-1.5h-15zm-.5 16.5a1.5 1.5 0 0 0 1.5 1.5h13a1.5 1.5 0 0 0 1.5-1.5v-11.5h-16v11.5zm5-8.5h6v2h-6v-2z"/></svg>; }
export function ArchiveMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 3a1.5 1.5 0 0 0-1.5 1.5v1.5h14v-1.5a1.5 1.5 0 0 0-1.5-1.5h-11zm-.5 12.5a1.5 1.5 0 0 0 1.5 1.5h9a1.5 1.5 0 0 0 1.5-1.5v-7.5h-12v7.5zm3-4.5h6v2h-6v-2z"/></svg>; }
export function ArrowDownMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m10.707 17.707 5-5a.999.999 0 1 0-1.414-1.414l-3.293 3.293v-11.586a1 1 0 1 0-2 0v11.586l-3.293-3.293a.999.999 0 1 0-1.414 1.414l5 5a.999.999 0 0 0 1.414 0z"/></svg>; }
export function ArrowLeftMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17 9h-11.586l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3.293-3.293h11.586a1 1 0 1 0 0-2z"/></svg>; }
export function ArrowRightMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m17.707 9.293-5-5a.999.999 0 1 0-1.414 1.414l3.293 3.293h-11.586a1 1 0 1 0 0 2h11.586l-3.293 3.293a.999.999 0 1 0 1.414 1.414l5-5a.999.999 0 0 0 0-1.414z"/></svg>; }
export function ArrowUpMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 17v-11.586l3.293 3.293a.999.999 0 1 0 1.414-1.414l-5-5a.999.999 0 0 0-1.414 0l-5 5a.997.997 0 0 0 0 1.414.999.999 0 0 0 1.414 0l3.293-3.293v11.586a1 1 0 1 0 2 0z"/></svg>; }
export function AttachmentMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5.243 20a5.228 5.228 0 0 1-3.707-1.533 5.213 5.213 0 0 1-1.536-3.708c0-1.402.546-2.719 1.536-3.708l9.515-9.519a5.25 5.25 0 0 1 8.553 1.7 5.21 5.21 0 0 1 .396 2.008 5.208 5.208 0 0 1-1.535 3.708l-4.258 4.26a3.124 3.124 0 0 1-5.092-1.012 3.098 3.098 0 0 1-.236-1.196c0-.835.324-1.619.914-2.208l4.5-4.501a1 1 0 1 1 1.414 1.414l-4.5 4.501a1.112 1.112 0 0 0-.328.794 1.114 1.114 0 0 0 1.121 1.12c.297 0 .582-.118.793-.327l4.258-4.26a3.223 3.223 0 0 0 .949-2.293c0-.866-.337-1.681-.949-2.293a3.248 3.248 0 0 0-4.586 0l-9.515 9.518a3.224 3.224 0 0 0-.95 2.295c0 .866.338 1.68.95 2.293a3.248 3.248 0 0 0 4.586 0l1.757-1.758a1 1 0 1 1 1.414 1.414l-1.757 1.758a5.236 5.236 0 0 1-3.707 1.533z"/></svg>; }
export function AutomationMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8.038.859a1 1 0 0 1 .99-.859h2.265a1 1 0 0 1 .99.859l.371 2.598c.703.268 1.35.646 1.924 1.113l2.438-.979a1 1 0 0 1 1.238.428l1.133 1.962a1 1 0 0 1-.249 1.287l-2.065 1.621a7.034 7.034 0 0 1 0 2.222l2.065 1.621a1 1 0 0 1 .249 1.287l-1.133 1.962a1 1 0 0 1-1.239.428l-2.437-.978a6.995 6.995 0 0 1-1.924 1.112l-.371 2.598a1 1 0 0 1-.99.859h-2.266a1 1 0 0 1-.99-.859l-.37-2.598a6.995 6.995 0 0 1-1.924-1.113l-2.438.979a1 1 0 0 1-1.239-.428l-1.132-1.962a1 1 0 0 1 .248-1.287l2.066-1.621a7.06 7.06 0 0 1 0-2.222l-2.066-1.621a1 1 0 0 1-.248-1.287l1.132-1.962a1 1 0 0 1 1.239-.428l2.438.979a6.993 6.993 0 0 1 1.923-1.113l.372-2.598Zm.74 5.66 4.598 3.065a.5.5 0 0 1 0 .832l-4.599 3.066a.5.5 0 0 1-.777-.416v-6.132a.5.5 0 0 1 .777-.416Z"/></svg>; }
export function BackspaceMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M20 15.5v-11a1.5 1.5 0 0 0-1.5-1.5h-10.878a1.5 1.5 0 0 0-1.061.44l-6.122 5.5a1.5 1.5 0 0 0 0 2.121l6.122 5.5a1.5 1.5 0 0 0 1.06.439h10.88a1.5 1.5 0 0 0 1.5-1.5zm-3.292-9.307a1 1 0 0 1 0 1.414l-2.293 2.293 2.293 2.293a1 1 0 0 1-1.415 1.414l-2.292-2.293-2.293 2.293a1 1 0 1 1-1.415-1.414l2.293-2.293-2.293-2.293a1 1 0 1 1 1.415-1.414l2.292 2.293 2.292-2.293a1 1 0 0 1 1.415 0z"/></svg>; }
export function BalanceMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4 1c-.493 0-1.211.14-1.834.588-.674.486-1.166 1.286-1.166 2.412v13.5a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-2.5h-3c-.493 0-1.211-.14-1.834-.588-.676-.487-1.166-1.286-1.166-2.412s.492-1.926 1.166-2.412a3.233 3.233 0 0 1 1.834-.588h3v-2.5c0-.824-.665-1.5-1.498-1.5h-.502v-2.5a1.5 1.5 0 0 0-1.5-1.5h-11.5zm12 10h3v2h-3c-.173 0-.456-.06-.666-.211-.159-.115-.334-.315-.334-.789 0-.474.175-.674.334-.789.21-.15.493-.211.666-.211zm-12-8a1 1 0 0 0-1 1v1h12v-2h-11z"/></svg>; }
export function BankMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M0 6h20c0-.604-.368-1.147-.928-1.371l-8.515-3.406a1.5 1.5 0 0 0-1.114 0l-8.515 3.406c-.56.224-.928.767-.928 1.371zm0 2v1a1 1 0 0 0 1 1v7a1 1 0 0 0 0 2h18a1 1 0 1 0 0-2v-7a1 1 0 0 0 1-1v-1h-20zm4 2h2v7h-2v-7zm7 0h-2v7h2v-7zm3 0h2v7h-2v-7z"/></svg>; }
export function BarcodeMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 3h1v-2h-1.5a1.5 1.5 0 0 0-1.5 1.5v1.5h2v-1zm14 0v1h2v-1.5a1.5 1.5 0 0 0-1.5-1.5h-1.5v2h1zm-1 14h1v-1h2v1.5a1.5 1.5 0 0 1-1.5 1.5h-1.5v-2zm-13 0v-1h-2v1.5a1.5 1.5 0 0 0 1.5 1.5h1.5v-2h-1zm-1-3h3v-8h-3v8zm12 0h-3v-8h3v8zm-7 0h2v-8h-2v8zm9 0h2v-8h-2v8z"/></svg>; }
export function BehaviorMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m9.37 8.07 10 4a1 1 0 0 1 .08 1.82l-3.7 1.86-1.85 3.7a1 1 0 0 1-.9.55h-.04a1 1 0 0 1-.89-.63l-4-10a1 1 0 0 1 1.3-1.3zm.337-3.363a1 1 0 0 1-1.707-.707v-3a1 1 0 0 1 2 0v3a1 1 0 0 1-.293.707zm-5 3.586a1 1 0 0 1-.707 1.707h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 .707.293zm-1-6a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414-1.414l-2-2zm12 0a1 1 0 0 0-1.414 0l-2 2a1 1 0 0 0 1.414 1.414l2-2a1 1 0 0 0 0-1.414zm-13.414 12 2-2a1 1 0 0 1 1.414 1.414l-2 2a1 1 0 0 1-1.414-1.414z"/></svg>; }
export function BehaviorMinor() { return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10.275 9.038a1 1 0 0 0-1.236 1.237l1.714 6a1 1 0 0 0 1.88.119l1.129-2.632 2.632-1.129a1 1 0 0 0-.12-1.88l-6-1.715Zm-.275-6.038a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1Zm-4.95 2.05a1 1 0 0 1 1.414 0l1.415 1.414a1 1 0 0 1-1.415 1.415l-1.414-1.415a1 1 0 0 1 0-1.414Zm9.9 0a1 1 0 0 1 0 1.414l-1.414 1.415a1 1 0 1 1-1.415-1.415l1.415-1.414a1 1 0 0 1 1.414 0Zm-7.071 7.071a1 1 0 0 1 0 1.415l-1.415 1.414a1 1 0 1 1-1.414-1.414l1.414-1.415a1 1 0 0 1 1.415 0Zm-.879-2.121a1 1 0 0 1-1 1h-2a1 1 0 1 1 0-2h2a1 1 0 0 1 1 1Z"/></svg>; }
export function BillingStatementDollarMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.5 0h11a1.5 1.5 0 0 1 1.5 1.5v18.223a.2.2 0 0 1-.335.148l-1.662-1.513a.5.5 0 0 0-.673 0l-1.66 1.51a.5.5 0 0 1-.673 0l-1.66-1.51a.5.5 0 0 0-.674 0l-1.66 1.51a.5.5 0 0 1-.673 0l-1.66-1.51a.5.5 0 0 0-.673 0l-1.662 1.512a.2.2 0 0 1-.335-.147v-18.223a1.5 1.5 0 0 1 1.5-1.5zm4.207 11.293c.667.667 1.29.706 1.316.707.528 0 .977-.448.977-1 0-.646-.128-.751-1.243-1.03h-.001c-1.031-.258-2.756-.69-2.756-2.97a2.993 2.993 0 0 1 2-2.815v-.185a1 1 0 0 1 2 0v.2a4.51 4.51 0 0 1 1.707 1.093 1 1 0 0 1-1.414 1.414c-.667-.667-1.291-.706-1.317-.707-.528 0-.976.448-.976 1 0 .646.127.751 1.242 1.03h.002c1.03.258 2.756.69 2.756 2.97a2.995 2.995 0 0 1-2 2.815v.185a1 1 0 0 1-2 0v-.2a4.49 4.49 0 0 1-1.707-1.093 1 1 0 1 1 1.414-1.414z"/></svg>; }
export function BillingStatementEuroMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 0h11c.828 0 1.5.677 1.5 1.512v18.21a.2.2 0 0 1-.334.149l-1.664-1.515a.497.497 0 0 0-.67 0l-1.664 1.514a.497.497 0 0 1-.67 0l-1.663-1.514a.497.497 0 0 0-.67 0l-1.663 1.514a.497.497 0 0 1-.67 0l-1.664-1.514a.497.497 0 0 0-.67 0l-1.664 1.515a.2.2 0 0 1-.334-.15v-18.209c0-.835.672-1.512 1.5-1.512zm2.543 8.865c-.02.324-.025.65-.014.974h-.268a.778.778 0 0 0-.538.214.716.716 0 0 0-.223.517c0 .194.08.38.223.517a.778.778 0 0 0 .538.214h.433c.418 2.123 1.58 3.699 3.198 3.699h.052c1.178.001 1.838-.227 2.401-1.207a.94.94 0 0 0-.095-1.07.999.999 0 0 0-.3-.244 1.038 1.038 0 0 0-1.115.091.977.977 0 0 0-.254.29.48.48 0 0 1-.11.152c-.072.056-.188.055-.525.055h-.054c-.375 0-.86-.692-1.136-1.766h1.058a.778.778 0 0 0 .538-.214.716.716 0 0 0 .223-.517c0-.194-.08-.38-.223-.517a.778.778 0 0 0-.538-.214h-1.27a7.719 7.719 0 0 1 .02-.974h1.249a.778.778 0 0 0 .538-.214.716.716 0 0 0 .223-.517c0-.194-.08-.38-.223-.517a.778.778 0 0 0-.538-.214h-.973c.283-.903.711-1.47 1.051-1.47.487 0 .569.034.731.325.13.216.344.376.595.445.25.068.52.04.75-.077a.978.978 0 0 0 .484-.554.931.931 0 0 0-.053-.722c-.567-1.025-1.348-1.35-2.507-1.35-1.541 0-2.67 1.43-3.131 3.403h-.5a.778.778 0 0 0-.538.214.716.716 0 0 0-.223.517c0 .194.08.38.223.517a.778.778 0 0 0 .538.214h.282z"/></svg>; }
export function BillingStatementPoundMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 0h11c.828 0 1.5.677 1.5 1.512v18.21a.2.2 0 0 1-.334.149l-1.664-1.515a.497.497 0 0 0-.67 0l-1.664 1.514a.497.497 0 0 1-.67 0l-1.663-1.514a.497.497 0 0 0-.67 0l-1.663 1.514a.497.497 0 0 1-.67 0l-1.664-1.514a.497.497 0 0 0-.67 0l-1.664 1.515a.2.2 0 0 1-.334-.15v-18.209c0-.835.672-1.512 1.5-1.512zm2.511 9.827h.247c-.16.943-.39 1.863-.696 2.76-.04.12-.06.244-.056.37 0 .276.106.542.296.737.19.196.447.306.715.306h4.472c.268 0 .525-.11.715-.306a1.06 1.06 0 0 0 .296-.737 1.06 1.06 0 0 0-.296-.738.996.996 0 0 0-.715-.305h-3.109c.18-.687.322-1.384.427-2.087h1.75c.268 0 .525-.11.714-.305a1.06 1.06 0 0 0 .297-.738 1.06 1.06 0 0 0-.297-.738.996.996 0 0 0-.715-.305h-1.543c.017-.355.026-.712.026-1.072 0-.985.385-1.492.877-1.573.35-.057.561.107.561.444 0 .276.107.542.297.737.19.196.447.306.715.306.268 0 .525-.11.715-.306a1.06 1.06 0 0 0 .296-.737c0-1.685-1.378-2.753-2.9-2.504-1.514.247-2.583 1.659-2.583 3.633 0 .36-.01.717-.028 1.072h-.479a.996.996 0 0 0-.715.305 1.06 1.06 0 0 0-.295.738c0 .277.107.542.296.738.19.195.447.305.715.305z"/></svg>; }
export function BillingStatementRupeeMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 0h11c.828 0 1.5.677 1.5 1.512v18.21a.2.2 0 0 1-.334.149l-1.664-1.515a.497.497 0 0 0-.67 0l-1.664 1.514a.497.497 0 0 1-.67 0l-1.663-1.514a.497.497 0 0 0-.67 0l-1.663 1.514a.497.497 0 0 1-.67 0l-1.664-1.514a.497.497 0 0 0-.67 0l-1.664 1.515a.2.2 0 0 1-.334-.15v-18.209c0-.835.672-1.512 1.5-1.512zm6.808 13.4-1.96-2.63c1.342-.21 2.254-1.288 2.552-2.694h.85a.75.75 0 1 0 0-1.499h-.763a4.427 4.427 0 0 0-.432-1.579h.945a1 1 0 1 0 0-1.998h-5a1 1 0 1 0 0 1.998h2.135c.449.297.754.86.844 1.58h-3.229a.75.75 0 1 0 0 1.498h3.1c-.252.756-.791 1.234-1.493 1.234-.285 0-.543-.02-.777-.056a1 1 0 0 0-1.258 1.489l2.89 3.86a1 1 0 0 0 1.596-1.204z"/></svg>; }
export function BillingStatementYenMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 0h11c.828 0 1.5.677 1.5 1.512v18.21a.2.2 0 0 1-.334.149l-1.664-1.515a.497.497 0 0 0-.67 0l-1.664 1.514a.497.497 0 0 1-.67 0l-1.663-1.514a.497.497 0 0 0-.67 0l-1.663 1.514a.497.497 0 0 1-.67 0l-1.664-1.514a.497.497 0 0 0-.67 0l-1.664 1.515a.2.2 0 0 1-.334-.15v-18.209c0-.835.672-1.512 1.5-1.512zm7.744 10.523h-1.25v-.75h1.25a.75.75 0 1 0 0-1.5h-.847l1.85-3.204a1 1 0 0 0-1.732-1l-1.515 2.624-1.515-2.624a1 1 0 0 0-1.732 1l1.85 3.204h-.859a.75.75 0 0 0 0 1.5h1.25v.75h-1.25a.75.75 0 1 0 0 1.5h1.25v1a1 1 0 0 0 2 0v-1h1.25a.75.75 0 0 0 0-1.5z"/></svg>; }
export function BlockMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 3.6a1.6 1.6 0 0 1 1.6-1.6h3.4a1 1 0 0 1 0 2h-3v3a1 1 0 0 1-2 0v-3.4zm14.4-1.6a1.6 1.6 0 0 1 1.6 1.6v3.4a1 1 0 1 1-2 0v-3h-3a1 1 0 1 1 0-2h3.4zm0 16h-3.4a1 1 0 1 1 0-2h3v-3a1 1 0 1 1 2 0v3.4a1.6 1.6 0 0 1-1.6 1.6zm-12.8 0a1.6 1.6 0 0 1-1.6-1.6v-3.4a1 1 0 1 1 2 0v3h3a1 1 0 1 1 0 2h-3.4z"/></svg>; }
export function BlockquoteMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 3.5a1 1 0 0 0-1 1v.5h.5a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-3a3 3 0 0 1 3-3v2zm5.5 1.5h-.5v-.5a1 1 0 0 1 1-1v-2a3 3 0 0 0-3 3v3a1.5 1.5 0 0 0 1.5 1.5h1a1.5 1.5 0 0 0 1.5-1.5v-1a1.5 1.5 0 0 0-1.5-1.5zm3.5 3a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1zm-8 3a1 1 0 1 0 0 2h15a1 1 0 1 0 0-2h-15zm-1 5a1 1 0 0 1 1-1h15a1 1 0 1 1 0 2h-15a1 1 0 0 1-1-1zm10-13a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2h-6z"/></svg>; }
export function BlogMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16 2a2 2 0 0 1 4 0v1h-4v-1zm-7.621-1a1.5 1.5 0 0 1 1.06.44l4.122 4.12a1.5 1.5 0 0 1 .439 1.062v10.878a1.5 1.5 0 0 1-1.5 1.5h-10a1.5 1.5 0 0 1-1.5-1.5v-15a1.5 1.5 0 0 1 1.5-1.5h5.879zm-4.379 4h4v2h-4v-2zm7 4v2h-7v-2h7zm-7 6v-2h5v2h-5zm12-10h4v11l-2 4-2-4v-11z"/></svg>; }
export function BugMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4 2a1 1 0 1 0-2 0v.228a7.166 7.166 0 0 0 3.187 5.96c-.056.338-.1.685-.131 1.039a11.254 11.254 0 0 0-3.357-1.124l-.534-.09a1 1 0 1 0-.329 1.973l.534.09a9.254 9.254 0 0 1 3.648 1.452c.012.347.03.687.057 1.02l-.607.729a11 11 0 0 0-2.4 5.233l-.054.326a1 1 0 1 0 1.973.328l.054-.325a9 9 0 0 1 1.473-3.636c.639 2.289 1.968 3.797 4.486 3.797s3.848-1.508 4.487-3.797a8.999 8.999 0 0 1 1.473 3.636l.054.325a1 1 0 1 0 1.973-.328l-.055-.326a11 11 0 0 0-2.4-5.233l-.607-.729c.027-.337.046-.683.058-1.035l.4-.3a5.236 5.236 0 0 1 2.282-.977l1.5-.25a1 1 0 1 0-.329-1.972l-1.5.25a7.236 7.236 0 0 0-2.401.855 13.69 13.69 0 0 0-.122-.932 7.166 7.166 0 0 0 3.187-5.959v-.228a1 1 0 1 0-2 0v.228c0 1.502-.65 2.894-1.727 3.855-.878-2.448-2.463-4.083-4.273-4.083-1.81 0-3.395 1.635-4.272 4.083a5.165 5.165 0 0 1-1.728-3.855v-.228z"/></svg>; }
export function ButtonCornerPillMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M16.28 2.79c-7.45 0-13.49 6.04-13.49 13.49v3.72h-2.79v-3.72c0-8.993 7.288-16.28 16.28-16.28h3.72v2.79h-3.72z"/></svg>; }
export function ButtonCornerRoundedMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8.837 2.79a6.047 6.047 0 0 0-6.046 6.047v11.163h-2.791v-11.163a8.837 8.837 0 0 1 8.837-8.837h11.163v2.79h-11.163z"/></svg>; }
export function ButtonCornerSquareMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h20v2.79h-17.21v17.21h-2.79v-20z"/></svg>; }
export function ButtonMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 5.5a1.5 1.5 0 0 1 1.5-1.5h13a1.5 1.5 0 0 1 1.5 1.5v4.95l-4.014-2.59c-1.71-1.104-3.953.19-3.853 2.224l.145 2.916h-6.778a1.5 1.5 0 0 1-1.5-1.5v-6z"/><path d="M12.131 9.999a.5.5 0 0 1 .77-.445l4.869 3.142a.5.5 0 0 1-.021.853l-1.425.823 1.229 2.128a1 1 0 1 1-1.732 1l-1.23-2.128-1.424.822a.5.5 0 0 1-.75-.408l-.286-5.786z"/></svg>; }
export function BuyButtonButtonLayoutMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 3h1v-2h-1.5a1.5 1.5 0 0 0-1.5 1.5v1.5h2v-1zm-2 7a4 4 0 0 1 4-4h10a4 4 0 0 1 0 8h-10a4 4 0 0 1-4-4zm16-6v-1h-1v-2h1.5a1.5 1.5 0 0 1 1.5 1.5v1.5h-2zm0 13h-1v2h1.5a1.5 1.5 0 0 0 1.5-1.5v-1.5h-2v1zm-14-1v1h1v2h-1.5a1.5 1.5 0 0 1-1.5-1.5v-1.5h2zm6 3h-3v-2h3v2zm2 0h3v-2h-3v2zm-2-16h-3v-2h3v2zm2 0h3v-2h-3v2z"/></svg>; }
export function BuyButtonHorizontalLayoutMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 3h1v-2h-1.5a1.5 1.5 0 0 0-1.5 1.5v1.5h2v-1zm14 0v1h2v-1.5a1.5 1.5 0 0 0-1.5-1.5h-1.5v2h1zm-14 13v1h1v2h-1.5a1.5 1.5 0 0 1-1.5-1.5v-1.5h2zm14 1h-1v2h1.5a1.5 1.5 0 0 0 1.5-1.5v-1.5h-2v1zm-8-3v-8h-6v8h6zm0 5h-3v-2h3v2zm-3-16h3v-2h-3v2zm8 16h-3v-2h3v2zm-3-16h3v-2h-3v2zm6 5h-6v-2h6v2zm-6 4h4v-2h-4v2z"/></svg>; }
export function BuyButtonMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M0 1.5a1.5 1.5 0 0 1 1.5-1.5h17a1.5 1.5 0 0 1 1.5 1.5v6a1.5 1.5 0 0 1-1.5 1.5h-5.889a1.5 1.5 0 0 1-1.5-1.5v-2.389a1.111 1.111 0 1 0-2.222 0v2.389a1.5 1.5 0 0 1-1.5 1.5h-5.889a1.5 1.5 0 0 1-1.5-1.5v-6z"/><path d="M7 5a3 3 0 0 1 6 0v4.384a.5.5 0 0 0 .356.479l2.695.808a2.5 2.5 0 0 1 1.756 2.748l-.633 4.435a2.5 2.5 0 0 1-2.475 2.146h-7.739a2.5 2.5 0 0 1-2.27-1.452l-2.06-4.464a2.417 2.417 0 0 1-.106-1.777c.21-.607.719-1.16 1.516-1.273 1.035-.148 2.016.191 2.961.82v-6.854zm3-1a1 1 0 0 0-1 1v7.793c0 1.39-1.609 1.921-2.527 1.16-.947-.784-1.59-.987-2.069-.948a.486.486 0 0 0 .042.241l2.06 4.463a.5.5 0 0 0 .454.291h7.74a.5.5 0 0 0 .494-.43l.633-4.434a.5.5 0 0 0-.35-.55l-2.695-.808a2.5 2.5 0 0 1-1.782-2.394v-4.384a1 1 0 0 0-1-1z"/></svg>; }
export function BuyButtonVerticalLayoutMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 3h1v-2h-1.5a1.5 1.5 0 0 0-1.5 1.5v1.5h2v-1zm14 0v1h2v-1.5a1.5 1.5 0 0 0-1.5-1.5h-1.5v2h1zm-14 13v1h1v2h-1.5a1.5 1.5 0 0 1-1.5-1.5v-1.5h2zm14 1h-1v2h1.5a1.5 1.5 0 0 0 1.5-1.5v-1.5h-2v1zm-16-11v3h2v-3h-2zm0 8v-3h2v3h-2zm16-8v3h2v-3h-2zm0 8v-3h2v3h-2zm-3-5v-6h-8v6h8zm-2 8h-6v-2h6v2zm-6-4h8v-2h-8v2z"/></svg>; }
export function CalendarMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M17.5 2h-2.5v-1a1 1 0 1 0-2 0v1h-7v-1a1 1 0 0 0-2 0v1h-1.5c-.8 0-1.5.7-1.5 1.5v15c0 .8.7 1.5 1.5 1.5h15c.8 0 1.5-.7 1.5-1.5v-15c0-.8-.7-1.5-1.5-1.5zm-14.5 16h14v-10h-14v10z"/></svg>; }
export function CalendarMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7 2a1 1 0 0 1 1 1v1h4v-1a1 1 0 1 1 2 0v1h1a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2h1v-1a1 1 0 0 1 1-1Zm-2 6v7h10v-7h-10Z"/></svg>; }
export function CalendarTickMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M15 2h2.5c.8 0 1.5.7 1.5 1.5v15c0 .8-.7 1.5-1.5 1.5h-15c-.8 0-1.5-.7-1.5-1.5v-15c0-.8.7-1.5 1.5-1.5h1.5v-1a1 1 0 0 1 2 0v1h7v-1a1 1 0 1 1 2 0v1zm2 16h-14v-10h14v10zm-4.707-7.707-3.293 3.293-1.293-1.293a1.001 1.001 0 0 0-1.707.707 1 1 0 0 0 .293.707l2 2a.997.997 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414z"/></svg>; }
export function CameraMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.74 1.786a1.5 1.5 0 0 1 1.319-.786h5.855a1.5 1.5 0 0 1 1.315.777l.55 1c.04.074.073.148.1.223h3.621a1.5 1.5 0 0 1 1.5 1.5v11a1.5 1.5 0 0 1-1.5 1.5h-17a1.5 1.5 0 0 1-1.5-1.5v-11a1.5 1.5 0 0 1 1.5-1.5h3.603c.025-.072.057-.144.095-.214l.542-1zm-3.74 3.214h3v2h-3v-2zm12 5a4 4 0 1 1-8 0 4 4 0 0 1 8 0z"/></svg>; }
export function CancelSmallMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6.707 5.293a1 1 0 0 0-1.414 1.414l3.293 3.293-3.293 3.293a1 1 0 1 0 1.414 1.414l3.293-3.293 3.293 3.293a1 1 0 0 0 1.414-1.414l-3.293-3.293 3.293-3.293a1 1 0 0 0-1.414-1.414l-3.293 3.293-3.293-3.293Z"/></svg>; }
export function CapitalMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.328 11.018h3.074c-.426.951-.704 2.033-.825 3.152a6.01 6.01 0 0 0-4.392 4.345c-.204.803.487 1.485 1.315 1.485h9c.828 0 1.52-.682 1.315-1.485a6.005 6.005 0 0 0-5.235-4.487 7.025 7.025 0 0 1 1.896-4.058l3.133-.648c1.897-.4 3.294-1.797 3.593-3.593l.799-4.491a1.7 1.7 0 0 0-.152-.738.784.784 0 0 0-.413-.4c-.246-.1-.515-.132-.733-.06l-4.59 1.597c-1.897.599-3.095 2.295-3.095 4.192v2.794l-.2.22-.598-2.815c-.4-1.896-1.996-3.293-3.992-3.693l-5.09-.798c-.362-.047-.699 0-.899.3-.2.299-.299.698-.2.997l1.698 4.99a4.869 4.869 0 0 0 4.59 3.194Zm8.283-7.485 3.094-.998-.599 2.795a2.564 2.564 0 0 1-1.996 1.996l-2.195.499v-1.996c0-.998.698-1.996 1.696-2.296Zm-10.978 3.693-1.098-3.393 3.393.499c1.198.2 2.196 1.097 2.396 2.195l.399 2.495h-2.395c-1.198 0-2.296-.698-2.695-1.796Z"/></svg>; }
export function CapturePaymentMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15 9a2 2 0 1 1-.001 4.001 2 2 0 0 1 .001-4.001zm-5 0a2 2 0 1 1 .001-4.001 2 2 0 0 1-.001 4.001zm6-7a2 2 0 1 1-.001 4.001 2 2 0 0 1 .001-4.001zm-3 14a1 1 0 1 1 0 2h-6a.998.998 0 0 1-.243-.03l-4-1a1 1 0 0 1-.757-.97v-3c0-.431.275-.813.684-.948l3-1a.947.947 0 0 1 .294-.047c.007-.001.014-.005.022-.005h3a1 1 0 1 1 0 2h-1.834c.599 1.807 2.828 3 5.834 3z"/></svg>; }
export function CardReaderChipMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M14.5 0a1.5 1.5 0 0 1 1.5 1.5v7.5h-3v-9h1.5zm-3.5 0h-5.5a1.5 1.5 0 0 0-1.5 1.5v7.5h7v-9zm-7.5 11a1.5 1.5 0 0 0-1.5 1.5v5.5h-1a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2h-1v-5.5a1.5 1.5 0 0 0-1.5-1.5h-13z"/></svg>; }
export function CardReaderMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M0 1.5a1.5 1.5 0 0 1 1.5-1.5h17a1.5 1.5 0 0 1 1.5 1.5v1.5h-20v-1.5zm0 3.5v7.5a1.5 1.5 0 0 0 1.5 1.5h2.5a6 6 0 0 1 12 0h2.5a1.5 1.5 0 0 0 1.5-1.5v-7.5h-20z"/><path d="M10 10a4 4 0 0 0-4 4v3h3v2a1 1 0 1 0 2 0v-2h3v-3a4 4 0 0 0-4-4z"/></svg>; }
export function CardReaderTapMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 8a6 6 0 0 1 6-6 1 1 0 0 0 0-2 8 8 0 0 0-8 8 1 1 0 0 0 2 0z"/><path d="M8 6a2 2 0 0 0-2 2 1 1 0 0 1-2 0 4 4 0 0 1 4-4 1 1 0 0 1 0 2zm7 0h-4.5a1.5 1.5 0 0 0-1.5 1.5v11a1.5 1.5 0 0 0 1.5 1.5h4.5v-14zm3.5 0a1.5 1.5 0 0 1 1.5 1.5v11a1.5 1.5 0 0 1-1.5 1.5h-1.5v-14h1.5z"/></svg>; }
export function CaretDownMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M13.098 8h-6.196c-.751 0-1.172.754-.708 1.268l3.098 3.432c.36.399 1.055.399 1.416 0l3.098-3.433c.464-.513.043-1.267-.708-1.267Z"/></svg>; }
export function CaretUpMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6.902 12h6.196c.751 0 1.172-.754.708-1.268l-3.098-3.432c-.36-.399-1.055-.399-1.416 0l-3.098 3.433c-.464.513-.043 1.267.708 1.267Z"/></svg>; }
export function CartDownMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a1 1 0 1 0-2 0v3.586l-1.293-1.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l3-3a1 1 0 0 0-1.414-1.414l-1.293 1.293v-3.586z"/><path fillRule="evenodd" d="M1 1a1 1 0 0 1 1-1h1.5a1.5 1.5 0 0 1 1.5 1.5v8.5h11.133l.877-6.141a1 1 0 1 1 1.98.282l-.939 6.571a1.5 1.5 0 0 1-1.485 1.288h-11.566v2h10a3 3 0 1 1-2.83 2h-5.34a3 3 0 1 1-3.83-1.83v-12.17h-1a1 1 0 0 1-1-1zm13 16a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm-11 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0z"/></svg>; }
export function CartMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M1 1c0-.552.45-1 1.004-1h1.505c.831 0 1.505.672 1.505 1.5v.56l12.574.908c.877.055 1.52.843 1.397 1.71l-.866 6.034a1.504 1.504 0 0 1-1.489 1.288h-11.616v2h10.043a3.005 3.005 0 0 1 3.011 3c0 1.657-1.348 3-3.01 3a3.005 3.005 0 0 1-2.84-4h-5.368a3.005 3.005 0 0 1-2.84 4 3.005 3.005 0 0 1-3.01-3c0-1.306.838-2.418 2.007-2.83v-12.17h-1.003a1.002 1.002 0 0 1-1.004-1zm4.014 3.064v5.936h11.18l.727-5.07-11.907-.866zm9.04 12.936c0-.552.449-1 1.003-1 .554 0 1.004.448 1.004 1s-.45 1-1.004 1a1.002 1.002 0 0 1-1.003-1zm-11.047 0c0-.552.45-1 1.004-1s1.003.448 1.003 1-.449 1-1.003 1a1.002 1.002 0 0 1-1.004-1z"/></svg>; }
export function CartUpMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 3.414v3.586a1 1 0 1 0 2 0v-3.586l1.293 1.293a1 1 0 1 0 1.414-1.414l-3-3a.998.998 0 0 0-1.414 0l-3 3a1 1 0 0 0 1.414 1.414l1.293-1.293z"/><path fillRule="evenodd" d="M1 1a1 1 0 0 1 1-1h1.5a1.5 1.5 0 0 1 1.5 1.5v8.5h11.133l.877-6.141a1 1 0 1 1 1.98.282l-.939 6.571a1.5 1.5 0 0 1-1.485 1.288h-11.566v2h10a3 3 0 1 1-2.83 2h-5.34a3 3 0 1 1-3.83-1.83v-12.17h-1a1 1 0 0 1-1-1zm13 16a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm-11 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0z"/></svg>; }
export function CashDollarMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 20c-5.514 0-10-4.486-10-10s4.486-10 10-10 10 4.486 10 10-4.486 10-10 10zm1-15a1 1 0 1 0-2 0v.17a3 3 0 0 0-2 2.83c0 1.013.36 1.77 1.025 2.269.54.405 1.215.572 1.666.685l.066.016c.55.138.835.224 1.018.361.085.064.225.182.225.669a1 1 0 0 1-.984 1 1.611 1.611 0 0 1-.325-.074 2.533 2.533 0 0 1-.984-.633 1 1 0 0 0-1.414 1.414 4.548 4.548 0 0 0 1.707 1.097v.196a1 1 0 1 0 2 0v-.17a3 3 0 0 0 2-2.83c0-1.013-.36-1.77-1.025-2.269-.54-.405-1.215-.572-1.666-.685l-.066-.016c-.55-.138-.835-.224-1.018-.361-.085-.064-.225-.182-.225-.669a1 1 0 0 1 .984-1 1.618 1.618 0 0 1 .325.074c.245.081.606.255.984.633a1 1 0 1 0 1.414-1.414 4.547 4.547 0 0 0-1.707-1.097v-.196z"/></svg>; }
export function CashEuroMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M20 10c0-5.514-4.486-10-10-10s-10 4.486-10 10 4.486 10 10 10 10-4.486 10-10zm-12.986.392a10.62 10.62 0 0 1 .014-1h-.278a.75.75 0 1 1 0-1.5h.49c.456-2.024 1.567-3.492 3.086-3.492 1.142 0 1.911.334 2.47 1.385a.992.992 0 0 1-1.75.932c-.16-.298-.24-.333-.72-.333-.335 0-.757.581-1.036 1.508h.959a.75.75 0 1 1 0 1.5h-1.231a8.247 8.247 0 0 0-.019 1h1.251a.75.75 0 1 1 0 1.5h-1.042c.272 1.102.749 1.812 1.119 1.812h.053c.329 0 .444.001.516-.054.04-.032.067-.081.11-.159a.993.993 0 0 1 1.738.958c-.555 1.006-1.205 1.24-2.366 1.239h-.051c-1.594 0-2.739-1.617-3.151-3.796h-.426a.75.75 0 1 1 0-1.5h.264z"/></svg>; }
export function CashPoundMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M20 10c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10 10 4.477 10 10zm-12.217 1h-.244a.997.997 0 0 1-1-1 1.003 1.003 0 0 1 1-1h.472c.018-.34.028-.682.028-1.027 0-1.893 1.057-3.246 2.555-3.483 1.504-.239 2.867.785 2.867 2.4a1 1 0 1 1-2 0c0-.323-.21-.48-.555-.425-.487.077-.867.563-.867 1.508 0 .345-.009.687-.026 1.027h1.526a1.003 1.003 0 0 1 1 1 1 1 0 0 1-1 1h-1.73a18.65 18.65 0 0 1-.422 2h2.652a1.003 1.003 0 0 1 1 1 1 1 0 0 1-1 1h-4a.997.997 0 0 1-1-1 .994.994 0 0 1 .056-.354c.302-.86.53-1.742.688-2.646z"/></svg>; }
export function CashRupeeMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M20 10c0-5.514-4.486-10-10-10s-10 4.486-10 10 4.486 10 10 10 10-4.486 10-10zm-10.474 2.062 2.08 2.761a1 1 0 1 1-1.597 1.204l-3.009-3.993a1 1 0 0 1 1.258-1.49c.234.037.492.056.777.056.702 0 1.241-.478 1.493-1.235h-3.1a.75.75 0 1 1 0-1.5h3.229c-.09-.72-.395-1.283-.844-1.58h-2.135a1 1 0 1 1 0-2h5a1 1 0 1 1 0 2h-.945c.233.467.38 1.004.432 1.58h.763a.75.75 0 1 1 0 1.5h-.85c-.298 1.407-1.21 2.486-2.552 2.697z"/></svg>; }
export function CashYenMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M20 10c0-5.514-4.486-10-10-10s-10 4.486-10 10 4.486 10 10 10 10-4.486 10-10zm-9.025 1.754h1.25a.75.75 0 1 1 0 1.5h-1.25v1a1 1 0 0 1-2 0v-1h-1.25a.75.75 0 1 1 0-1.5h1.25v-.75h-1.25a.75.75 0 1 1 0-1.5h.859l-1.85-3.204a1 1 0 0 1 1.732-1l1.515 2.624 1.515-2.624a1 1 0 0 1 1.732 1l-1.85 3.204h.847a.75.75 0 1 1 0 1.5h-1.25v.75z"/></svg>; }
export function CategoriesMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 2a1.5 1.5 0 0 0-1.5 1.5v.5h12v-.5a1.5 1.5 0 0 0-1.5-1.5h-9zm-3.5 5.5a1.5 1.5 0 0 1 1.5-1.5h13a1.5 1.5 0 0 1 1.5 1.5v.5h-16v-.5zm-1 4a1.5 1.5 0 0 1 1.5-1.5h15a1.5 1.5 0 0 1 1.5 1.5v7a1.5 1.5 0 0 1-1.5 1.5h-15a1.5 1.5 0 0 1-1.5-1.5v-7z"/></svg>; }
export function ChannelsMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15.284 14.546a2.975 2.975 0 0 1 1.716-.546c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3a2.94 2.94 0 0 1 .129-.822l-1.575-1.125a3.964 3.964 0 0 1-2.554.947 3.964 3.964 0 0 1-2.554-.947l-1.575 1.125c.076.262.129.535.129.822 0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3c.615 0 1.214.191 1.716.546l1.56-1.114a3.97 3.97 0 0 1-.276-1.432c0-1.858 1.28-3.411 3-3.858v-2.327a2.993 2.993 0 0 1-2-2.815c0-1.654 1.346-3 3-3s3 1.346 3 3a2.996 2.996 0 0 1-2 2.816v2.326c1.72.447 3 2 3 3.858-.003.49-.096.976-.276 1.432l1.56 1.114zm1.037 3.146a1 1 0 0 0 .679.308 1 1 0 0 0 0-2 1 1 0 0 0-.679 1.692zm-14 0a1 1 0 0 0 .679.308 1 1 0 0 0 0-2 1 1 0 0 0-.679 1.692zm8.679-14.692c0-.551-.449-1-1-1-.551 0-1 .449-1 1 0 .551.449 1 1 1 .551 0 1-.449 1-1z"/></svg>; }
export function ChatMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 0c-5.514 0-10 4.486-10 10 0 1.728.45 3.42 1.304 4.924l-1.203 3.041c-.458 1.209.726 2.393 1.935 1.934l3.04-1.204a9.944 9.944 0 0 0 4.924 1.305c5.514 0 10-4.486 10-10s-4.486-10-10-10zm-4.707 9.293a.997.997 0 0 1 1.414 0 .997.997 0 0 1 0 1.414.997.997 0 0 1-1.414 0 .997.997 0 0 1 0-1.414zm4 0a.997.997 0 0 1 1.414 0 .997.997 0 0 1 0 1.414 1.003 1.003 0 0 1-1.414 0 1.003 1.003 0 0 1 0-1.414zm4.707-.293a.997.997 0 0 0-1 1 .997.997 0 0 0 1 1 .997.997 0 0 0 1-1 .997.997 0 0 0-1-1z"/></svg>; }
export function ChecklistAlternateMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 2a2 2 0 1 1 4 0h3.5a1.5 1.5 0 0 1 1.5 1.5v15a1.5 1.5 0 0 1-1.5 1.5h-11a1.5 1.5 0 0 1-1.5-1.5v-15a1.5 1.5 0 0 1 1.5-1.5h3.5zm-1 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1 5a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm8-10.5a1.5 1.5 0 0 0-1.5-1.5h-5a1.5 1.5 0 0 0-1.5 1.5v.5h8v-.5zm-5 6.5h6v-2h-6v2zm0 2h6v2h-6v-2z"/></svg>; }
export function ChecklistMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 0a2 2 0 0 0-2 2h-4.5a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h13a1.5 1.5 0 0 0 1.5-1.5v-15a1.5 1.5 0 0 0-1.5-1.5h-4.5a2 2 0 0 0-2-2zm-4 6v-.5a1.5 1.5 0 0 1 1.5-1.5h5a1.5 1.5 0 0 1 1.5 1.5v.5h-8zm7.707 3.293a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 1 1 1.414-1.414l1.293 1.293 3.293-3.293a1 1 0 0 1 1.414 0z"/></svg>; }
export function CheckoutMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M1 1c0-.552.45-1 1.004-1h1.505c.831 0 1.505.672 1.505 1.5v.56l12.574.908c.877.055 1.52.843 1.397 1.71l-.866 6.034a1.504 1.504 0 0 1-1.489 1.288h-11.616v2h10.043a3.005 3.005 0 0 1 3.011 3c0 1.657-1.348 3-3.01 3a3.005 3.005 0 0 1-2.84-4h-5.368a3.005 3.005 0 0 1-2.84 4 3.005 3.005 0 0 1-3.01-3c0-1.306.838-2.418 2.007-2.83v-12.17h-1.003a1.002 1.002 0 0 1-1.004-1zm13.054 16c0-.552.449-1 1.003-1 .554 0 1.004.448 1.004 1s-.45 1-1.004 1a1.002 1.002 0 0 1-1.003-1zm-11.047 0c0-.552.45-1 1.004-1s1.003.448 1.003 1-.449 1-1.003 1a1.002 1.002 0 0 1-1.004-1z"/></svg>; }
export function ChevronDownMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 14a.997.997 0 0 1-.707-.293l-5-5a.999.999 0 1 1 1.414-1.414l4.293 4.293 4.293-4.293a.999.999 0 1 1 1.414 1.414l-5 5a.997.997 0 0 1-.707.293z"/></svg>; }
export function ChevronLeftMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M12 16a.997.997 0 0 1-.707-.293l-5-5a.999.999 0 0 1 0-1.414l5-5a.999.999 0 1 1 1.414 1.414l-4.293 4.293 4.293 4.293a.999.999 0 0 1-.707 1.707z"/></svg>; }
export function ChevronRightMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8 16a.999.999 0 0 1-.707-1.707l4.293-4.293-4.293-4.293a.999.999 0 1 1 1.414-1.414l5 5a.999.999 0 0 1 0 1.414l-5 5a.997.997 0 0 1-.707.293z"/></svg>; }
export function ChevronUpMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15 13a.997.997 0 0 1-.707-.293l-4.293-4.293-4.293 4.293a.999.999 0 1 1-1.414-1.414l5-5a.999.999 0 0 1 1.414 0l5 5a.999.999 0 0 1-.707 1.707z"/></svg>; }
export function CircleAlertMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 0c-5.514 0-10 4.486-10 10s4.486 10 10 10 10-4.486 10-10-4.486-10-10-10zm-1 6a1 1 0 1 1 2 0v4a1 1 0 1 1-2 0v-4zm1 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/></svg>; }
export function CircleCancelMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M0 10c0-5.514 4.486-10 10-10s10 4.486 10 10-4.486 10-10 10-10-4.486-10-10zm7.707-3.707a1 1 0 0 0-1.414 1.414l2.293 2.293-2.293 2.293a1 1 0 1 0 1.414 1.414l2.293-2.293 2.293 2.293a1 1 0 0 0 1.414-1.414l-2.293-2.293 2.293-2.293a1 1 0 0 0-1.414-1.414l-2.293 2.293-2.293-2.293z"/></svg>; }
export function CircleCancelMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm-2.293 4.293a1 1 0 0 0-1.414 1.414l2.293 2.293-2.293 2.293a1 1 0 1 0 1.414 1.414l2.293-2.293 2.293 2.293a1 1 0 1 0 1.414-1.414l-2.293-2.293 2.293-2.293a1 1 0 0 0-1.414-1.414l-2.293 2.293-2.293-2.293z"/></svg>; }
export function CircleChevronDownMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0zm-4.293-1.707a1 1 0 0 0-1.414 0l-2.293 2.293-2.293-2.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l3-3a1 1 0 0 0 0-1.414z"/></svg>; }
export function CircleChevronLeftMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm1.707 4.293a1 1 0 0 1 0 1.414l-2.293 2.293 2.293 2.293a1 1 0 0 1-1.414 1.414l-3-3a1 1 0 0 1 0-1.414l3-3a1 1 0 0 1 1.414 0z"/></svg>; }
export function CircleChevronRightMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a8 8 0 1 1 0 16 8 8 0 0 1 0-16zm-1.707 4.293a1 1 0 0 0 0 1.414l2.293 2.293-2.293 2.293a1 1 0 1 0 1.414 1.414l3-3a1 1 0 0 0 0-1.414l-3-3a1 1 0 0 0-1.414 0z"/></svg>; }
export function CircleChevronUpMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M18 10a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-4.293 1.707a1 1 0 0 1-1.414 0l-2.293-2.293-2.293 2.293a1 1 0 0 1-1.414-1.414l3-3a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1 0 1.414z"/></svg>; }
export function CircleDisabledMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10c0-1.846.635-3.543 1.688-4.897l11.209 11.209a7.954 7.954 0 0 1-4.897 1.688c-4.411 0-8-3.589-8-8zm14.312 4.897-11.209-11.209a7.954 7.954 0 0 1 4.897-1.688c4.411 0 8 3.589 8 8a7.952 7.952 0 0 1-1.688 4.897zm-16.312-4.897c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10-10 4.486-10 10z"/></svg>; }
export function CircleDisableMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4 10c0-1.294.416-2.49 1.115-3.471l8.356 8.356a5.961 5.961 0 0 1-3.471 1.115c-3.309 0-6-2.691-6-6zm12 0c0 1.294-.416 2.49-1.115 3.471l-8.356-8.356a5.961 5.961 0 0 1 3.471-1.115c3.309 0 6 2.691 6 6zm-14 0c0 4.411 3.589 8 8 8s8-3.589 8-8-3.589-8-8-8-8 3.589-8 8z"/></svg>; }
export function CircleDotsMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M0 10c0-5.514 4.486-10 10-10s10 4.486 10 10-4.486 10-10 10-10-4.486-10-10zm10 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-3-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/></svg>; }
export function CircleDownMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 20c-5.514 0-10-4.486-10-10s4.486-10 10-10 10 4.486 10 10-4.486 10-10 10zm-1-8.414-1.293-1.293a1 1 0 0 0-1.414 1.414l3 3a.998.998 0 0 0 1.414 0l3-3a1 1 0 0 0-1.414-1.414l-1.293 1.293v-5.586a1 1 0 0 0-2 0v5.586z"/></svg>; }
export function CircleInformationMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 20c5.514 0 10-4.486 10-10s-4.486-10-10-10-10 4.486-10 10 4.486 10 10 10zm1-6a1 1 0 1 1-2 0v-4a1 1 0 1 1 2 0v4zm-1-9a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/></svg>; }
export function CircleLeftMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M0 10c0-5.514 4.486-10 10-10s10 4.486 10 10-4.486 10-10 10-10-4.486-10-10zm8.414-1 1.293-1.293a1 1 0 0 0-1.414-1.414l-3 3a.997.997 0 0 0 0 1.414l3 3a1 1 0 0 0 1.414-1.414l-1.293-1.293h5.586a1 1 0 0 0 0-2h-5.586z"/></svg>; }
export function CircleMinusMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 20c-5.514 0-10-4.486-10-10s4.486-10 10-10 10 4.486 10 10-4.486 10-10 10zm-4-11a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2h-8z"/></svg>; }
export function CircleMinusMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15 10a1 1 0 0 1-1 1h-8a1 1 0 1 1 0-2h8a1 1 0 0 1 1 1zm-5-8a8 8 0 1 0 0 16 8 8 0 0 0 0-16z"/></svg>; }
export function CircleMinusOutlineMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6zm-3-7a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2h-6z"/></svg>; }
export function CirclePlusMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M0 10c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10-10 4.486-10 10zm5 0a1 1 0 0 1 1-1h3v-3a1 1 0 1 1 2 0v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3h-3a1 1 0 0 1-1-1z"/></svg>; }
export function CirclePlusMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15 10a1 1 0 0 1-1 1h-3v3a1 1 0 1 1-2 0v-3h-3a1 1 0 1 1 0-2h3v-3a1 1 0 0 1 2 0v3h3a1 1 0 0 1 1 1zm-5-8a8 8 0 1 0 0 16 8 8 0 0 0 0-16z"/></svg>; }
export function CirclePlusOutlineMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6zm3-7h-2v-2a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2z"/></svg>; }
export function CircleRightMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M20 10c0 5.514-4.486 10-10 10s-10-4.486-10-10 4.486-10 10-10 10 4.486 10 10zm-8.414 1-1.293 1.293a1 1 0 0 0 1.414 1.414l3-3a.997.997 0 0 0 0-1.414l-3-3a1 1 0 1 0-1.414 1.414l1.293 1.293h-5.586a1 1 0 0 0 0 2h5.586z"/></svg>; }
export function CircleTickMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M0 10a10 10 0 1 0 20 0 10 10 0 0 0-20 0zm15.2-1.8a1 1 0 0 0-1.4-1.4l-4.8 4.8-2.3-2.3a1 1 0 0 0-1.4 1.4l3 3c.4.4 1 .4 1.4 0l5.5-5.5z"/></svg>; }
export function CircleTickMinor() { return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fillRule="evenodd" d="M2 10a8 8 0 1 0 16 0 8 8 0 0 0-16 0Zm12.16-1.44a.8.8 0 0 0-1.12-1.12l-3.84 3.84-1.84-1.84a.8.8 0 0 0-1.12 1.12l2.4 2.4c.32.32.8.32 1.12 0l4.4-4.4Z"/></svg>; }
export function CircleTickOutlineMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm0-14c-3.309 0-6 2.691-6 6s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6zm-1 9a.997.997 0 0 1-.707-.293l-2-2a1 1 0 1 1 1.414-1.414l1.293 1.293 3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4a.996.996 0 0 1-.707.293z"/></svg>; }
export function CircleUpMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 0c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm1 8.414 1.293 1.293a1 1 0 1 0 1.414-1.414l-3-3a.998.998 0 0 0-1.414 0l-3 3a1 1 0 0 0 1.414 1.414l1.293-1.293v5.586a1 1 0 1 0 2 0v-5.586z"/></svg>; }
export function ClipboardMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15 2a1 1 0 0 1 1 1v13.5a1.5 1.5 0 0 1-1.5 1.5h-9a1.5 1.5 0 0 1-1.5-1.5v-13.5a1 1 0 1 1 2 0v1a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-1a1 1 0 0 1 1-1zm-4 2h-2a1 1 0 1 1 0-2h2a1 1 0 1 1 0 2z"/></svg>; }
export function ClockMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 20c-5.514 0-10-4.486-10-10s4.486-10 10-10 10 4.486 10 10-4.486 10-10 10zm1-15a1 1 0 1 0-2 0v5a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414l-2.707-2.707v-4.586z"/></svg>; }
export function ClockMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm2.293-4.707a.997.997 0 0 1-.707-.293l-2.293-2.293a.997.997 0 0 1-.293-.707v-4a1 1 0 1 1 2 0v3.586l2 2a.999.999 0 0 1-.707 1.707z"/></svg>; }
export function CodeMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.707 9.707a.996.996 0 0 0 .293-.707v-4a1 1 0 0 1 1-1 1 1 0 0 0 0-2c-1.654 0-3 1.346-3 3v3.586l-.707.707a.999.999 0 0 0 0 1.414l.707.707v3.586c0 1.654 1.346 3 3 3a1 1 0 0 0 0-2 1 1 0 0 1-1-1v-4a.996.996 0 0 0-.293-.707l-.293-.293.293-.293zm17.217-.09a1.001 1.001 0 0 0-.217-.324l-.707-.707v-3.586c0-1.654-1.346-3-3-3a1 1 0 1 0 0 2 1 1 0 0 1 1 1v4a.997.997 0 0 0 .293.707l.293.293-.293.293a.996.996 0 0 0-.293.707v4a1 1 0 0 1-1 1 1 1 0 1 0 0 2c1.654 0 3-1.346 3-3v-3.586l.707-.707a1.001 1.001 0 0 0 .217-1.09zm-7.227-4.333a1.002 1.002 0 0 0-1.63.346l-3.996 8a.999.999 0 0 0 .56 1.299 1.006 1.006 0 0 0 1.302-.557l3.995-8a.997.997 0 0 0-.23-1.088z"/></svg>; }
export function CollectionsMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6.948.001c.394 0 .772.159 1.052.439l1.477 1.68-3.638 4.12a3.568 3.568 0 0 0-.872 2.33v9.43h-2.48a1.48 1.48 0 0 1-1.051-.44 1.507 1.507 0 0 1-.436-1.06v-9.88a1.497 1.497 0 0 1 .377-1l3.48-4 1.04-1.18a1.48 1.48 0 0 1 1.052-.439zm7.092 2.439 4.58 5.13c.247.275.383.631.381 1v9.93c0 .399-.159.78-.441 1.062a1.51 1.51 0 0 1-1.065.439h-9.039a1.509 1.509 0 0 1-1.033-.457 1.497 1.497 0 0 1-.423-1.044v-9.88a1.487 1.487 0 0 1 .382-1l3.524-4.001 1.005-1.18a1.51 1.51 0 0 1 2.128 0zm-1.9 5.807a1.51 1.51 0 0 0 1.901-.186 1.497 1.497 0 0 0-.489-2.447 1.512 1.512 0 0 0-1.641.325 1.498 1.498 0 0 0 .228 2.308z"/></svg>; }
export function ColorNoneMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.759 4.35a1 1 0 0 0-1.41-.108s-14.036 12.032-14.055 12.05a1 1 0 0 0 0 1.415.992.992 0 0 0 1.35.043l.008.01 1.963-1.683a5.97 5.97 0 0 0 4.385 1.923 6 6 0 0 0 6-6c0-1.157-.51-2.48-1.223-3.777l2.873-2.463a.999.999 0 0 0 .108-1.41zm-13.75 7.834c-.002-.062-.01-.121-.01-.184 0-4 6-10 6-10s1.2 1.203 2.526 2.885l-8.516 7.299z"/></svg>; }
export function ColorsMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18.868 12.48a4.529 4.529 0 0 0 1.132-3.176v-.19a9.998 9.998 0 0 0-1.916-5.041 10.03 10.03 0 0 0-14.53-1.7 10.005 10.005 0 0 0-3.303 9.848 10.001 10.001 0 0 0 2.566 4.744 10.02 10.02 0 0 0 4.66 2.725 19.9 19.9 0 0 0 4.007.31h1.153c.375 0 .734-.195.985-.474a1.47 1.47 0 0 0 .378-1.019 1.5 1.5 0 0 0-1.5-1.5c-.398 0-.795-.167-1.076-.448a1.5 1.5 0 0 1 1.062-2.562h3.366a4.54 4.54 0 0 0 3.016-1.517zm-6.868-8.98a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-6.5 3.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm-1 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm10-4a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/></svg>; }
export function Column1Major() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16 2h-12v16h12v-16Zm-12-2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-16a2 2 0 0 0-2-2h-12Z"/></svg>; }
export function Columns2Major() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M0 2a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2h-16a2 2 0 0 1-2-2v-16Zm11 0h7v16h-7v-16Zm-2 0h-7v16h7v-16Z"/></svg>; }
export function Columns3Major() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M2 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-16a2 2 0 0 0-2-2h-16Zm16 2h-4v16h4v-16Zm-10 0h4v16h-4v-16Zm-2 0h-4v16h4v-16Z"/></svg>; }
export function Columns3Minor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16 2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2v-12a2 2 0 0 1 2-2h12Zm-2.667 2h2.667v12h-2.667v-12Zm-4.666 0h2.666v12h-2.666v-12Zm-2 0h-2.667v12h2.667v-12Z"/></svg>; }
export function ColumnWithTextMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 7v-6h-6.5a1.5 1.5 0 0 0-1.5 1.5v4.5h8zm10-4.5a1.5 1.5 0 0 0-1.5-1.5h-6.5v6h8v-4.5zm-18 6.5h8v2h-8v-2zm18 0h-8v2h8v-2zm-18 4h8v2h-8v-2zm18 0h-8v2h8v-2zm-18 4h4v2h-4v-2zm14 0h-4v2h4v-2z"/></svg>; }
export function ComposeMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M18 9a1 1 0 0 0-1 1v7h-14v-14h7a1 1 0 1 0 0-2h-7.5a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-7.5a1 1 0 0 0-1-1z"/><path d="m15.53 2.029 2.439 2.44-7.739 7.738-3.23.792.792-3.231 7.738-7.74zm3.558 1.321-2.438-2.437.406-.407a1.725 1.725 0 0 1 2.44 2.439l-.408.407z"/></svg>; }
export function ConfettiMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M12.384 8.92a1.01 1.01 0 0 1-.363.08 1.01 1.01 0 0 1-.553-.17.914.914 0 0 1-.373-.452.93.93 0 0 1-.08-.572.991.991 0 0 1 .271-.522l1.007-1.004a1.01 1.01 0 0 1 1.54.17 1.001 1.001 0 0 1-.12 1.255l-1.007 1.005a1.158 1.158 0 0 1-.322.21zm-4.354-5.163a1 1 0 0 0 1.94.486l-1.94-.486zm2.44-1.514a1 1 0 1 0-1.94-.486l1.94.486zm-.5 2 .5-2-1.94-.486-.5 2 1.94.486zm5.755 5.796a1 1 0 1 0 .55 1.922l-.55-1.922zm2.532 1.355a1 1 0 1 0-.55-1.922l.55 1.922zm-1.982.567 1.982-.567-.55-1.922-1.982.567.55 1.922zm-11.76-4.443-4.333 9.359a2.238 2.238 0 0 0 2.942 2.94l9.367-4.338a.921.921 0 0 0 .465-1.102.918.918 0 0 0-.226-.369l-6.743-6.738a.92.92 0 0 0-.8-.258.92.92 0 0 0-.672.506z"/><path d="M1.801 3.98c.109.022.197.11.219.219a1 1 0 0 0 1.96 0 .283.283 0 0 1 .22-.219 1 1 0 0 0 0-1.96.283.283 0 0 1-.219-.219 1 1 0 0 0-1.96 0 .283.283 0 0 1-.219.219 1 1 0 0 0 0 1.96zm14 0c.109.022.197.11.219.219a1 1 0 0 0 1.96 0 .283.283 0 0 1 .219-.219 1 1 0 0 0 0-1.96.283.283 0 0 1-.219-.219 1 1 0 0 0-1.96 0 .283.283 0 0 1-.219.219 1 1 0 0 0 0 1.96zm0 14c.109.022.197.11.219.219a1 1 0 0 0 1.96 0 .283.283 0 0 1 .219-.219 1 1 0 0 0 0-1.96.283.283 0 0 1-.219-.219 1 1 0 0 0-1.96 0 .283.283 0 0 1-.219.219 1 1 0 0 0 0 1.96z"/></svg>; }
export function ConnectMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M14 8c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm-6 6c0 1.103-.897 2-2 2s-2-.897-2-2 .897-2 2-2 2 .897 2 2zm6-12c-1.858 0-3.411 1.28-3.857 3h-.143c-2.757 0-5 2.243-5 5v.142c-1.721.447-3 2-3 3.858 0 2.206 1.794 4 4 4s4-1.794 4-4c0-1.858-1.279-3.411-3-3.858v-.142c0-1.654 1.346-3 3-3h.143c.446 1.72 1.999 3 3.857 3 2.206 0 4-1.794 4-4s-1.794-4-4-4z"/></svg>; }
export function ConversationMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M13 10a1 1 0 1 0 2 0 1 1 0 0 0-2 0zm-4 0a1 1 0 1 0 2 0 1 1 0 0 0-2 0zm-4 0a1 1 0 1 0 2 0 1 1 0 0 0-2 0zm5-8c-4.411 0-8 3.589-8 8 0 1.504.425 2.908 1.15 4.111l-1.069 2.495a1 1 0 0 0 1.314 1.313l2.494-1.069a7.939 7.939 0 0 0 4.111 1.15c4.411 0 8-3.589 8-8s-3.589-8-8-8z"/></svg>; }
export function CreditCardCancelMajor() { return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M15.5 9.9a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9Zm-1.29 2.415a.562.562 0 1 0-.795.796l1.29 1.29-1.29 1.29a.562.562 0 1 0 .795.795l1.29-1.29 1.29 1.29a.563.563 0 0 0 .795-.796l-1.29-1.29 1.29-1.29a.562.562 0 1 0-.795-.795l-1.29 1.29-1.29-1.29Z" fillRule="evenodd"/><path d="M1.5 0a1.5 1.5 0 0 0-1.5 1.5v1.5h20v-1.5a1.5 1.5 0 0 0-1.5-1.5h-17Z"/><path d="M0 12.5v-7.5h20v3h-6c-2.757 0-5 2.236-5 4.987v1.013h-7.5a1.5 1.5 0 0 1-1.5-1.5Zm2-3.5h5v-2h-5v2Z" fillRule="evenodd"/></svg>; }
export function CreditCardMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M0 4.5a1.5 1.5 0 0 1 1.5-1.5h17a1.5 1.5 0 0 1 1.5 1.5v1.5h-20v-1.5z"/><path d="M0 8v7.5a1.5 1.5 0 0 0 1.5 1.5h17a1.5 1.5 0 0 0 1.5-1.5v-7.5h-20zm7 4h-5v-2h5v2z"/></svg>; }
export function CreditCardPercentMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 1a1.5 1.5 0 0 0-1.5 1.5v1.5h20v-1.5a1.5 1.5 0 0 0-1.5-1.5h-17z"/><path d="M0 13.5v-7.5h20v3h-6c-2.757 0-5 2.236-5 4.987v1.013h-7.5a1.5 1.5 0 0 1-1.5-1.5zm2-3.5h5v-2h-5v2z"/><circle cx="13.5" cy="12.5" r="1.5"/><circle cx="18.5" cy="17.5" r="1.5"/><path d="M19.707 11.293a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414-1.414l6-6a1 1 0 0 1 1.414 0z"/></svg>; }
export function CreditCardSecureMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 1a1.5 1.5 0 0 0-1.5 1.5v1.5h20v-1.5a1.5 1.5 0 0 0-1.5-1.5h-17z"/><path fillRule="evenodd" d="M0 13.5v-7.5h20v3h-5c-2.757 0-5 2.236-5 4.987v1.013h-8.5a1.5 1.5 0 0 1-1.5-1.5zm2-3.5h5v-2h-5v2z"/><path fillRule="evenodd" d="M13 14v1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1v-1a3 3 0 1 0-6 0zm3-1a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1z"/></svg>; }
export function CurrencyConvertMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.977 7c.026.001.649.039 1.316.707a.999.999 0 1 0 1.414-1.414 4.491 4.491 0 0 0-1.707-1.093v-.2a1 1 0 1 0-2 0v.185a2.995 2.995 0 0 0-2 2.815c0 2.281 1.727 2.713 2.758 2.971 1.115.278 1.242.383 1.242 1.029 0 .552-.448 1-.976 1-.026-.001-.65-.04-1.317-.707a.999.999 0 1 0-1.414 1.414 4.506 4.506 0 0 0 1.707 1.093v.2a1 1 0 1 0 2 0v-.185a2.993 2.993 0 0 0 2-2.815c0-2.281-1.726-2.713-2.757-2.971-1.115-.278-1.243-.383-1.243-1.029 0-.551.449-1 .977-1zm12.023 6h-3.585l.292-.293a.999.999 0 1 0-1.414-1.414l-2 1.999a.988.988 0 0 0-.215.326.992.992 0 0 0 0 .764c.05.123.124.234.216.326l1.999 1.999a1 1 0 0 0 1.414-1.414l-.292-.293h3.585a1 1 0 1 0 0-2zm-7-7a1 1 0 0 1 1-1h3.586l-.293-.293a1 1 0 0 1 1.414-1.414l1.999 1.999a.985.985 0 0 1 .217.326.992.992 0 0 1 0 .764.985.985 0 0 1-.217.326l-2 1.999a.997.997 0 0 1-1.413 0 1 1 0 0 1 0-1.414l.293-.293h-3.586a1 1 0 0 1-1-1z"/></svg>; }
export function CustomerMinusMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M14 11h4a1 1 0 0 0 0-2h-4a1 1 0 0 0 0 2zm-1.637-5.78a4.22 4.22 0 1 1-8.439-.001 4.22 4.22 0 0 1 8.439 0zm-11.693 9.249c1.384-1.09 4.141-2.853 7.474-2.853 3.332 0 6.089 1.764 7.474 2.853.618.486.81 1.308.567 2.056l-.333 1.02a2.11 2.11 0 0 1-2.006 1.455h-11.405a2.11 2.11 0 0 1-2.005-1.455l-.333-1.02c-.245-.748-.052-1.57.566-2.056zm-2651.67 430.531h100v100h-100v-100z"/></svg>; }
export function CustomerPlusMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15 11a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1a1 1 0 1 0-2 0v1h-1a1 1 0 1 0 0 2h1v1zm-2.637-5.78a4.22 4.22 0 1 1-8.439 0 4.22 4.22 0 0 1 8.439 0zm-11.693 9.249c1.385-1.09 4.141-2.853 7.474-2.853 3.332 0 6.089 1.764 7.474 2.853.618.486.81 1.308.567 2.056l-.333 1.02a2.11 2.11 0 0 1-2.007 1.455h-11.404a2.11 2.11 0 0 1-2.005-1.455l-.333-1.02c-.245-.748-.052-1.57.567-2.056z"/></svg>; }
export function CustomersMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M14.363 5.22a4.22 4.22 0 1 1-8.439 0 4.22 4.22 0 0 1 8.439 0zm-11.693 9.249c1.385-1.09 4.141-2.853 7.474-2.853 3.332 0 6.089 1.764 7.474 2.853.618.486.81 1.308.567 2.056l-.333 1.02a2.11 2.11 0 0 1-2.006 1.455h-11.405a2.11 2.11 0 0 1-2.005-1.455l-.333-1.02c-.245-.748-.052-1.57.567-2.056z"/></svg>; }
export function CustomersMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-3.5 8a2.5 2.5 0 0 1-1.768-4.268c.59-.577 2.468-1.732 5.268-1.732s4.678 1.155 5.268 1.732a2.5 2.5 0 0 1-1.768 4.268h-7Z"/></svg>; }
export function DataVisualizationMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M0 2a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2h-1v11.5a1.5 1.5 0 0 1-1.5 1.5h-3.22l.69 2.758a1 1 0 1 1-1.94.485l-.81-3.243h-2.44l-.81 3.242a1 1 0 1 1-1.94-.485l.69-2.757h-3.22a1.5 1.5 0 0 1-1.5-1.5v-11.5h-1a1 1 0 0 1-1-1zm15 3h-2v8h2v-8zm-6 2h2v6h-2v-6zm-2 2h-2v4h2v-4z"/></svg>; }
export function DeleteMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M14 4h3a1 1 0 0 1 1 1v1h-16v-1a1 1 0 0 1 1-1h3v-2.5a1.5 1.5 0 0 1 1.5-1.5h5a1.5 1.5 0 0 1 1.5 1.5v2.5zm-6-2v2h4v-2h-4zm-5 6h14v10.5a1.5 1.5 0 0 1-1.5 1.5h-11a1.5 1.5 0 0 1-1.5-1.5v-10.5zm4 3h-2v6h2v-6zm4 0h-2v6h2v-6zm2 0h2v6h-2v-6z"/></svg>; }
export function DeleteMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8 3.994c0-1.101.895-1.994 2-1.994s2 .893 2 1.994h4c.552 0 1 .446 1 .997a1 1 0 0 1-1 .997h-12c-.552 0-1-.447-1-.997s.448-.997 1-.997h4zm-3 10.514v-6.508h2v6.508a.5.5 0 0 0 .5.498h1.5v-7.006h2v7.006h1.5a.5.5 0 0 0 .5-.498v-6.508h2v6.508a2.496 2.496 0 0 1-2.5 2.492h-5c-1.38 0-2.5-1.116-2.5-2.492z"/></svg>; }
export function DesktopMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M2.5 1a1.5 1.5 0 0 0-1.5 1.5v11a1.5 1.5 0 0 0 1.5 1.5h4.5c0 .525-.015.793-.144 1.053-.12.239-.416.61-1.303 1.053a1 1 0 0 0 .469 1.894h7.956a1.004 1.004 0 0 0 .995-.77 1.001 1.001 0 0 0-.544-1.134c-.873-.439-1.166-.806-1.285-1.043-.13-.26-.144-.528-.144-1.053h4.5a1.5 1.5 0 0 0 1.5-1.5v-11a1.5 1.5 0 0 0-1.5-1.5h-15zm8.883 16a2.621 2.621 0 0 1-.027-.053c-.357-.714-.357-1.42-.356-1.895v-.052h-2v.052c0 .475.001 1.181-.356 1.895a2.913 2.913 0 0 1-.027.053h2.766zm5.617-6h-14v2h14v-2z"/></svg>; }
export function DetailedPopUpMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3.1 3h1v-2h-1.5c-.8 0-1.5.7-1.5 1.5v1.5h2v-1zm6 16h-3v-2h3v2zm2 0h3v-2h-3v2zm-8-2v-1h-2v1.5c0 .8.7 1.5 1.5 1.5h1.5v-2h-1zm13 0h1v-1h2v1.5c0 .8-.7 1.5-1.5 1.5h-1.5v-2zm-15-8v-3h2v3h-2zm0 2v3h2v-3h-2z"/><path fillRule="evenodd" d="M9 3v8h8v-8h-8zm-.5-2c-.8 0-1.5.7-1.5 1.5v9c0 .8.7 1.5 1.5 1.5h9c.8 0 1.5-.7 1.5-1.5v-9c0-.8-.7-1.5-1.5-1.5h-9z"/></svg>; }
export function DiamondAlertMajor() { return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M11.768.768a2.5 2.5 0 0 0-3.536 0l-7.464 7.464a2.5 2.5 0 0 0 0 3.536l7.464 7.464a2.5 2.5 0 0 0 3.536 0l7.464-7.464a2.5 2.5 0 0 0 0-3.536l-7.464-7.464zm-2.768 5.232a1 1 0 1 1 2 0v4a1 1 0 1 1-2 0v-4zm2 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/></svg>; }
export function DigitalMediaReceiverMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 0 1 8-8 1 1 0 1 0 0-2c-5.523 0-10 4.477-10 10a1 1 0 1 0 2 0zm8-4a4 4 0 0 0-4 4 1 1 0 1 1-2 0 6 6 0 0 1 6-6 1 1 0 1 1 0 2zm1 4a1 1 0 0 1 1 1v3h6.5a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-15a1.5 1.5 0 0 1-1.5-1.5v-3a1.5 1.5 0 0 1 1.5-1.5h6.5v-3a1 1 0 0 1 1-1zm-4 6h-3v2h3v-2z"/></svg>; }
export function DiscountAutomaticMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m8.707 7.707 6-6a1 1 0 0 0-1.414-1.414l-6 6a1 1 0 0 0 1.414 1.414z"/><path fillRule="evenodd" d="M2 0a1 1 0 0 0 0 2h1v12.17a3.001 3.001 0 1 0 3.83 1.83h5.34a3 3 0 1 0 2.83-2h-10v-2h11.566a1.5 1.5 0 0 0 1.485-1.288l.939-6.57a1 1 0 0 0-1.98-.283l-.877 6.141h-11.133v-8.5a1.5 1.5 0 0 0-1.5-1.5h-1.5zm13 16a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-11 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/><path d="M8.5 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm6.5 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/></svg>; }
export function DiscountCodeMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4 3h-1v1h-2v-1.5a1.5 1.5 0 0 1 1.5-1.5h1.5v2zm5 0h-3v-2h3v2zm5 0h-3v-2h3v2zm-13 6v-3h2v3h-2zm0 2v3h2v-3h-2zm16-5h2v3h-2v-3zm2 5h-2v3h2v-3zm-10 8v-2h-3v2h3zm5-2v2h-3v-2h3zm-5-9.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm3.5 6.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm1.207-7.707a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414-1.414l6-6a1 1 0 0 1 1.414 0zm4.293 12.707a1 1 0 0 0 1-1v-2h-2v1h-1v2h2zm-16 0a1 1 0 0 1-1-1v-2h2v1h1v2h-2zm15-15v-1h-1v-2h1.5a1.5 1.5 0 0 1 1.5 1.5v1.5h-2z"/></svg>; }
export function DiscountsMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M11.566.66a2.189 2.189 0 0 0-3.132 0l-.962.985a2.189 2.189 0 0 1-1.592.66l-1.377-.017a2.189 2.189 0 0 0-2.215 2.215l.016 1.377a2.189 2.189 0 0 1-.66 1.592l-.984.962a2.189 2.189 0 0 0 0 3.132l.985.962c.428.418.667.994.66 1.592l-.017 1.377a2.189 2.189 0 0 0 2.215 2.215l1.377-.016a2.189 2.189 0 0 1 1.592.66l.962.984c.859.88 2.273.88 3.132 0l.962-.985a2.189 2.189 0 0 1 1.592-.66l1.377.017a2.189 2.189 0 0 0 2.215-2.215l-.016-1.377a2.189 2.189 0 0 1 .66-1.592l.984-.962c.88-.859.88-2.273 0-3.132l-.985-.962a2.189 2.189 0 0 1-.66-1.592l.017-1.377a2.189 2.189 0 0 0-2.215-2.215l-1.377.016a2.189 2.189 0 0 1-1.592-.66l-.962-.984zm-4.566 7.84a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm6 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm.778-8.278a1.1 1.1 0 0 1 0 1.556l-6 6a1.1 1.1 0 1 1-1.556-1.556l6-6a1.1 1.1 0 0 1 1.556 0z"/></svg>; }
export function DiscountsMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 3.072a2 2 0 0 0-.99 1.939 2 2 0 0 0-1.826 3.163 2 2 0 0 0 0 3.652 2 2 0 0 0 1.826 3.164 2 2 0 0 0 3.164 1.828 2 2 0 0 0 3.652 0 2 2 0 0 0 3.164-1.827 2 2 0 0 0 1.826-3.164 2 2 0 0 0 0-3.652 2 2 0 0 0-1.826-3.165 2 2 0 0 0-3.163-1.826 2 2 0 0 0-3.654 0 2 2 0 0 0-2.173-.112Zm6.832 4.483a1 1 0 1 0-1.664-1.11l-4 6a1 1 0 0 0 1.664 1.11l4-6Zm-5.832 1.445a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm7 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"/></svg>; }
export function DisputeMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 9a1 1 0 0 0 2 0v-2a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0zm-7-3c0 4.411 3.589 8 8 8a7.939 7.939 0 0 0 4.111-1.15l2.494 1.069a1 1 0 0 0 1.314-1.313l-1.069-2.495a7.939 7.939 0 0 0 1.15-4.111c0-4.411-3.589-8-8-8s-8 3.589-8 8z"/></svg>; }
export function DnsSettingsMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M1 3.5a1.5 1.5 0 0 1 1.5-1.5h15a1.5 1.5 0 0 1 1.5 1.5v2a1.5 1.5 0 0 1-1.5 1.5h-15a1.5 1.5 0 0 1-1.5-1.5v-2zm3.5 1a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3.5 5a1.5 1.5 0 0 1 1.5-1.5h6.073a1.5 1.5 0 0 1 1.342 2.17l-1 2a1.5 1.5 0 0 1-1.342.83h-5.073a1.5 1.5 0 0 1-1.5-1.5v-2zm3.5 1a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3.5 5a1.5 1.5 0 0 1 1.5-1.5h5.27a1.5 1.5 0 0 1 1.471 1.206l.4 2a1.5 1.5 0 0 1-1.47 1.794h-5.671a1.5 1.5 0 0 1-1.5-1.5v-2zm3.5 1a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7.659-3.441-.682-.429a.987.987 0 0 1-.452-.611.946.946 0 0 1 .134-.742.983.983 0 0 1 .639-.425 1.023 1.023 0 0 1 .758.15l.682.427c.369-.31.8-.54 1.267-.676v-.783c0-.258.104-.504.291-.686a1.01 1.01 0 0 1 .704-.284c.264 0 .517.102.704.284a.957.957 0 0 1 .291.686v.783c.472.138.903.37 1.267.676l.682-.429a1.02 1.02 0 0 1 .735-.107c.25.058.467.208.606.419.14.21.19.465.141.71a.97.97 0 0 1-.403.608l-.682.429a3.296 3.296 0 0 1 0 1.882l.682.43a.987.987 0 0 1 .452.611.946.946 0 0 1-.134.742.982.982 0 0 1-.639.425 1.02 1.02 0 0 1-.758-.15l-.682-.428c-.369.31-.8.54-1.267.676v.783a.957.957 0 0 1-.291.686 1.01 1.01 0 0 1-.704.284 1.01 1.01 0 0 1-.704-.284.957.957 0 0 1-.291-.686v-.783a3.503 3.503 0 0 1-1.267-.676l-.682.429a1.02 1.02 0 0 1-.75.132.999.999 0 0 1-.627-.421.949.949 0 0 1-.135-.73.97.97 0 0 1 .434-.61l.68-.43a3.296 3.296 0 0 1 0-1.882zm3.341-.507c-.82 0-1.487.65-1.487 1.449s.667 1.448 1.487 1.448c.82 0 1.487-.65 1.487-1.448 0-.8-.667-1.45-1.487-1.45z"/></svg>; }
export function DomainNewMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M2.5 1a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-15a1.5 1.5 0 0 0-1.5-1.5h-15zm6.5 8a1 1 0 0 1 2 0v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2h-2a1 1 0 1 1 0-2h2v-2zm-2-4v-2h10v2h-10zm-4-2v2h2v-2h-2z"/></svg>; }
export function DomainRedirectMinor() { return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 3.778a6.222 6.222 0 1 0 3.726 11.205l-4.869-4.869v2.208a.889.889 0 0 1-1.778 0v-4.354a.89.89 0 0 1 .89-.889h4.353a.889.889 0 0 1 0 1.778h-2.208l4.87 4.87a6.193 6.193 0 0 0 1.237-3.727 6.222 6.222 0 0 0-6.221-6.222Zm-8 6.222a8 8 0 1 1 16 0 8 8 0 0 1-16 0Z"/></svg>; }
export function DomainsMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M13.903 8c.06.629.097 1.292.097 2a1 1 0 0 1-2 0c0-.713-.039-1.378-.105-2h-3.803c-.139 1.33-.14 2.67-.004 4h1.912a1 1 0 0 1 0 2h-1.584c.469 2.08 1.202 3.41 1.585 4 0 0 .789.013.926.002a1.008 1.008 0 0 1 1.07.925 1 1 0 0 1-.924 1.07c-.094.007-1.073.003-1.073.003-5.514 0-10-4.486-10-10 0-5.515 4.486-10 10-10s10 4.485 10 10c0 .379-.036.749-.079 1.116a1.001 1.001 0 0 1-1.987-.233c.035-.291.066-.583.066-.883 0-.692-.098-1.36-.263-2h-3.834zm-3.915-5.964c-.386.606-1.1 1.931-1.564 3.964h3.137c-.466-2.033-1.185-3.358-1.573-3.964zm-7.725 9.964h3.819a21.604 21.604 0 0 1-.095-1.996c0-.67.033-1.338.096-2.004h-3.82a7.946 7.946 0 0 0-.263 2 7.96 7.96 0 0 0 .263 2zm.819 2a8.031 8.031 0 0 0 4.437 3.601 16.293 16.293 0 0 1-1.148-3.601h-3.289zm3.291-8a16.235 16.235 0 0 1 1.15-3.603 8.03 8.03 0 0 0-4.441 3.603h3.291zm7.239 0h3.305a8.029 8.029 0 0 0-4.465-3.612 16.128 16.128 0 0 1 1.16 3.612z"/><path d="m15.223 18.945-2.55-5.61a.5.5 0 0 1 .662-.662l5.61 2.55a.5.5 0 0 1 .017.903l-1.742.87a.5.5 0 0 0-.223.224l-.871 1.742a.5.5 0 0 1-.903-.017z"/></svg>; }
export function DraftOrdersMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m19.101 3.291-2.392-2.393.398-.398a1.692 1.692 0 1 1 2.393 2.392z"/><path d="m15.61 1.994 2.393 2.393-6.503 6.503-3.168.777.776-3.17z"/><path d="M3 3v11h3.5c.775 0 1.388.662 1.926 1.244l.026.028.085.091c.365.392.885.637 1.463.637.578 0 1.098-.245 1.463-.637a31 31 0 0 0 .102-.11l.009-.009c.538-.581 1.151-1.244 1.926-1.244h3.5v-3a1 1 0 1 1 2 0v6.5a1.5 1.5 0 0 1-1.5 1.5h-15a1.5 1.5 0 0 1-1.5-1.5v-15a1.5 1.5 0 0 1 1.5-1.5h6.5a1 1 0 1 1 0 2z"/></svg>; }
export function DragDropMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 2h1v-2h-1.5a1.5 1.5 0 0 0-1.5 1.5v1.5h2v-1zm3 0h3v-2h-3v2zm8 0h-3v-2h3v2zm-13 3v3h2v-3h-2zm0 5v3h2v-3h-2zm18-5h-2v3h2v-3zm-13 13h3v-2h-3v2zm-5-1a1 1 0 0 0 1 1h2v-2h-1v-1h-2v2zm16-14v-1h-1v-2h1.5a1.5 1.5 0 0 1 1.5 1.5v1.5h-2z"/><path d="M10.814 14h-5.314a1.5 1.5 0 0 1-1.5-1.5v-7a1.5 1.5 0 0 1 1.5-1.5h7a1.5 1.5 0 0 1 1.5 1.5v5.314l5.512 2.506a.832.832 0 0 1 .028 1.501l-2.898 1.45a.832.832 0 0 0-.372.371l-1.449 2.898a.832.832 0 0 1-1.501-.028l-2.506-5.512zm-.909-2-.828-1.821c-.318-.7.402-1.42 1.102-1.102l1.821.828v-3.905h-6v6h3.905z"/></svg>; }
export function DragHandleMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 2a2 2 0 1 0 .001 4.001 2 2 0 0 0-.001-4.001zm0 6a2 2 0 1 0 .001 4.001 2 2 0 0 0-.001-4.001zm0 6a2 2 0 1 0 .001 4.001 2 2 0 0 0-.001-4.001zm6-8a2 2 0 1 0-.001-4.001 2 2 0 0 0 .001 4.001zm0 2a2 2 0 1 0 .001 4.001 2 2 0 0 0-.001-4.001zm0 6a2 2 0 1 0 .001 4.001 2 2 0 0 0-.001-4.001z"/></svg>; }
export function DropdownMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M13.098 8h-6.196c-.751 0-1.172.754-.708 1.268l3.098 3.432c.36.399 1.055.399 1.416 0l3.098-3.433c.464-.513.043-1.267-.708-1.267Z"/></svg>; }
export function DuplicateMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 2a1.5 1.5 0 0 0-1.5 1.5v9.5a1 1 0 0 0 1 1h9.5a1.5 1.5 0 0 0 1.5-1.5v-9a1.5 1.5 0 0 0-1.5-1.5h-9zm-4 4h.5v10h10v.5a1.5 1.5 0 0 1-1.5 1.5h-9a1.5 1.5 0 0 1-1.5-1.5v-9a1.5 1.5 0 0 1 1.5-1.5z"/></svg>; }
export function EditMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m14.846 1.403 3.752 3.753.625-.626a2.653 2.653 0 0 0-3.752-3.752l-.625.625zm2.029 5.472-3.752-3.753-11.905 11.906-1.218 4.97 4.97-1.217 11.905-11.906z"/></svg>; }
export function EditMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m13.877 3.123 3.001 3.002.5-.5a2.123 2.123 0 1 0-3.002-3.002l-.5.5zm1.623 4.377-3.002-3.002-9.524 9.525-.974 3.976 3.976-.974 9.524-9.525z"/></svg>; }
export function EmailMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M0 5.324v10.176a1.5 1.5 0 0 0 1.5 1.5h17a1.5 1.5 0 0 0 1.5-1.5v-10.176l-9.496 5.54a1 1 0 0 1-1.008 0l-9.496-5.54z"/><path d="M19.443 3.334a1.494 1.494 0 0 0-.943-.334h-17a1.49 1.49 0 0 0-.943.334l9.443 5.508 9.443-5.508z"/></svg>; }
export function EmailNewsletterMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 4h6v2h-6v-2zm4 4h-4v2h4v-2z"/><path fillRule="evenodd" d="M3 10v-8.5a1.5 1.5 0 0 1 1.5-1.5h11a1.5 1.5 0 0 1 1.5 1.5v8.5h2v7.5a1.5 1.5 0 0 1-1.5 1.5h-15a1.5 1.5 0 0 1-1.5-1.5v-7.5h2zm2 2v-10h10v10l-5 2.771-5-2.771z"/></svg>; }
export function EmbedMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11.98 5.196a1 1 0 0 0-1.96-.392l-2 10a1 1 0 0 0 1.96.392l2-10zm-5.18 1.204a1 1 0 0 1-.2 1.4l-2.933 2.2 2.933 2.2a1 1 0 0 1-1.2 1.6l-4-3a1 1 0 0 1 0-1.6l4-3a1 1 0 0 1 1.4.2zm6.4 7.2a1 1 0 0 1 .2-1.4l2.933-2.2-2.933-2.2a1 1 0 1 1 1.2-1.6l4 3a1 1 0 0 1 0 1.6l-4 3a1 1 0 0 1-1.4-.2z"/></svg>; }
export function EnableSelectionMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.5 3a1.5 1.5 0 0 0-1.5 1.5v11a1.5 1.5 0 0 0 1.5 1.5h11a1.5 1.5 0 0 0 1.5-1.5v-11a1.5 1.5 0 0 0-1.5-1.5h-11zm9.207 5.707a1 1 0 0 0-1.414-1.414l-3.293 3.293-1.293-1.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4z"/></svg>; }
export function EnvelopeMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M0 16.5v-13c0-.8.7-1.5 1.5-1.5h17c.8 0 1.5.7 1.5 1.5v13c0 .8-.7 1.5-1.5 1.5h-17c-.8 0-1.5-.7-1.5-1.5zm18-1.2-3-1.8v-6.9l3-1.867v10.567zm-2.667.7-1.833-1.1c-.3-.2-.5-.5-.5-.9v-8c0-.4.2-.7.5-.9l1.833-1.1h-13.333v12h13.333zm-11.333-8h4v2h-4v-2zm6 4h-6v2h6v-2z"/></svg>; }
export function ExchangeMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.986 12.166a3 3 0 1 1-1.973 0 1.003 1.003 0 0 1-.013-.166v-2.001a3.999 3.999 0 0 0-3.999-3.999h-.587l1.293 1.293a1 1 0 1 1-1.414 1.414l-3-3a1 1 0 0 1 0-1.414l3-3a1 1 0 0 1 1.414 1.414l-1.293 1.293h.587a5.999 5.999 0 0 1 5.999 5.999v2.001c0 .057-.005.112-.014.166zm-14-4.332c.01.054.014.11.014.166v2.001a4 4 0 0 0 3.999 3.999h.587l-1.293-1.293a1 1 0 0 1 1.414-1.414l3 3a1 1 0 0 1 0 1.414l-3 3a1 1 0 1 1-1.414-1.414l1.293-1.293h-.587a6 6 0 0 1-5.999-5.999v-2.001c0-.057.005-.112.014-.166a3 3 0 1 1 1.972 0zm.014-2.834a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm14 10a1 1 0 1 0-2.001.001 1 1 0 0 0 2.001-.001z"/></svg>; }
export function ExistingInventoryMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M20 9c0 .55-.45 1-1 1h-2v2c0 .55-.45 1-1 1s-1-.45-1-1v-2h-2c-.55 0-1-.45-1-1s.45-1 1-1h2v-2c0-.55.45-1 1-1s1 .45 1 1v2h2c.55 0 1 .45 1 1zm-16-1h3v-5h-3v5zm-2 9h5v-7h-5v7zm14-2c-.55 0-1 .45-1 1v1h-6v-11h3c.55 0 1-.45 1-1s-.45-1-1-1h-3v-2c0-.55-.45-1-1-1h-5c-.55 0-1 .45-1 1v6h-1c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h15c.55 0 1-.45 1-1v-2c0-.55-.45-1-1-1z"/></svg>; }
export function ExitMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.703 13.707a.997.997 0 0 0 0-1.414l-1.294-1.293h6.589a1 1 0 1 0 0-2h-6.589l1.294-1.293a1 1 0 1 0-1.415-1.414l-3.001 3a.994.994 0 0 0-.198.298c-.013.027-.021.054-.03.082a.944.944 0 0 0-.054.269l-.001.027a.937.937 0 0 0 .062.398l.003.012v.004c.048.112.117.208.197.294l.01.015.01.015 3.002 3a1 1 0 0 0 1.415 0z"/><path d="M2 16.5a1.5 1.5 0 0 0 1.5 1.5h13a1.5 1.5 0 0 0 1.5-1.5v-13a1.5 1.5 0 0 0-1.5-1.5h-13a1.5 1.5 0 0 0-1.5 1.5v1.5a1 1 0 1 0 2 0v-1h12v12h-12v-1a1 1 0 1 0-2 0v1.5z"/></svg>; }
export function ExportMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a.999.999 0 0 0-1.414 0l-3 3a.999.999 0 1 0 1.414 1.414l1.293-1.293v7.586a1 1 0 1 0 2 0v-7.586l1.293 1.293a.999.999 0 1 0 1.414-1.414l-3-3zm-7.707 13.707a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2h-14z"/></svg>; }
export function ExternalMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-1.586l-5.293 5.293a1 1 0 0 1-1.414-1.414l5.293-5.293h-1.586a1 1 0 0 1-1-1zm-8 2.5a1.5 1.5 0 0 1 1.5-1.5h3.5a1 1 0 0 1 0 2h-3v8h8v-3a1 1 0 1 1 2 0v3.5a1.5 1.5 0 0 1-1.5 1.5h-9a1.5 1.5 0 0 1-1.5-1.5v-9z"/></svg>; }
export function ExternalSmallMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M14 13v1a1 1 0 0 1-1 1h-7c-.575 0-1-.484-1-1v-7a1 1 0 0 1 1-1h1c1.037 0 1.04 1.5 0 1.5-.178.005-.353 0-.5 0v6h6v-.5c0-1 1.5-1 1.5 0zm-3.75-7.25a.75.75 0 0 1 .75-.75h4v4a.75.75 0 0 1-1.5 0v-1.44l-3.22 3.22a.75.75 0 1 1-1.06-1.06l3.22-3.22h-1.44a.75.75 0 0 1-.75-.75z"/></svg>; }
export function FaviconMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.71.29c-.19-.18-.44-.29-.71-.29h-14c-.27 0-.52.11-.71.29-.18.19-.29.44-.29.71v10c0 .27.11.52.29.71.19.18.44.29.71.29h14c.27 0 .52-.11.71-.29.18-.19.29-.44.29-.71v-10c0-.27-.11-.52-.29-.71zm-1.71 6.71-2.41-1.832a.992.992 0 0 0-1.15.03l-3.44 2.802-2.52-1.832c-.3-.2-.68-.22-1-.06l-1.48.892v-5h12v5zm-7 9c.27 0 .52-.11.71-.29.18-.19.29-.44.29-.71 0-.27-.11-.52-.29-.71-.19-.18-.44-.29-.71-.29-1.65 0-3 1.35-3 3s1.35 3 3 3c.27 0 .52-.11.71-.29.18-.19.29-.44.29-.71 0-.27-.11-.52-.29-.71-.19-.18-.44-.29-.71-.29a.982.982 0 0 1-.68-.31.976.976 0 0 1-.28-.69c0-.26.1-.51.28-.69.18-.19.42-.3.68-.31zm6-2c-1.65 0-3 1.35-3 3s1.35 3 3 3 3-1.35 3-3-1.35-3-3-3zm.68 3.69c-.18.19-.42.3-.68.31a.982.982 0 0 1-.68-.31.976.976 0 0 1-.28-.69c0-.26.1-.51.28-.69.18-.19.42-.3.68-.31.26.01.5.12.68.31.18.18.28.43.28.69 0 .26-.1.51-.28.69zm-12.68-3.69c-.27 0-.52.11-.71.29-.18.19-.29.44-.29.71v4c0 .27.11.52.29.71.19.18.44.29.71.29.27 0 .52-.11.71-.29.18-.19.29-.44.29-.71v-4c0-.27-.11-.52-.29-.71-.19-.18-.44-.29-.71-.29z"/></svg>; }
export function FavoriteMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m5 12-3.624-3c-.684-.547-.384-1.647.484-1.771l5.14-.229 2.195-5.503a.9.9 0 0 1 1.61 0l2.195 5.503 5.14.229c.868.124 1.168 1.224.484 1.771l-3.624 3 .989 5.857a1 1 0 0 1-1.437 1.036l-4.552-2.893-4.552 2.892a1 1 0 0 1-1.437-1.036l.989-5.856z"/></svg>; }
export function FeaturedCollectionMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 .439a1.48 1.48 0 0 0-2.103 0l-1.04 1.18-3.48 4a1.497 1.497 0 0 0-.377 1v9.88c0 .398.157.779.436 1.06.278.282.657.44 1.051.44h2.479v-9.43a3.568 3.568 0 0 1 .872-2.33l3.638-4.12-1.476-1.68zm10.618 7.13-4.579-5.13a1.51 1.51 0 0 0-2.129 0l-1.004 1.18-3.524 4a1.486 1.486 0 0 0-.382 1v9.88a1.5 1.5 0 0 0 1.456 1.5h9.038c.4 0 .782-.158 1.065-.44.282-.281.44-.663.44-1.06v-9.93a1.487 1.487 0 0 0-.38-1zm-7.216 1.431c-1 0-1.9.9-1.9 2 0 2.2 1.3 3.9 3.5 5 2.2-1.1 3.5-2.8 3.5-4.9v-.2c0-1-.9-1.9-1.9-1.9-1 0-1.6 1.167-1.6 1.167s-.6-1.167-1.6-1.167z"/></svg>; }
export function FeaturedContentMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="m16.56 4.56-4.12-4.12a1.5 1.5 0 0 0-1.06-.44h-6.88a1.5 1.5 0 0 0-1.5 1.5v17a1.5 1.5 0 0 0 1.5 1.5h10.5a2 2 0 0 0 2-2v-12.38a1.5 1.5 0 0 0-.44-1.06zm-8.16 2.44c-1 0-1.9.9-1.9 2 0 2.2 1.3 3.9 3.5 5 2.2-1.1 3.5-2.8 3.5-4.9v-.2c0-1-.9-1.9-1.9-1.9-1 0-1.6 1.167-1.6 1.167s-.6-1.167-1.6-1.167z"/></svg>; }
export function FilterMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M0 5a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2h-18a1 1 0 0 1-1-1zm3 5a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2h-12a1 1 0 0 1-1-1zm5 4a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4z"/></svg>; }
export function FinancesMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5 3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1h-14Zm7 6.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"/><path fillRule="evenodd" d="M16 15v1a1 1 0 0 1-1 1h-14a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h1v7a1 1 0 0 0 1 1h13Z"/></svg>; }
export function FinancesMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9.445 3.168a1 1 0 0 1 1.11 0l6 4a1 1 0 0 1 .152 1.54l-.707.706v4.586a1.55 1.55 0 0 1-.55 3h-10.9a1.55 1.55 0 0 1-.55-3v-4.586l-.707-.707a1 1 0 0 1 .152-1.54l6-3.999Zm2.555 5.832a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1Zm-3 1a1 1 0 0 0-2 0v3a1 1 0 1 0 2 0v-3Z"/></svg>; }
export function FirstOrderMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m9.69 5.772-.243.123a1 1 0 1 1-.895-1.79l2-1a.998.998 0 0 1 1.434 1.06l-1 6a1 1 0 1 1-1.973-.33l.677-4.063zm-5.073-.848a.997.997 0 0 1-.324-.217l-1-1a1 1 0 0 1 1.414-1.414l1 1a.999.999 0 0 1-1.09 1.631zm10.766 0a.997.997 0 0 1-.94-.092 1 1 0 0 1-.15-1.54l1-1a1 1 0 1 1 1.414 1.415l-1 1a.996.996 0 0 1-.324.217zm-.09 3.783a1 1 0 0 1 .707-1.707h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-.707-.293zm-11.293.293h-2a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2zm-3 8.5v-5.5h2v2h3.5c.775 0 1.388.662 1.926 1.244l.11.12a2 2 0 0 0 2.927-.001l.11-.119h.001c.538-.582 1.151-1.244 1.926-1.244h3.5v-2h2v5.5a1.5 1.5 0 0 1-1.5 1.5h-15a1.5 1.5 0 0 1-1.5-1.5z"/></svg>; }
export function FirstVisitMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M19.962 9.726c-.023-.078-2.296-7.726-9.962-7.726s-9.939 7.648-9.962 7.726a1 1 0 0 0 0 .548c.023.078 2.296 7.726 9.962 7.726s9.939-7.648 9.962-7.726a.999.999 0 0 0 0-.548zm-8.876-3.723a1 1 0 0 1 .901 1.161l-1 6a1 1 0 1 1-1.973-.328l.677-4.064-.243.123a1 1 0 0 1-.895-1.79l2-1a1 1 0 0 1 .533-.102z"/></svg>; }
export function FlagMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3.249 2.001h13.868a.5.5 0 0 1 .428.758l-2.545 4.241 3.35 4.188a.5.5 0 0 1-.39.812l-12.49.001 1.507 6.782a1 1 0 1 1-1.953.434l-4-18a1 1 0 0 1 1.953-.434l.272 1.218z"/></svg>; }
export function FlipCameraMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.059 1a1.5 1.5 0 0 0-1.32.786l-.54 1c-.039.07-.07.142-.096.214h-3.603a1.5 1.5 0 0 0-1.5 1.5v11a1.5 1.5 0 0 0 1.5 1.5h17a1.5 1.5 0 0 0 1.5-1.5v-11a1.5 1.5 0 0 0-1.5-1.5h-3.621a1.546 1.546 0 0 0-.1-.223l-.55-1a1.5 1.5 0 0 0-1.315-.777h-5.854zm4.692 14.08c-.63.278-1.311.42-2 .42a5.016 5.016 0 0 1-4.975-4.5h-1.275a.5.5 0 0 1-.405-.795l2.27-3.145a.5.5 0 0 1 .81 0l2.275 3.145a.5.5 0 0 1-.4.8h-1.25a3.014 3.014 0 0 0 2.95 2.495c.32 0 .637-.05.94-.15l1.33 1.6c-.09.05-.18.09-.27.13zm2.884-2.14 2.265-3.14a.5.5 0 0 0-.405-.8h-1.275a5.016 5.016 0 0 0-4.975-4.5 4.941 4.941 0 0 0-2.27.55l1.33 1.6c.303-.1.62-.15.94-.15a3.014 3.014 0 0 1 2.95 2.505h-1.25a.5.5 0 0 0-.4.795l2.28 3.14a.5.5 0 0 0 .81 0z"/></svg>; }
export function FolderDownMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M17.5 19a1.5 1.5 0 0 0 1.5-1.5v-13a1.5 1.5 0 0 0-1.5-1.5h-6.879c-.397 0-.779-.158-1.06-.44l-1.121-1.121a1.503 1.503 0 0 0-1.061-.439h-4.879a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h15zm-8.5-7.414-1.293-1.293a1 1 0 0 0-1.414 1.414l3 3a.997.997 0 0 0 1.414 0l3-3a1 1 0 0 0-1.414-1.414l-1.293 1.293v-3.586a1 1 0 0 0-2 0v3.586z"/></svg>; }
export function FolderMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M19 17.5v-13a1.5 1.5 0 0 0-1.5-1.5h-6.879a1.5 1.5 0 0 1-1.06-.44l-1.122-1.12a1.5 1.5 0 0 0-1.059-.44h-4.88a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5z"/></svg>; }
export function FolderMinusMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 3a1.5 1.5 0 0 1 1.5 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-15a1.5 1.5 0 0 1-1.5-1.5v-15a1.5 1.5 0 0 1 1.5-1.5h4.879a1.5 1.5 0 0 1 1.06.44l1.122 1.12a1.5 1.5 0 0 0 1.06.44h6.879zm-10.5 7a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2h-6z"/></svg>; }
export function FolderPlusMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M17.5 3a1.5 1.5 0 0 1 1.5 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-15a1.5 1.5 0 0 1-1.5-1.5v-15a1.5 1.5 0 0 1 1.5-1.5h4.879a1.5 1.5 0 0 1 1.06.44l1.122 1.12a1.5 1.5 0 0 0 1.06.44h6.879zm-8.5 5a1 1 0 0 1 2 0v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 1 1-2 0v-2h-2a1 1 0 1 1 0-2h2v-2z"/></svg>; }
export function FolderUpMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M19 17.5v-13a1.5 1.5 0 0 0-1.5-1.5h-6.879c-.397 0-.779-.158-1.06-.44l-1.121-1.121a1.503 1.503 0 0 0-1.061-.439h-4.879a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5zm-8-7.085 1.293 1.293a1 1 0 0 0 1.414-1.414l-3-3a.997.997 0 0 0-1.414 0l-3 3a1 1 0 0 0 1.414 1.414l1.293-1.293v3.586a1 1 0 1 0 2 0v-3.586z"/></svg>; }
export function FollowUpEmailMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m19.707 15.293-3-3a1.001 1.001 0 0 0-1.414 1.414l1.293 1.293h-3.586a1 1 0 0 0 0 2h3.586l-1.293 1.293a1 1 0 1 0 1.414 1.414l3-3a1 1 0 0 0 0-1.414z"/><path d="M18.5 1c.357 0 .686.125.943.334l-9.443 5.508-9.443-5.508a1.49 1.49 0 0 1 .943-.334h17zm-18.5 12.5v-10.176l9.496 5.54a1 1 0 0 0 1.008 0l9.496-5.54v6.676h-4a6.002 6.002 0 0 0-5.917 5h-8.583a1.5 1.5 0 0 1-1.5-1.5z"/></svg>; }
export function FoodMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M17 4h-.22l-.81-3.242a.999.999 0 0 0-.97-.758h-10a.998.998 0 0 0-.97.758l-.81 3.242h-.22a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h.095l.91 9.1a1 1 0 0 0 .995.9h10c.514 0 .945-.39.996-.9l.91-9.1h.094a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1zm-11.218-2h8.437l.5 2h-9.437l.5-2zm-1.782 6h12v-2h-12v2zm6 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/></svg>; }
export function FooterMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M1 2a1 1 0 0 1 1-1h2v2h-1v1h-2v-2zm17-1a1 1 0 0 1 1 1v2h-2v-1h-1v-2h2zm1 16.5v-6.5h-18v6.5a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5zm0-11.5v3h-2v-3h2zm-16 3v-3h-2v3h2zm11-8v2h-3v-2h3zm-5 2v-2h-3v2h3z"/></svg>; }
export function FormsMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M18 14v-8h-2v-2h2.5c.8 0 1.5.7 1.5 1.5v9c0 .8-.7 1.5-1.5 1.5h-2.5v-2h2z"/><path d="M17.7 18.3c-.2-.2-.4-.3-.7-.3h-2c-.3 0-.5-.1-.7-.3-.2-.2-.3-.4-.3-.7v-14c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3h2c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7 0-.3-.1-.5-.3-.7-.2-.2-.4-.3-.7-.3h-2c-.7 0-1.5.3-2 .8-.5-.5-1.2-.8-2-.8h-2c-.3 0-.5.1-.7.3-.2.2-.3.4-.3.7 0 .3.1.5.3.7.2.2.4.3.7.3h2c.3 0 .5.1.7.3.2.2.3.4.3.7v14c0 .3-.1.5-.3.7-.2.2-.4.3-.7.3h-2c-.3 0-.5.1-.7.3-.2.2-.3.4-.3.7 0 .3.1.5.3.7.2.2.4.3.7.3h2c.7 0 1.5-.3 2-.8.5.5 1.2.8 2 .8h2c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7 0-.3-.1-.5-.3-.7zm-15.7-4.3v-8h8v-2h-8.5c-.8 0-1.5.7-1.5 1.5v9c0 .8.7 1.5 1.5 1.5h8.5v-2h-8zm4-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/></svg>; }
export function FraudProtectMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9.131.233c-2.37 1.383-5.37 2.33-7.634 2.646-.822.115-1.495.79-1.494 1.62l.002.497c-.03 6.043.477 11.332 9.462 14.903a1.45 1.45 0 0 0 1.062 0c8.991-3.571 9.503-8.859 9.471-14.903v-.501c0-.828-.673-1.51-1.491-1.638-2.149-.337-5.282-1.274-7.65-2.628a1.733 1.733 0 0 0-1.729.004zm4.577 8.478a1 1 0 0 0-1.414-1.415l-3.293 3.294-1.293-1.293a1 1 0 1 0-1.414 1.414l2 2.001a1 1 0 0 0 1.414 0l4-4.001z"/></svg>; }
export function FraudProtectMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.822 12.683c-1.349 2.513-3.885 4.485-6.818 5.317-2.935-.832-5.471-2.804-6.82-5.317-1.42-2.645-1.184-5.683-.184-8.683 1.361-.089 3.983-.573 7.004-2 3.02 1.427 5.635 1.911 6.996 2 1 3 1 6-.178 8.683zm-2.615-5.89a1 1 0 0 1 0 1.414l-4.5 4.5a1 1 0 0 1-1.414 0l-2.5-2.5a1 1 0 1 1 1.414-1.414l1.793 1.793 3.793-3.793a1 1 0 0 1 1.414 0z"/></svg>; }
export function FraudProtectPendingMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M1.493 2.879c2.264-.317 5.264-1.263 7.635-2.646a1.733 1.733 0 0 1 1.728-.004c2.369 1.354 5.502 2.29 7.65 2.628.818.128 1.491.81 1.491 1.638v.501c.031 6.043-.48 11.332-9.472 14.903a1.45 1.45 0 0 1-1.062 0c-8.985-3.571-9.492-8.86-9.462-14.903l-.001-.497c-.002-.83.672-1.505 1.493-1.62Zm9.214 6.414a1 1 0 1 0-1.414 1.414 1 1 0 0 0 1.414-1.414Zm-4 0a1 1 0 1 0-1.414 1.414 1 1 0 0 0 1.414-1.414Zm8 0a1 1 0 1 0-1.414 1.414 1 1 0 0 0 1.414-1.414Z"/></svg>; }
export function FraudProtectPendingMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M16.822 12.683c-1.349 2.513-3.885 4.485-6.818 5.317-2.935-.832-5.471-2.804-6.82-5.317-1.344-2.502-1.542-5.536-.586-8.808 1.362-.089 4.385-.448 7.406-1.875 3.02 1.427 6.043 1.786 7.404 1.875.958 3.272.758 6.306-.586 8.808zm-3.822-2.683a1 1 0 1 0 2 0 1 1 0 0 0-2 0zm-4 0a1 1 0 1 0 2 0 1 1 0 0 0-2 0zm-4 0a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"/></svg>; }
export function FraudProtectUnprotectedMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M1.493 2.879c2.264-.317 5.264-1.263 7.635-2.646a1.733 1.733 0 0 1 1.728-.004c2.369 1.354 5.502 2.29 7.65 2.628.818.128 1.491.81 1.491 1.638v.501c.031 6.043-.48 11.332-9.472 14.903a1.45 1.45 0 0 1-1.062 0c-8.985-3.571-9.492-8.86-9.462-14.903l-.001-.497c-.002-.83.672-1.505 1.493-1.62Zm7.8 2.414a1 1 0 0 0-.293.707v4a1 1 0 1 0 2 0v-4a1 1 0 0 0-1.707-.707Zm0 8a1 1 0 1 0 1.414 1.414 1 1 0 0 0-1.414-1.414Z"/></svg>; }
export function FraudProtectUnprotectedMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M16.822 12.683c-1.349 2.513-3.885 4.485-6.818 5.317-2.935-.832-5.471-2.804-6.82-5.317-1.344-2.502-1.542-5.536-.586-8.808 1.362-.089 4.385-.448 7.406-1.875 3.02 1.427 6.043 1.786 7.404 1.875.958 3.272.758 6.306-.586 8.808zm-7.822-3.683a1 1 0 1 0 2 0v-2a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"/></svg>; }
export function GamesConsoleMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M11 2.774c0-.432.344-.774.77-.774h2.23v-2h-2.23a2.769 2.769 0 0 0-2.77 2.774v2.226h-3.45a5 5 0 0 0-4.977 4.516l-.557 5.737a3.416 3.416 0 0 0 6.712 1.17l.073-.291a1.5 1.5 0 0 1 1.454-1.132h3.74a1.5 1.5 0 0 1 1.454 1.132l.098.386a3.288 3.288 0 0 0 6.46-1.124l-.569-5.876a5 5 0 0 0-4.977-4.518h-3.461v-2.226Zm-5 4.226a1 1 0 0 1 1 1v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 1 1 0-2h1v-1a1 1 0 0 1 1-1Zm7.5 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm3 4a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"/></svg>; }
export function GiftCardMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5 4.5c0-.737.69-1.5 1.77-1.5 1.048 0 2.23.87 2.23 2.333v.667h-.846c-.805 0-1.656-.011-2.306-.25-.302-.112-.498-.253-.621-.413-.115-.15-.227-.397-.227-.837zm6.846 1.5h-.846v-.667c0-1.463 1.181-2.333 2.23-2.333 1.08 0 1.77.763 1.77 1.5 0 .44-.112.686-.227.837-.123.16-.319.3-.621.412-.65.24-1.5.251-2.306.251zm5.154-1.5c0 .558-.103 1.06-.306 1.5h1.806a1.5 1.5 0 0 1 1.5 1.5v2.5h-20v-2.5a1.5 1.5 0 0 1 1.5-1.5h1.806a3.547 3.547 0 0 1-.306-1.5c0-2.03 1.783-3.5 3.77-3.5 1.165 0 2.398.546 3.23 1.529.832-.983 2.065-1.529 3.23-1.529 1.988 0 3.77 1.47 3.77 3.5zm-8 15.5v-8h-8v6.5c0 .83.67 1.5 1.5 1.5h6.5zm2 0v-8h8v6.5c0 .83-.67 1.5-1.5 1.5h-6.5z"/></svg>; }
export function GiftCardMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5 5c0 .35.06.687.17 1h-.67a1.5 1.5 0 0 0-1.5 1.5v1.5h14v-1.5a1.5 1.5 0 0 0-1.34-1.492 3.5 3.5 0 0 0-6.022-3.523 3 3 0 0 0-4.638 2.515zm7.5 1a1.5 1.5 0 1 0-1.5-1.5v1.5h1.5zm-3.5-1v1h-1a1 1 0 1 1 1-1z"/><path d="M4 11h5v6h-3.5a1.5 1.5 0 0 1-1.5-1.5v-4.5zm12 0h-5v6h3.5a1.5 1.5 0 0 0 1.5-1.5v-4.5z"/></svg>; }
export function GlobeMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3.07 6a8.025 8.025 0 0 1 4.262-3.544 12.802 12.802 0 0 0-1.737 3.544h-2.525zm-.818 2a8.015 8.015 0 0 0-.252 2c0 .69.088 1.36.252 2h2.89a13.886 13.886 0 0 1-.142-2c0-.704.051-1.371.143-2h-2.891zm4.916 0c-.108.62-.168 1.286-.168 2 0 .713.061 1.38.168 2h5.664c.107-.62.168-1.287.168-2 0-.714-.061-1.38-.168-2h-5.664zm7.69 0a14.102 14.102 0 0 1-.001 4h2.891a8 8 0 0 0 .252-2 8 8 0 0 0-.252-2h-2.89zm2.072-2h-2.525a12.805 12.805 0 0 0-1.737-3.544 8.025 8.025 0 0 1 4.262 3.544zm-4.638 0h-4.584c.324-.865.725-1.596 1.124-2.195.422-.633.842-1.117 1.168-1.452.326.335.746.82 1.168 1.452.4.599.8 1.33 1.124 2.195zm-1.124 10.195c.4-.599.8-1.33 1.124-2.195h-4.584c.324.865.725 1.596 1.124 2.195.422.633.842 1.117 1.168 1.452.326-.335.746-.82 1.168-1.452zm-8.098-2.195h2.525a12.802 12.802 0 0 0 1.737 3.544 8.025 8.025 0 0 1-4.262-3.544zm9.762 3.305a12.9 12.9 0 0 1-.164.24 8.025 8.025 0 0 0 4.262-3.545h-2.525a12.805 12.805 0 0 1-1.573 3.305zm7.168-7.305c0 5.52-4.472 9.994-9.99 10h-.022c-5.518-.006-9.988-4.481-9.988-10 0-5.523 4.477-10 10-10s10 4.477 10 10z"/></svg>; }
export function GlobeMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M12.347 15.521c.699-1.16 1.39-2.712 1.589-4.521h1.974a6.013 6.013 0 0 1-3.563 4.521zm-8.257-4.521h1.975c.198 1.809.889 3.361 1.589 4.521a6.017 6.017 0 0 1-3.564-4.521zm3.564-6.521c-.7 1.16-1.391 2.712-1.59 4.521h-1.974a6.017 6.017 0 0 1 3.564-4.521zm.423 4.521c.269-1.916 1.239-3.52 1.925-4.443.687.92 1.655 2.519 1.922 4.443h-3.847zm1.92 6.443c-.685-.919-1.653-2.519-1.921-4.443h3.847c-.27 1.916-1.24 3.521-1.925 4.443zm5.913-6.443h-1.974c-.198-1.809-.89-3.361-1.59-4.521a6.013 6.013 0 0 1 3.564 4.521zm-5.91-7c-4.411 0-8 3.589-8 8s3.589 8 8 8c4.41 0 8-3.589 8-8s-3.59-8-8-8z"/></svg>; }
export function GrammarMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M19 2.5a1.5 1.5 0 0 0-1.5-1.5h-15a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h6.5a1 1 0 0 0 0-2h-6v-14h14v8a1 1 0 1 0 2 0v-8.5zm-5 8.5h-8v-2h8v2zm-8 4v-2h4v2h-4zm0-10v2h8v-2h-8zm12.293 9.293-3.293 3.293-1.293-1.293a1 1 0 0 0-1.414 1.414l2 2a.997.997 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414z"/></svg>; }
export function HashtagMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.992 2.124a1 1 0 1 0-1.984-.248l-.39 3.124h-3.618a1 1 0 0 0 0 2h3.367l-.75 6h-2.617a1 1 0 1 0 0 2h2.367l-.36 2.876a1 1 0 1 0 1.985.248l.39-3.124h5.985l-.36 2.876a1 1 0 0 0 1.985.248l.39-3.124h3.618a1 1 0 1 0 0-2h-3.367l.75-6h2.617a1 1 0 1 0 0-2h-2.367l.36-2.876a1 1 0 1 0-1.985-.248l-.39 3.124h-5.986l.36-2.876zm4.625 10.876.75-6h-5.984l-.75 6h5.984z"/></svg>; }
export function HashtagMinor() { return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M14.164 3.014a1 1 0 0 1 .822 1.15l-.306 1.836h1.32a1 1 0 1 1 0 2h-1.653l-.666 4h2.319a1 1 0 1 1 0 2h-2.653l-.36 2.164a1 1 0 1 1-1.973-.328l.306-1.836h-3.973l-.36 2.164a1 1 0 1 1-1.973-.328l.306-1.836h-1.32a1 1 0 1 1 0-2h1.653l.667-4h-2.32a1 1 0 1 1 0-2h2.653l.36-2.164a1 1 0 1 1 1.973.328l-.306 1.836h3.973l.36-2.164a1 1 0 0 1 1.151-.822Zm-5.817 4.986-.667 4h3.973l.666-4h-3.972Z"/></svg>; }
export function HeaderMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M1 2.5v6.5h18v-6.5a1.5 1.5 0 0 0-1.5-1.5h-15a1.5 1.5 0 0 0-1.5 1.5zm1 16.5a1 1 0 0 1-1-1v-2h2v1h1v2h-2zm17-1a1 1 0 0 1-1 1h-2v-2h1v-1h2v2zm-18-4v-3h2v3h-2zm16-3v3h2v-3h-2zm-11 6h3v2h-3v-2zm8 0h-3v2h3v-2z"/></svg>; }
export function HeartMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6 1a5 5 0 0 0-5 5v.448c0 5.335 2.955 9.647 8.598 12.457a.902.902 0 0 0 .804 0c5.644-2.81 8.598-7.122 8.598-12.457v-.448a5 5 0 0 0-9-3 4.992 4.992 0 0 0-4-2z"/></svg>; }
export function HideKeyboardMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M0 3.5a1.5 1.5 0 0 1 1.5-1.5h17a1.5 1.5 0 0 1 1.5 1.5v7a1.5 1.5 0 0 1-1.5 1.5h-17a1.5 1.5 0 0 1-1.5-1.5v-7zm3 .5h2v2h-2v-2zm1 4v2h2v-2h-2zm8 2h-4v-2h4v2zm-3-6h-2v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm-1 6v-2h-2v2h2z"/><path d="m10 16.586-2.293-2.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l3-3a1 1 0 0 0-1.414-1.414l-2.293 2.293z"/></svg>; }
export function HideMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 0 0 2-2c0-.178-.03-.348-.074-.512l5.781-5.781a.999.999 0 1 0-1.414-1.414l-2.61 2.61a7.757 7.757 0 0 0-3.683-.903c-5.612 0-7.837 5.399-7.929 5.628a1.017 1.017 0 0 0 0 .744c.054.133.835 2.011 2.582 3.561l-2.36 2.36a.999.999 0 1 0 1.414 1.414l5.781-5.781c.164.043.334.074.512.074zm-4-2a4 4 0 0 1 4-4c.742 0 1.432.208 2.025.561l-1.513 1.513a2.004 2.004 0 0 0-.512-.074 2 2 0 0 0-2 2c0 .178.031.347.074.511l-1.513 1.514a3.959 3.959 0 0 1-.561-2.025zm10.145-3.144-2.252 2.252c.064.288.106.585.106.893a4 4 0 0 1-4 4 3.97 3.97 0 0 1-.89-.108l-1.682 1.68a7.903 7.903 0 0 0 2.573.427c5.613 0 7.837-5.399 7.928-5.629a1.004 1.004 0 0 0 0-.742c-.044-.111-.596-1.437-1.784-2.773z"/></svg>; }
export function HintMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 0a1 1 0 0 1 1 1v1a1 1 0 1 1-2 0v-1a1 1 0 0 1 1-1zm2 17v1.5a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1.5h4zm6-8a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2h-1zm-18 1a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2h-1a1 1 0 0 1-1-1zm4.343-7.071a1 1 0 0 0-1.413 1.414l.707.707a1 1 0 1 0 1.413-1.414l-.707-.707zm10.607 2.121a1 1 0 0 1 0-1.414l.707-.707a1 1 0 1 1 1.414 1.414l-.707.707a1 1 0 0 1-1.414 0zm-1.682 6.257c-.618.874-1.268 1.817-1.268 2.888v.805h-4v-.805c0-1.07-.65-2.014-1.268-2.888a4 4 0 1 1 6.536 0z"/></svg>; }
export function HomeMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M18 7.261v10.239c0 .841-.672 1.5-1.5 1.5h-2c-.828 0-1.5-.659-1.5-1.5v-4.5h-6v4.477c0 .841-.672 1.523-1.5 1.523h-2c-.828 0-1.5-.682-1.5-1.523v-10.216a1.5 1.5 0 0 1 .615-1.21l6.59-4.82a1.481 1.481 0 0 1 1.59 0l6.59 4.82a1.5 1.5 0 0 1 .615 1.209z"/></svg>; }
export function HomeMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.555 3.168a1 1 0 0 0-1.11 0l-5.554 3.702a2 2 0 0 0-.891 1.665v6.465a2 2 0 0 0 2 2h2a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h2a2 2 0 0 0 2-2v-6.465a2 2 0 0 0-.89-1.664l-5.555-3.703Z"/></svg>; }
export function HorizontalDotsMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6 10a2 2 0 1 1-4.001-.001 2 2 0 0 1 4.001.001zm6 0a2 2 0 1 1-4.001-.001 2 2 0 0 1 4.001.001zm6 0a2 2 0 1 1-4.001-.001 2 2 0 0 1 4.001.001z"/></svg>; }
export function IconsMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m10.618 18 3.382-6.764 3.382 6.764h-6.764zm-6.618-2v-6.101a5.006 5.006 0 0 0 5.898-5.899h6.102v6.764l-1.106-2.211c-.339-.678-1.449-.678-1.789 0l-3.723 7.447h-5.382zm15.894 2.553-1.894-3.789v-11.264a1.5 1.5 0 0 0-1.5-1.5h-7.526c-.914-1.207-2.347-2-3.974-2-2.757 0-5 2.243-5 5 0 1.627.793 3.061 2 3.975v7.525a1.5 1.5 0 0 0 1.5 1.5h4.882l-.277.553a1 1 0 0 0 .895 1.447h10a.997.997 0 0 0 .894-1.447z"/></svg>; }
export function IllustrationMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M19.685 1.46c.1-.099.2-.198.2-.298.184-.276.113-.551.023-.905l-.024-.092c-.3-.2-.6-.2-.899-.1-.05.05-.1.075-.15.1-.05.025-.1.05-.15.1l-6.495 3.887c-2.598 1.495-4.596 3.688-5.995 6.28 1.499.598 2.798 1.893 3.298 3.488a16.485 16.485 0 0 0 6.295-5.98l3.897-6.48zm-15.688 18.54c2.198 0 3.997-1.794 3.997-3.987s-1.799-3.987-3.997-3.987-3.997 1.794-3.997 3.987v3.987h3.997z"/></svg>; }
export function ImageAltMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.895 6.553c-.34-.678-1.45-.678-1.79 0l-3 6a1 1 0 1 0 1.79.894l.724-1.447h2.764l.723 1.447a1 1 0 0 0 1.789-.894l-3-6zm8.105 5.447h-2v-5a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1h3a1 1 0 0 0 0-2zm6-6h-4a1 1 0 1 0 0 2h1v5a1 1 0 1 0 2 0v-5h1a1 1 0 1 0 0-2zm-15.381 4 .381-.764.382.764h-.763z"/></svg>; }
export function ImageAltMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M12 11a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1v-4a1 1 0 1 1 2 0v3h1zm5-4a1 1 0 1 1 0 2h-1v3a1 1 0 1 1-2 0v-3h-1a1 1 0 1 1 0-2h4zm-11.105.553 2 4a1 1 0 0 1-1.79.895l-.222-.448h-1.764l-.224.448a1 1 0 0 1-1.79-.895l2-4c.34-.678 1.45-.678 1.79 0z"/></svg>; }
export function ImageMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.5 1a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-15a1.5 1.5 0 0 0-1.5-1.5h-15zm5 3.5c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm8.999 12.5h-13.002c-.41 0-.64-.46-.4-.79l3.553-4.051c.19-.21.52-.21.72-.01l1.63 1.851 3.06-4.781a.5.5 0 0 1 .84.02l4.039 7.011c.18.34-.06.75-.44.75z"/></svg>; }
export function ImagesMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M19 16a1 1 0 0 0 1-1v-14a1 1 0 0 0-1-1h-14a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14zm-12.574-2c-.253 0-.426-.191-.426-.396 0-.08.026-.162.083-.236l3.046-3.24a.448.448 0 0 1 .617-.009l1.397 1.481 2.623-3.825a.446.446 0 0 1 .72.016l3.462 5.609c.154.272-.052.6-.377.6h-11.145zm4.574-9a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm-11-.5a1.5 1.5 0 0 1 1.5-1.5h.5v15h15v.5a1.5 1.5 0 0 1-1.5 1.5h-14a1.5 1.5 0 0 1-1.5-1.5v-14z"/></svg>; }
export function ImageWithTextMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 2a1.5 1.5 0 0 0-1.5 1.5v5a1.5 1.5 0 0 0 1.5 1.5h6a1.5 1.5 0 0 0 1.5-1.5v-5a1.5 1.5 0 0 0-1.5-1.5h-6zm16.5 4h-6a1 1 0 1 1 0-2h6a1 1 0 1 1 0 2zm-6 4h6a1 1 0 1 0 0-2h-6a1 1 0 1 0 0 2zm6 4h-17a1 1 0 0 1 0-2h17a1 1 0 0 1 0 2zm-3.293 3.707a1 1 0 0 1-.707.293h-13a1 1 0 0 1 0-2h13a1 1 0 0 1 .707 1.707z"/></svg>; }
export function ImageWithTextOverlayMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 1a1 1 0 0 1 1-1h7a1 1 0 1 1 0 2h-7a1 1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1zm-2.917-1a6.002 6.002 0 0 0 5.917 5h2v9.5a1.5 1.5 0 0 1-1.5 1.5h-13a1.5 1.5 0 0 1-1.5-1.5v-13a1.5 1.5 0 0 1 1.5-1.5h6.583zm-3.083 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm-2.574 7h11.145c.325 0 .531-.328.377-.6l-3.462-5.609a.446.446 0 0 0-.72-.016l-2.623 3.825-1.397-1.48a.449.449 0 0 0-.617.007l-3.045 3.241c-.206.264-.01.632.342.632z"/></svg>; }
export function ImportMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M13.707 10.707a.999.999 0 1 0-1.414-1.414l-1.293 1.293v-7.586a1 1 0 1 0-2 0v7.586l-1.293-1.293a.999.999 0 1 0-1.414 1.414l3 3a.999.999 0 0 0 1.414 0l3-3zm-10.707 5.293a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2h-14z"/></svg>; }
export function ImportStoreMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="m1.014 5.836.61-3.583a1.522 1.522 0 0 1 1.51-1.253h13.733c.748 0 1.387.53 1.51 1.253l.609 3.583c.104.61-.376 1.164-1.007 1.164h-.91c-.58 0-1.11-.321-1.37-.83l-.597-1.17-.597 1.17c-.26.509-.79.83-1.37.83h-1.169c-.58 0-1.11-.321-1.369-.83l-.597-1.17-.597 1.17c-.26.509-.79.83-1.37.83h-1.169c-.58 0-1.11-.321-1.369-.83l-.597-1.17-.598 1.17c-.259.509-.789.83-1.369.83h-.91c-.63 0-1.11-.555-1.007-1.164Zm6.779 2.957a1 1 0 0 0 0 1.414l.793.793h-6.586a1 1 0 1 0 0 2h6.586l-.793.793a1 1 0 0 0 1.414 1.414l2.5-2.5a.998.998 0 0 0 .293-.704v-.006a.997.997 0 0 0-.284-.695l-.01-.01-2.499-2.5a1 1 0 0 0-1.414 0Zm10.207.207h-2v8h-12v-2h-2v2.5a1.5 1.5 0 0 0 1.5 1.5h13a1.5 1.5 0 0 0 1.5-1.5v-8.5Z"/></svg>; }
export function IncomingMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 6a1.5 1.5 0 0 1 1.5 1.5v10a1.5 1.5 0 0 1-1.5 1.5h-15a1.5 1.5 0 0 1-1.5-1.5v-10a1.5 1.5 0 0 1 1.5-1.5h4.5v2h-4v9h14v-9h-4v-2h4.5z"/><path d="M1 2a1 1 0 0 1 0-2h5c2.757 0 5 2.243 5 5v6.586l1.293-1.293a1 1 0 0 1 1.414 1.414l-3 3a.998.998 0 0 1-1.414 0l-3-3a1 1 0 1 1 1.414-1.414l1.293 1.293v-6.586c0-1.654-1.346-3-3-3h-5z"/></svg>; }
export function InfoMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-9 3a1 1 0 1 0 2 0v-2a1 1 0 1 0-2 0v2zm0-6a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"/></svg>; }
export function InstallMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 4.5a1.5 1.5 0 0 1 1.5-1.5h11a1.5 1.5 0 0 1 1.5 1.5v11a1.5 1.5 0 0 1-1.5 1.5h-11a1.5 1.5 0 0 1-1.5-1.5v-11zm2 8.5v2h10v-2h-10zm2.207-7.207a1 1 0 0 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0-1.414-1.414l-2.793 2.793-2.793-2.793z"/></svg>; }
export function InventoryMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M19.838 5.455a1 1 0 0 0-.432-.369l-9-4a.999.999 0 0 0-.812 0l-9 4a1 1 0 0 0-.594.914v14h2v-13.35l8-3.556 8 3.556v13.35h2v-14a1 1 0 0 0-.162-.545zm-7.838 2.545h4v4h-4v-4zm-2 2h-6v10h6v-10zm6 4h-4v6h4v-6z"/></svg>; }
export function InviteMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3.5 4a1.5 1.5 0 0 0-1.5 1.5v9a1.5 1.5 0 0 0 1.5 1.5h13a1.5 1.5 0 0 0 1.5-1.5v-9a1.5 1.5 0 0 0-1.5-1.5h-13zm2.015 2.143a1 1 0 1 0-1.03 1.714l5 3a1 1 0 0 0 1.03 0l5-3a1 1 0 1 0-1.03-1.714l-4.485 2.69-4.485-2.69z"/></svg>; }
export function IqMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.896 2.141a.98.98 0 1 0 1.387-1.386.98.98 0 0 0-1.387 1.385z"/><path fillRule="evenodd" d="M2.812 12.676a7.055 7.055 0 0 1 0-9.97 7.025 7.025 0 0 1 4.462-2.045.9.9 0 0 1 .132 1.795 5.225 5.225 0 0 0-3.321 1.521 5.255 5.255 0 0 0 0 7.425 5.256 5.256 0 0 0 5.491 1.227 2.95 2.95 0 1 1 .674 1.67 7.057 7.057 0 0 1-7.438-1.624zm8.893.551a1.15 1.15 0 1 1 1.627-1.626 1.15 1.15 0 0 1-1.627 1.626z"/><path fillRule="evenodd" d="M11.651 19.408a.9.9 0 0 1 .22-1.786 5.242 5.242 0 0 0 4.36-1.496 5.255 5.255 0 0 0 0-7.425 5.257 5.257 0 0 0-5.556-1.204 2.95 2.95 0 1 1-.621-1.69 7.057 7.057 0 0 1 7.45 1.621 7.055 7.055 0 0 1 0 9.97 7.042 7.042 0 0 1-5.853 2.01zm-4.737-12.598a1.15 1.15 0 1 0 1.627 1.627 1.15 1.15 0 0 0-1.627-1.627z"/><circle cx="8.646" cy="17.934" r=".94"/></svg>; }
export function JobsMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="m5.508 2.466-1.152 2.534h-1.856a1.5 1.5 0 0 0-1.5 1.5v1.882l.503.251a19 19 0 0 0 16.994 0l.503-.251v-1.882a1.5 1.5 0 0 0-1.5-1.5h-1.856l-1.152-2.534a2.5 2.5 0 0 0-2.276-1.466h-4.432a2.5 2.5 0 0 0-2.276 1.466zm2.276.534a.5.5 0 0 0-.455.293l-.776 1.707h6.894l-.776-1.707a.5.5 0 0 0-.455-.293h-4.432z"/><path d="M19 10.613a20.986 20.986 0 0 1-8 2.003v1.384a1 1 0 0 1-2 0v-1.384a20.995 20.995 0 0 1-8-2.003v6.887a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-6.887z"/></svg>; }
export function KeyMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M14.56.44a1.5 1.5 0 0 1 1.062-.44h2.878a1.5 1.5 0 0 1 1.5 1.5v3.423a.5.5 0 0 1-.5.5h-1.082a1 1 0 0 0-1 1v1a1 1 0 0 1-1 1h-1a1 1 0 0 0-1 1v2l-1.449 1.443a6.5 6.5 0 1 1-5.111-5.724l6.703-6.703zm-6.56 13.56a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"/></svg>; }
export function LabelPrinterMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4 2.5a1.5 1.5 0 0 1 1.5-1.5h9a1.5 1.5 0 0 1 1.5 1.5v3.5h1.5a1.5 1.5 0 0 1 1.5 1.5v10a1.5 1.5 0 0 1-1.5 1.5h-15a1.5 1.5 0 0 1-1.5-1.5v-10a1.5 1.5 0 0 1 1.5-1.5h1.5v-3.5zm2 9.5h8v-9h-8v9zm-2 3a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1zm11 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/></svg>; }
export function LandingPageMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 7c0-.552-.449-1-1-1h-9a1 1 0 0 1 0-2h9c1.654 0 3 1.346 3 3v5.586l1.293-1.293a1 1 0 0 1 1.414 1.414l-3 3a.998.998 0 0 1-1.414 0l-3-3a1 1 0 1 1 1.414-1.414l1.293 1.293v-5.586zm8.56-2.853a1.5 1.5 0 0 1 .44 1.06v13.293a1.5 1.5 0 0 1-1.5 1.5h-13a1.5 1.5 0 0 1-1.5-1.5v-10.5h2v10h12v-12.586l-3.414-3.414h-10.586v-.5a1.5 1.5 0 0 1 1.5-1.5h9.293a1.5 1.5 0 0 1 1.06.44l3.708 3.707z"/></svg>; }
export function LanguageMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m11.428 13.999 1.572-3.538 1.573 3.538zm6.486 2.594-4-9a1 1 0 0 0-1.828 0l-1.958 4.406h-.128c-1.276 0-2.24-.3-2.965-.716l.012-.012c1.292-1.443 1.777-3.536 1.94-5.272h1.013a1 1 0 1 0 0-2h-.942c-.015-.63-.058-1.051-.064-1.11a.998.998 0 0 0-1.103-.884 1.002 1.002 0 0 0-.885 1.103c0 .013.035.359.05.89h-4.056a1 1 0 0 0 0 2h3.98c-.143 1.37-.515 2.922-1.42 3.935a4.165 4.165 0 0 1-.591-1.183.999.999 0 0 0-1.938.492c.118.474.373 1.07.778 1.679-.254.05-.522.078-.81.078a1 1 0 0 0 0 2c.857 0 1.629-.16 2.322-.467.962.734 2.253 1.303 3.932 1.435l-1.167 2.626a1.001 1.001 0 0 0 1.828.812l.625-1.406h4.922l.625 1.406a1 1 0 0 0 1.828-.812z"/></svg>; }
export function LegalMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 5h5v2h-5v-2zm5 4h-5v2h5v-2z"/><path fillRule="evenodd" d="M16 17a3 3 0 0 1-3 3h-10a3 3 0 0 1-3-3v-1.5a1.5 1.5 0 0 1 1.5-1.5h1.5v-10a3 3 0 0 1 3-3h11a3 3 0 1 1 0 6h-1v10zm-11-13a1 1 0 0 1 1-1h8.17c-.11.313-.17.65-.17 1v13a1 1 0 1 1-2 0v-3h-7v-10zm12-1a1 1 0 0 0-1 1v1h1a1 1 0 1 0 0-2zm-7 14c0 .35.06.687.17 1h-7.17a1 1 0 0 1-1-1v-1h8v1z"/></svg>; }
export function LinkMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6.534 18a4.507 4.507 0 0 1-3.208-1.329 4.54 4.54 0 0 1 0-6.414l1.966-1.964a.999.999 0 1 1 1.414 1.414l-1.966 1.964a2.54 2.54 0 0 0 0 3.586c.961.959 2.631.958 3.587 0l1.966-1.964a1 1 0 1 1 1.415 1.414l-1.966 1.964a4.503 4.503 0 0 1-3.208 1.329zm7.467-6a.999.999 0 0 1-.707-1.707l1.966-1.964a2.54 2.54 0 0 0 0-3.586c-.961-.959-2.631-.957-3.587 0l-1.966 1.964a1 1 0 1 1-1.415-1.414l1.966-1.964a4.503 4.503 0 0 1 3.208-1.329c1.211 0 2.351.472 3.208 1.329a4.541 4.541 0 0 1 0 6.414l-1.966 1.964a.997.997 0 0 1-.707.293zm-6.002 1a.999.999 0 0 1-.707-1.707l4.001-4a1 1 0 1 1 1.415 1.414l-4.001 4a1 1 0 0 1-.708.293z"/></svg>; }
export function ListMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M1 2.5a1.5 1.5 0 0 1 1.5-1.5h15a1.5 1.5 0 0 1 1.5 1.5v15a1.5 1.5 0 0 1-1.5 1.5h-15a1.5 1.5 0 0 1-1.5-1.5v-15zm7 2.5h8v2h-8v-2zm8 4h-8v2h8v-2zm-8 4h8v2h-8v-2zm-3-6a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm1 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1 5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/></svg>; }
export function LiveViewMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M13.293 2.707a1 1 0 0 0 1.707-.707v-1a1 1 0 0 0-2 0v1a1 1 0 0 0 .293.707zm3.707 1.293a.999.999 0 0 1-.707-1.707l1-1a1 1 0 1 1 1.414 1.414l-1 1a.996.996 0 0 1-.707.293zm2 3h-1a1 1 0 1 1 0-2h1a1 1 0 1 1 0 2zm-4-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-14-3a2 2 0 0 1 2-2h8v2h-8v8.464c1.795-.934 4.836-1.464 7-1.464 2.164 0 5.205.529 7 1.464v-2.464h2v8a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-14z"/></svg>; }
export function LocationMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 8c0-4.42-3.58-8-8-8s-8 3.58-8 8c0 .15 0 .29.01.44.13 3.55 1.99 7.62 7.13 11.29.51.36 1.21.36 1.72 0 5.14-3.67 7-7.74 7.13-11.29.01-.15.01-.29.01-.44zm-5.879 2.121a2.996 2.996 0 0 0 0-4.242 2.996 2.996 0 0 0-4.242 0 2.996 2.996 0 0 0 0 4.242 2.996 2.996 0 0 0 4.242 0z"/></svg>; }
export function LocationsMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm0-8c-3.31 0-6 2.87-6 6.4 0 6 5.4 9.35 5.63 9.49.11.07.24.11.37.11s.26-.04.37-.11c.23-.14 5.63-3.49 5.63-9.49 0-3.53-2.69-6.4-6-6.4z"/></svg>; }
export function LockMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4 6v2h-1.5a1.5 1.5 0 0 0-1.5 1.5v9a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-9a1.5 1.5 0 0 0-1.5-1.5h-1.5v-2a6 6 0 0 0-12 0zm6-4a4 4 0 0 0-4 4v2h8v-2a4 4 0 0 0-4-4zm1 14v-4h-2v4h2z"/></svg>; }
export function LockMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M15 8v-1c0-2.757-2.243-5-5-5s-5 2.243-5 5v1a1 1 0 0 0-1 1v7.5a1.5 1.5 0 0 0 1.5 1.5h9a1.5 1.5 0 0 0 1.5-1.5v-7.5a1 1 0 0 0-1-1zm-6 7h2v-4h-2v4zm4-7h-6v-1c0-1.654 1.346-3 3-3s3 1.346 3 3v1z"/></svg>; }
export function LogoBlockMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M1 2a1 1 0 0 1 1-1h2v2h-1v1h-2v-2zm18 0a1 1 0 0 0-1-1h-2v2h1v1h2v-2zm-17 17a1 1 0 0 1-1-1v-2h2v1h1v2h-2zm16 0a1 1 0 0 0 1-1v-2h-2v1h-1v2h2zm-12-2h3v2h-3v-2zm5 0h3v2h-3v-2zm3-16v2h-3v-2h3zm-5 0v2h-3v-2h3zm.598 5.332a4 4 0 0 0-3.573 7.147l3.573-7.147zm-1.598 7.668h8l-4-8-4 8z"/></svg>; }
export function LogOutMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 19a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.707-10.293a1 1 0 0 1 1.414-1.414l3 3a1 1 0 0 1 0 1.414l-3 3a1 1 0 0 1-1.414-1.414l1.293-1.293h-3.586a1 1 0 1 1 0-2h3.586l-1.293-1.293z"/></svg>; }
export function ManagedStoreMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m1.623 2.253-.609 3.583c-.104.61.376 1.164 1.007 1.164h.91c.58 0 1.11-.321 1.37-.83l.596-1.17.597 1.17c.26.509.79.83 1.37.83h1.169c.58 0 1.11-.321 1.369-.83l.598-1.17.597 1.17c.26.509.79.83 1.37.83h1.169c.58 0 1.11-.321 1.369-.83l.597-1.17.598 1.17c.259.509.789.83 1.369.83h.91c.63 0 1.11-.555 1.007-1.164l-.61-3.583a1.522 1.522 0 0 0-1.509-1.253h-13.733c-.748 0-1.387.53-1.51 1.253zm12.084 7.04a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 1 1 1.414-1.414l1.293 1.293 3.293-3.293a1 1 0 0 1 1.414 0zm-9.707-.293h-2v8.5a1.5 1.5 0 0 0 1.5 1.5h13a1.5 1.5 0 0 0 1.5-1.5v-8.5h-2v8h-12v-8z"/></svg>; }
export function MarketingMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.1 14.95c.412.16.9-.08.9-.45v-13c0-.37-.488-.61-.9-.45-.683.41-1.311.83-1.919 1.237-2.14 1.43-4.019 2.687-7.084 2.713h-4.097c-1.66 0-3 1.34-3 3s1.34 3 3 3h4c3.123 0 5.02 1.268 7.182 2.714.607.406 1.236.826 1.918 1.236zm-8.1 3.05h-.79c-.43 0-.81-.27-.94-.67l-2.2-4.33h3.93a1 1 0 0 1 1 1v3c0 .55-.45 1-1 1z"/></svg>; }
export function MarketingMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 4a5 5 0 0 0-.163 9.997l.662 1.986a7 7 0 1 1 6.484-6.484l-1.986-.662a5 5 0 0 0-4.997-4.837Z"/><path d="M9 8a1 1 0 0 0 0 2v2a3 3 0 1 1 3-3h-2a1 1 0 0 0-1-1Z"/><path d="M11.316 10.051a1 1 0 0 0-1.265 1.265l2 6a1 1 0 0 0 1.898 0l.842-2.525 2.525-.842a1 1 0 0 0 0-1.898l-6-2Z"/></svg>; }
export function MarketsMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.55 7.55a7.95 7.95 0 0 0-5.1-5.1l-2.45-2.45-2.45 2.45a7.957 7.957 0 0 0-5.1 5.1l-2.45 2.45 2.45 2.45a7.956 7.956 0 0 0 5.1 5.1l2.45 2.45 2.45-2.45a7.96 7.96 0 0 0 5.1-5.118l2.45-2.45-2.45-2.431Zm-1.584 4.063-.032.123-.038.132a6.214 6.214 0 0 1-4.01 4.01l-.133.039h-.039a9.658 9.658 0 0 0 1.89-5.06h2.516c0 .06-.018.12-.028.179a5.49 5.49 0 0 1-.123.577h-.003Zm-11.844.255-.039-.133c0-.045-.02-.09-.035-.136a5.986 5.986 0 0 1-.122-.553c0-.06 0-.119-.028-.178h2.52a9.685 9.685 0 0 0 1.885 5.06h-.038l-.133-.04a6.214 6.214 0 0 1-4.007-4.023l-.003.003Zm-.074-3.498c0-.045.025-.09.035-.136l.039-.133a6.214 6.214 0 0 1 4.01-4.01l.133-.038h.038a9.685 9.685 0 0 0-1.886 5.063h-2.512c0-.063 0-.123.028-.182.028-.182.07-.368.119-.55l-.004-.014Zm11.848-.27.038.133c0 .046.025.091.035.136.05.182.091.368.123.553 0 .06.02.12.028.182h-2.516a9.657 9.657 0 0 0-1.89-5.063h.039l.133.039a6.214 6.214 0 0 1 4.013 4.034l-.003-.014Zm-5.886 7.124a7.918 7.918 0 0 1-1.836-4.37h3.673a7.967 7.967 0 0 1-1.833 4.384l-.003-.014Zm-1.836-6.12a7.918 7.918 0 0 1 1.837-4.373 7.967 7.967 0 0 1 1.836 4.373h-3.673Z"/></svg>; }
export function MarkFulfilledMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M18 16.5a1.5 1.5 0 0 1-1.5 1.5h-13a1.5 1.5 0 0 1-1.5-1.5v-7.5h2v4h2.382c.379 0 .725.214.894.553l.724 1.447h4l.724-1.447a.998.998 0 0 1 .894-.553h2.382v-4h2v7.5z"/><path d="m8.293 8.707-3-3a.999.999 0 1 1 1.414-1.414l2.293 2.293 4.293-4.293a.999.999 0 1 1 1.414 1.414l-5 5a.997.997 0 0 1-1.414 0z"/></svg>; }
export function MarkPaidMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M14.207 6.793a1 1 0 0 1 0 1.414l-4.5 4.5a1 1 0 0 1-1.414 0l-2.5-2.5a1 1 0 0 1 1.414-1.414l1.793 1.793 3.793-3.793a1 1 0 0 1 1.414 0zm-9.707-3.793a1.5 1.5 0 0 0-1.5 1.5v12.5a1.002 1.002 0 0 0 1.301.953c.179-.056.315-.193.437-.336l1.122-1.322 1.349 1.399c.184.191.437.301.702.306a.96.96 0 0 0 .713-.281l1.364-1.317 1.305 1.305a.997.997 0 0 0 1.414 0l1.42-1.42 1.138 1.334c.12.14.254.275.43.331a1 1 0 0 0 1.305-.952v-12.5a1.5 1.5 0 0 0-1.5-1.5h-11z"/></svg>; }
export function MaximizeMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 5a1 1 0 1 0 0 2h2.586l-3.293 3.293a1 1 0 1 0 1.414 1.414l3.293-3.293v2.586a1 1 0 1 0 2 0v-5c0-.025 0-.05-.003-.075a1 1 0 0 0-.997-.925h-5zm-8 9.5a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a1.5 1.5 0 0 1-1.5-1.5v-3z"/><path d="M2.5 1a1.5 1.5 0 0 0-1.5 1.5v8.5h2v-8h14v14h-8v2h8.5a1.5 1.5 0 0 0 1.5-1.5v-15a1.5 1.5 0 0 0-1.5-1.5h-15z"/></svg>; }
export function MaximizeMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 3a1.5 1.5 0 0 0-1.5 1.5v2.5a1 1 0 0 0 2 0v-2h2a1 1 0 0 0 0-2h-2.5zm-1.5 12.5a1.5 1.5 0 0 0 1.5 1.5h2.5a1 1 0 1 0 0-2h-2v-2a1 1 0 1 0-2 0v2.5zm14 0a1.5 1.5 0 0 1-1.5 1.5h-2.5a1 1 0 1 1 0-2h2v-2a1 1 0 1 1 2 0v2.5zm0-11a1.5 1.5 0 0 0-1.5-1.5h-2.5a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2.5z"/></svg>; }
export function MentionMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 13c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-13c-5.514 0-10 4.486-10 10s4.486 10 10 10a1 1 0 0 0 0-2c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8v1c0 .827-.673 1.5-1.5 1.5s-1.5-.673-1.5-1.5v-1c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5c1.531 0 2.887-.707 3.805-1.795a3.477 3.477 0 0 0 2.695 1.295c1.93 0 3.5-1.57 3.5-3.5v-1c0-5.514-4.486-10-10-10z"/></svg>; }
export function MergeMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a1 1 0 0 0-.717.303l-5 5.143a1 1 0 0 0 1.434 1.394l3.283-3.377v6.537a1 1 0 0 0 .293.707l5 5a1 1 0 0 0 1.414-1.414l-4.707-4.707v-6.123l3.283 3.377a1 1 0 0 0 1.434-1.394l-5-5.143a1 1 0 0 0-.717-.303Zm-2.293 12.293a1 1 0 0 0-1.414 0l-2 2a1 1 0 1 0 1.414 1.414l2-2a1 1 0 0 0 0-1.414Z"/></svg>; }
export function MicrophoneMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6 4a4 4 0 1 1 8 0v4a4 4 0 0 1-8 0v-4z"/><path d="M11 15v4h-2v-4h2z"/><path d="M4 8a6 6 0 0 0 12 0h2a8 8 0 1 1-16 0h2z"/><path d="M16 6h.5a1.5 1.5 0 0 1 1.5 1.5v.5h-2v-2zm-14 1.5a1.5 1.5 0 0 1 1.5-1.5h.5v2h-2v-.5zm5 12a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 1.5 1.5v.5h-6v-.5z"/></svg>; }
export function MinimizeMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 17h8v-14h-14v8h-2v-8.5a1.5 1.5 0 0 1 1.5-1.5h15a1.5 1.5 0 0 1 1.5 1.5v15a1.5 1.5 0 0 1-1.5 1.5h-8.5v-2z"/><path d="M10 6a1 1 0 1 0-2 0v5a.997.997 0 0 0 1 1h5a1 1 0 1 0 0-2h-2.586l3.293-3.293a1 1 0 0 0-1.414-1.414l-3.293 3.293v-2.586zm-9 8.5a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a1.5 1.5 0 0 1-1.5-1.5v-3z"/></svg>; }
export function MinimizeMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 7.5a1.5 1.5 0 0 0 1.5 1.5h3.5a1 1 0 1 0 0-2h-3v-3a1 1 0 1 0-2 0v3.5zm1.5 3.5a1.5 1.5 0 0 0-1.5 1.5v3.5a1 1 0 1 0 2 0v-3h3a1 1 0 1 0 0-2h-3.5zm-5 0a1.5 1.5 0 0 1 1.5 1.5v3.5a1 1 0 1 1-2 0v-3h-3a1 1 0 1 1 0-2h3.5zm0-2a1.5 1.5 0 0 0 1.5-1.5v-3.5a1 1 0 1 0-2 0v3h-3a1 1 0 1 0 0 2h3.5z"/></svg>; }
export function MinusMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M14.167 9h-8.334c-.46 0-.833.448-.833 1s.372 1 .833 1h8.334c.46 0 .833-.448.833-1s-.373-1-.833-1"/></svg>; }
export function MobileAcceptMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 18a.997.997 0 0 1-.707-.293l-6-6a1 1 0 0 1 1.414-1.414l5.236 5.236 11.298-13.18a1 1 0 0 1 1.518 1.3l-12 14a1.001 1.001 0 0 1-.721.35h-.038"/></svg>; }
export function MobileBackArrowMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M19 9h-15.339l5.997-5.246a1 1 0 0 0-1.316-1.506l-8 7c-.008.007-.011.018-.019.025a.975.975 0 0 0-.177.24c-.018.03-.045.054-.059.087a.975.975 0 0 0 0 .802c.014.033.041.057.059.088.05.087.104.17.177.239.008.007.011.018.019.025l8 7a.996.996 0 0 0 1.411-.095 1 1 0 0 0-.095-1.411l-5.997-5.248h15.339a1 1 0 0 0 0-2z"/></svg>; }
export function MobileCancelMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m11.414 10 6.293-6.293a1 1 0 1 0-1.414-1.414l-6.293 6.293-6.293-6.293a1 1 0 0 0-1.414 1.414l6.293 6.293-6.293 6.293a1 1 0 1 0 1.414 1.414l6.293-6.293 6.293 6.293a.998.998 0 0 0 1.707-.707.999.999 0 0 0-.293-.707l-6.293-6.293z"/></svg>; }
export function MobileChevronMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M14 20.001a.994.994 0 0 1-.747-.336l-8-9a.999.999 0 0 1 0-1.328l8-9a1 1 0 0 1 1.494 1.328l-7.41 8.336 7.41 8.336a.998.998 0 0 1-.747 1.664z"/></svg>; }
export function MobileHamburgerMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M19 11h-18a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2zm0-7h-18a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2zm0 14h-18a1 1 0 0 1 0-2h18a1 1 0 0 1 0 2z"/></svg>; }
export function MobileHorizontalDotsMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M16 8c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2zm-6 0c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2zm-6 0c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z"/></svg>; }
export function MobileMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 1.5c0-.8.7-1.5 1.5-1.5h11c.8 0 1.5.7 1.5 1.5v17c0 .8-.7 1.5-1.5 1.5h-11c-.8 0-1.5-.7-1.5-1.5v-17zm2 .5h10v14h-10v-14zm4 15a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2z"/></svg>; }
export function MobilePlusMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17 9h-6v-6a1 1 0 0 0-2 0v6h-6a1 1 0 0 0 0 2h6v6a1 1 0 1 0 2 0v-6h6a1 1 0 0 0 0-2z"/></svg>; }
export function MobileVerticalDotsMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0 2c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2zm0 6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z"/></svg>; }
export function MonerisMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3.541 0a1.5 1.5 0 0 0-1.5 1.54l.013.46h15.892l.012-.46a1.5 1.5 0 0 0-1.5-1.54h-12.916z"/><path fillRule="evenodd" d="M4.424 20a1.5 1.5 0 0 1-1.498-1.422l-.76-14.578h15.669l-.76 14.578a1.5 1.5 0 0 1-1.5 1.422h-11.151zm.576-14h10v4h-10v-4zm0 6h2v2h-2v-2zm0 4h2v2h-2v-2zm6-4h-2v2h2v-2zm-2 4h2v2h-2v-2zm6-4h-2v2h2v-2zm-2 4h2v2h-2v-2z"/></svg>; }
export function NatureMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15.651 12.851c-2.13 1.94-5.17 2.91-9.07 2.91-.91 0-1.87-.04-2.88-.17-.38.86-.6 1.71-.6 2.51 0 .55-.45 1-1 1s-1-.45-1-1c0-.24.01-.48.04-.72.02-.13.03-.26.06-.39.18-1.13.64-2.27 1.29-3.39 2.32-4.02 9.3-8.68 12.61-9.5-4.76-.03-12 3-13.91 6.93 0-2.89 2.3-6.42 5.46-8.38 4.54-2.82 10.47-1.14 12.06 0 .23.16.37.42.38.7.08 2.31-.25 6.62-3.44 9.5z"/></svg>; }
export function NavigationMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 0c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm-5 15 2.27-6.31 4.04 4.04-6.31 2.27zm7.73-3.69-4.04-4.04 6.31-2.27-2.27 6.31z"/></svg>; }
export function NoteMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M11.379 0a1.5 1.5 0 0 1 1.06.44l4.122 4.12a1.5 1.5 0 0 1 .439 1.062v12.878a1.5 1.5 0 0 1-1.5 1.5h-11a1.5 1.5 0 0 1-1.5-1.5v-17a1.5 1.5 0 0 1 1.5-1.5h6.879zm-5.379 5h4v2h-4v-2zm8 4v2h-8v-2h8zm-8 6v-2h7v2h-7z"/></svg>; }
export function NoteMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 11h8v-2h-8v2zm0 4h8v-2h-8v2zm0-8h4v-2h-4v2zm6-5h-6.5a1.5 1.5 0 0 0-1.5 1.5v13a1.5 1.5 0 0 0 1.5 1.5h9a1.5 1.5 0 0 0 1.5-1.5v-10.5l-4-4z"/></svg>; }
export function NotificationMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 0a1 1 0 0 1 1 1v2.032l-.001.021-.002.03a6.002 6.002 0 0 1 5.003 5.917c0 3.093.625 4.312 1.599 6.21l.034.068c.17.33-.07.722-.442.722h-14.382a.496.496 0 0 1-.442-.722l.034-.068c.974-1.898 1.599-3.117 1.599-6.21a6.002 6.002 0 0 1 5.003-5.918l-.002-.04a.835.835 0 0 1-.001-.042v-2a1 1 0 0 1 1-1zm2 18a2 2 0 0 1-4 0h4z"/></svg>; }
export function OnlineStoreMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m1.791 2.253-.597 3.583a1 1 0 0 0 .986 1.164h.893a1.5 1.5 0 0 0 1.342-.83l.585-1.17.585 1.17a1.5 1.5 0 0 0 1.342.83h1.146a1.5 1.5 0 0 0 1.342-.83l.585-1.17.585 1.17a1.5 1.5 0 0 0 1.342.83h1.146a1.5 1.5 0 0 0 1.342-.83l.585-1.17.585 1.17a1.5 1.5 0 0 0 1.342.83h.893a1 1 0 0 0 .986-1.164l-.597-3.583a1.5 1.5 0 0 0-1.48-1.253h-13.458a1.5 1.5 0 0 0-1.48 1.253zm2.209 16.247a1.5 1.5 0 0 1 1.5-1.5h2.5v-3h4v3h2.5a1.5 1.5 0 0 1 1.5 1.5v.5h-12v-.5z"/><path d="M2 9h2v4h12v-4h2v4.5a1.5 1.5 0 0 1-1.5 1.5h-13a1.5 1.5 0 0 1-1.5-1.5v-4.5z"/></svg>; }
export function OnlineStoreMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5 3a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2a1 1 0 1 1 0 2h-1a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2h-1a1 1 0 1 1 0-2h2a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2h-10Zm1.986 3.836a1 1 0 0 0-1.972.329c.223 1.339 1.565 3.835 4.986 3.835 3.42 0 4.763-2.496 4.986-3.835a1 1 0 1 0-1.972-.33c-.11.662-.835 2.165-3.014 2.165-2.18 0-2.903-1.504-3.014-2.164Z"/></svg>; }
export function OrdersMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 1a1 1 0 1 0-2 0v7.586l-1.293-1.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l3-3a1 1 0 0 0-1.414-1.414l-1.293 1.293v-7.586z"/><path d="M3 14v-11h4v-2h-4.5a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-15a1.5 1.5 0 0 0-1.5-1.5h-4.5v2h4v11h-3.5c-.775 0-1.388.662-1.926 1.244l-.11.12a1.994 1.994 0 0 1-1.464.636 1.994 1.994 0 0 1-1.463-.637l-.111-.12c-.538-.579-1.151-1.243-1.926-1.243h-3.5z"/></svg>; }
export function OrdersMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3.735 5.507a3 3 0 0 1 2.96-2.507h6.61a3 3 0 0 1 2.96 2.507l.735 4.493v5a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2v-5l.735-4.493Zm2.96-.507a1 1 0 0 0-.987.836l-.708 4.164v1h1.394a3 3 0 0 1 1.665.504l.832.555a2 2 0 0 0 2.218 0l.832-.555a3 3 0 0 1 1.666-.504h1.393v-1l-.708-4.164a1 1 0 0 0-.986-.836h-6.612Z"/></svg>; }
export function OrderStatusMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 7a1 1 0 1 1 0-2h2.586l2.707-2.707a.997.997 0 0 1 1.655.391l1.49 4.465 1.855-1.856a.996.996 0 0 1 .707-.293h3a1 1 0 1 1 0 2h-2.586l-2.707 2.707a.998.998 0 0 1-1.655-.391l-1.49-4.465-1.855 1.856a.996.996 0 0 1-.707.293h-3zm14 2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-14a1 1 0 0 1-1-1v-7a1 1 0 1 1 2 0v3h2.382c.379 0 .725.214.894.553l.724 1.447h4l.724-1.447a.998.998 0 0 1 .894-.553h2.382v-3a1 1 0 0 1 1-1z"/><path d="M2 9h2v1h-2zm14 0h2v1h-2z"/></svg>; }
export function OutgoingMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 10a1.5 1.5 0 0 1 1.5 1.5v7a1.5 1.5 0 0 1-1.5 1.5h-15a1.5 1.5 0 0 1-1.5-1.5v-7c0-.829.67-1.5 1.5-1.5h4.5v2h-4v6h14v-6h-4v-2h4.5zm-7.5 6a1 1 0 0 1-1-1v-7c0-2.757 2.243-5 5-5h2.586l-1.293-1.293a1 1 0 0 1 1.414-1.414l3 3a.998.998 0 0 1 0 1.414l-3 3a.997.997 0 0 1-1.631-.324 1 1 0 0 1 .217-1.09l1.293-1.293h-2.586c-1.654 0-3 1.346-3 3v7a1 1 0 0 1-1 1z"/></svg>; }
export function PackageMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M19 5h-8v-3h5.227c.382-.001.736.162.928.429l1.845 2.571zm-15.227-3h5.227v3h-8l1.845-2.571c.192-.267.546-.43.928-.429zm-2.773 5v10.5a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-10.5h-18zm2 2h6v4h-6v-4z"/></svg>; }
export function PageDownMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M11.379 0a1.5 1.5 0 0 1 1.06.44l4.122 4.12a1.5 1.5 0 0 1 .439 1.062v12.878a1.5 1.5 0 0 1-1.5 1.5h-11a1.5 1.5 0 0 1-1.5-1.5v-17a1.5 1.5 0 0 1 1.5-1.5h6.879zm-1.379 6a1 1 0 0 1 1 1v3.586l1.293-1.293a1 1 0 1 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414l1.293 1.293v-3.586a1 1 0 0 1 1-1z"/></svg>; }
export function PageMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M12.44.44a1.5 1.5 0 0 0-1.062-.44h-6.878a1.5 1.5 0 0 0-1.5 1.5v17a1.5 1.5 0 0 0 1.5 1.5h11a1.5 1.5 0 0 0 1.5-1.5v-12.879a1.5 1.5 0 0 0-.44-1.06l-4.12-4.122z"/></svg>; }
export function PageMinusMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11.379 0a1.5 1.5 0 0 1 1.06.44l4.122 4.12a1.5 1.5 0 0 1 .439 1.062v12.878a1.5 1.5 0 0 1-1.5 1.5h-11a1.5 1.5 0 0 1-1.5-1.5v-17a1.5 1.5 0 0 1 1.5-1.5h6.879zm-4.379 9a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2h-6z"/></svg>; }
export function PagePlusMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M11.379 0a1.5 1.5 0 0 1 1.06.44l4.122 4.12a1.5 1.5 0 0 1 .439 1.062v12.878a1.5 1.5 0 0 1-1.5 1.5h-11a1.5 1.5 0 0 1-1.5-1.5v-17a1.5 1.5 0 0 1 1.5-1.5h6.879zm-1.379 14a1 1 0 0 1-1-1v-2h-2a1 1 0 1 1 0-2h2v-2a1 1 0 1 1 2 0v2h2a1 1 0 1 1 0 2h-2v2a1 1 0 0 1-1 1z"/></svg>; }
export function PageUpMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M11.379 0a1.5 1.5 0 0 1 1.06.44l4.122 4.12a1.5 1.5 0 0 1 .439 1.062v12.878a1.5 1.5 0 0 1-1.5 1.5h-11a1.5 1.5 0 0 1-1.5-1.5v-17a1.5 1.5 0 0 1 1.5-1.5h6.879zm2.328 10.707a1 1 0 0 1-1.414 0l-1.293-1.293v3.586a1 1 0 1 1-2 0v-3.586l-1.293 1.293a1 1 0 0 1-1.414-1.414l3-3a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1 0 1.414z"/></svg>; }
export function PaginationEndMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M14 4a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0v-10a1 1 0 0 1 1-1zm-7.219.376 4 5a1 1 0 0 1 0 1.249l-4 5a1 1 0 1 1-1.562-1.25l3.5-4.374-3.5-4.376a1 1 0 1 1 1.562-1.25z"/></svg>; }
export function PaginationStartMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6 4a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0v-10a1 1 0 0 1 1-1zm7.219.376a1 1 0 1 1 1.562 1.249l-3.501 4.375 3.5 4.375a1 1 0 1 1-1.562 1.249l-4-5a1 1 0 0 1 0-1.25l4-5z"/></svg>; }
export function PaintBrushMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M20 18c0-.79-1.018-2.488-1.601-3.397a.47.47 0 0 0-.798 0c-.583.909-1.601 2.607-1.601 3.397 0 1.107.893 2 2 2s2-.893 2-2z"/><path d="m11 0-5.518 5.5 9.018 9.018 5.5-5.518-5-5-3 1 1-3-2-2zm-7.512 7.488.5-.498 9.022 9.023-.538.541c-.575.575-1.493.569-2.173.124-1.053-.69-2.146-1.132-3.248-.913a4.72 4.72 0 0 0-.378.092c-.506.145-.93.477-1.301.85-.374.374-.656.848-.937 1.321-.383.643-.763 1.284-1.373 1.667a2 2 0 0 1-2.77-2.738c.383-.624 1.035-1.012 1.69-1.402.475-.283.952-.567 1.328-.943.398-.399.753-.855.89-1.402l.02-.08c.27-1.143-.134-2.351-.858-3.466-.443-.681-.45-1.6.126-2.176z"/></svg>; }
export function PauseCircleMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M0 10c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10-10 4.486-10 10zm7-3a1 1 0 0 1 2 0v6a1 1 0 1 1-2 0v-6zm5-1a1 1 0 0 0-1 1v6a1 1 0 1 0 2 0v-6a1 1 0 0 0-1-1z"/></svg>; }
export function PauseMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6 0a1 1 0 0 0-1 1v18a1 1 0 1 0 2 0v-18a1 1 0 0 0-1-1zm8 0a1 1 0 0 0-1 1v18a1 1 0 1 0 2 0v-18a1 1 0 0 0-1-1z"/></svg>; }
export function PauseMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M11 13a1 1 0 1 0 2 0v-6a1 1 0 1 0-2 0v6zm-4 0a1 1 0 1 0 2 0v-6a1 1 0 0 0-2 0v6zm3-11a8 8 0 1 0 0 16 8 8 0 0 0 0-16z"/></svg>; }
export function PaymentsMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m4.002 5-3.452 1.76a.985.985 0 0 0-.549.9v10.34c0 .51.38.93.879.99l8.023 1a.997.997 0 0 0 1.068-.67l1.766-5.32h3.273c1.297 0 2.405-.84 2.814-2h.679c.828 0 1.497-.67 1.497-1.5v-5.5h-15.998zm.998 5h10.01c.27 0 .509.1.709.31.19.18.289.42.289.69 0 .55-.449 1-.998 1h-3.992c-.429 0-.808.28-.948.68l-1.736 5.23-6.338-.79v-8.85l2.006-1v1.74a1 1 0 0 0 .998.99zm13.503-9h-13.004c-.828 0-1.497.67-1.497 1.5v.5h15.998v-.5c0-.83-.669-1.5-1.497-1.5z"/></svg>; }
export function PhoneInMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M16.094 9.1h-3.994a.996.996 0 0 1-.998-.998v-3.996a.997.997 0 0 1 1.705-.708.998.998 0 0 1 .293.707v1.585l3.335-3.334a.999.999 0 0 1 1.413 1.411l-3.336 3.334h1.584a.999.999 0 0 1 0 1.999zm-8.752-4.794.534 2.67a1.495 1.495 0 0 1-.802 1.634l-.975.488c0 2 3 5 5 5l.488-.975a1.5 1.5 0 0 1 1.636-.8l2.67.534a1.5 1.5 0 0 1 1.206 1.471v3.235c0 .859-.725 1.572-1.584 1.535-8.915-.388-13.954-4.251-14.414-14.412-.039-.859.677-1.586 1.537-1.586h3.233a1.5 1.5 0 0 1 1.471 1.206z"/></svg>; }
export function PhoneMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m7.876 6.976-.534-2.67a1.5 1.5 0 0 0-1.471-1.206h-3.233c-.86 0-1.576.727-1.537 1.586.461 10.161 5.499 14.025 14.415 14.413.859.037 1.584-.676 1.584-1.535v-3.235a1.5 1.5 0 0 0-1.206-1.471l-2.67-.534a1.5 1.5 0 0 0-1.636.8l-.488.975c-2 0-5-3-5-5l.975-.488c.606-.302.934-.972.801-1.635z"/></svg>; }
export function PhoneOutMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M13.105 2.1h3.995c.553 0 1 .446 1 .999v3.995a.997.997 0 0 1-1.705.708.998.998 0 0 1-.293-.707v-1.584l-3.336 3.335a.999.999 0 0 1-1.413-1.411l3.337-3.335h-1.584a.999.999 0 0 1-.705-1.707.993.993 0 0 1 .705-.293zm-5.763 2.206.534 2.67a1.495 1.495 0 0 1-.802 1.634l-.975.488c0 2 3 5 5 5l.488-.975a1.5 1.5 0 0 1 1.636-.8l2.67.534a1.5 1.5 0 0 1 1.206 1.471v3.235c0 .859-.725 1.572-1.584 1.535-8.915-.388-13.954-4.251-14.414-14.412-.039-.859.677-1.586 1.537-1.586h3.233a1.5 1.5 0 0 1 1.471 1.206z"/></svg>; }
export function PinMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m13.707.295 6 6a1.003 1.003 0 0 1-.066 1.475l-5.65 4.706c-.154 4.105-2.292 5.294-2.544 5.42a1 1 0 0 1-1.154-.187l-3.293-3.293-5.293 5.293a1 1 0 0 1-1.414-1.414l5.293-5.293-3.293-3.293a1.001 1.001 0 0 1-.188-1.155c.126-.252 1.316-2.389 5.419-2.544l4.707-5.649a.998.998 0 0 1 .724-.358.966.966 0 0 1 .752.292z"/></svg>; }
export function PinMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m17.7 7-4.7-4.7c-.2-.2-.401-.3-.7-.3-.3 0-.6.1-.701.4l-3.599 4.3c-3.3.1-4.2 1.9-4.3 2.1-.2.4-.2.8.2 1.1l2.4 2.4-4 4c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3l4-4 2.399 2.4c.201.2.401.3.701.3.2 0 .299 0 .4-.1.2-.1 1.899-1.1 2.1-4.3l4.299-3.6c.301-.1.401-.4.401-.7 0-.3-.1-.5-.3-.7z"/></svg>; }
export function PlayCircleMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 20c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm-2-13.138v6.276a.5.5 0 0 0 .748.434l5.492-3.138a.5.5 0 0 0 0-.868l-5.492-3.139a.5.5 0 0 0-.748.435z"/></svg>; }
export function PlayMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.333 1c-.693 0-1.333.555-1.333 1.332v15.335a1.328 1.328 0 0 0 2.014 1.143l12.787-7.665a1.33 1.33 0 0 0 0-2.283l-12.786-7.67a1.326 1.326 0 0 0-.682-.192zm.667 2.515 10.815 6.488-10.815 6.482v-12.97z"/></svg>; }
export function PlayMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M16 10a.997.997 0 0 1-.485.857l-10 6a1.004 1.004 0 0 1-1.008.013 1.001 1.001 0 0 1-.507-.87v-12a1.001 1.001 0 0 1 1.515-.857l10 6c.301.18.485.505.485.857z"/></svg>; }
export function PlusMinor() { return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 4a1 1 0 0 0-1 1v4h-4a1 1 0 1 0 0 2h4v4a1 1 0 1 0 2 0v-4h4a1 1 0 1 0 0-2h-4v-4a1 1 0 0 0-1-1Z"/></svg>; }
export function PointOfSaleMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3.945 2.438a.5.5 0 0 1 .496-.438h11.119a.5.5 0 0 1 .496.438l.875 7a.5.5 0 0 1-.496.562h-3.245c.198.576.577 1.269 1.24 2h4.07a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-17a1.5 1.5 0 0 1-1.5-1.5v-3a1.5 1.5 0 0 1 1.5-1.5h4.072c.663-.731 1.041-1.424 1.24-2h-3.246a.5.5 0 0 1-.496-.562l.875-7zm1.32 5.562.5-4h8.47l.5 4h-9.47zm5.848 2a6.6 6.6 0 0 0 .824 2h-3.874a6.601 6.601 0 0 0 .824-2h2.226zm-1.113 4a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/></svg>; }
export function PopularMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M12.984 18a.999.999 0 0 1-.94-.658l-3.077-8.454-2.05 5.465a1.001 1.001 0 0 1-1.794.163l-2.188-3.646-1.124 1.685a1.002 1.002 0 0 1-1.665-1.11l2.001-3a.966.966 0 0 1 .856-.444.998.998 0 0 1 .834.485l1.936 3.223 2.271-6.059a1 1 0 0 1 .938-.65h.005a1.001 1.001 0 0 1 .935.658l2.948 8.1 3.154-11.033c.12-.42.502-.714.94-.724.43-.026.834.268.97.683l2.002 6a1 1 0 1 1-1.897.631l-.98-2.932-3.112 10.888a1 1 0 0 1-.928.726h-.035"/></svg>; }
export function PriceLookupMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 2a1 1 0 0 0 0 2h8a1 1 0 1 0 0-2h-8zm0 4a1 1 0 0 0 0 2h4a4.977 4.977 0 0 0-.901 2.005 1.013 1.013 0 0 0-.099-.005h-3a1 1 0 1 0 0 2h3c.033 0 .066-.002.099-.005a5.002 5.002 0 0 0 7.658 3.177l2.536 2.535a1 1 0 0 0 1.414-1.414l-2.535-2.536a5 5 0 0 0-4.172-7.757h-8zm8 2a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"/></svg>; }
export function PrintMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4 2.5a1.5 1.5 0 0 1 1.5-1.5h9a1.5 1.5 0 0 1 1.5 1.5v2.5h1.5a1.5 1.5 0 0 1 1.5 1.5v6a1.5 1.5 0 0 1-1.5 1.5h-1.5v4a1 1 0 0 1-1 1h-10a1 1 0 0 1-1-1v-4h-1.5a1.5 1.5 0 0 1-1.5-1.5v-6a1.5 1.5 0 0 1 1.5-1.5h1.5v-2.5Zm10 14.5v-5h-8v5h8Zm3-8a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-3-6h-8v2h8v-2Z"/></svg>; }
export function PrintMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M14 11h2v-2h-2v2zm-7-4h6v-3h-6v3zm0 9h6v-2h-6v2zm8-9v-3.5a1.5 1.5 0 0 0-1.5-1.5h-7a1.5 1.5 0 0 0-1.5 1.5v3.5h-1.5a1.5 1.5 0 0 0-1.5 1.5v6a1.5 1.5 0 0 0 1.5 1.5h1.5v.5a1.5 1.5 0 0 0 1.5 1.5h7a1.5 1.5 0 0 0 1.5-1.5v-.5h1.5a1.5 1.5 0 0 0 1.5-1.5v-6a1.5 1.5 0 0 0-1.5-1.5h-1.5z"/></svg>; }
export function ProductReturnsMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 12a6 6 0 0 0 6 6h9a1 1 0 0 0 0-2h-9a4 4 0 1 1 0-8h6.526l-2.233 2.293a1 1 0 0 0 1.414 1.414l4-4a1 1 0 0 0 0-1.414l-4-4a1 1 0 1 0-1.414 1.414l2.233 2.293h-6.526a6 6 0 0 0-6 6Z"/></svg>; }
export function ProductsMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.293 1.293a1 1 0 0 1 .707-.293h7a1 1 0 0 1 1 1v7a1 1 0 0 1-.293.707l-9 9a1 1 0 0 1-1.414 0l-7-7a1 1 0 0 1 0-1.414l9-9zm5.207 4.707a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/></svg>; }
export function ProductsMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.408 3h5.592a1 1 0 0 1 1 1v5.592a2 2 0 0 1-.57 1.399l-5.162 5.277a2.5 2.5 0 0 1-3.536 0l-4-4a2.5 2.5 0 0 1 0-3.536l.008-.008 5.27-5.154a2 2 0 0 1 1.397-.57Zm3.092 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"/></svg>; }
export function ProfileMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 0c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm6.24 15a7.99 7.99 0 0 1-12.48 0 7.99 7.99 0 0 1 12.48 0zm-6.24-5a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/></svg>; }
export function ProfileMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 15c-1.631 0-3.064-.792-3.978-2 .914-1.208 2.347-2 3.978-2 1.631 0 3.064.792 3.978 2-.914 1.208-2.347 2-3.978 2zm0-10c1.104 0 2 .897 2 2s-.896 2-2 2-2-.897-2-2 .896-2 2-2zm0-3c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8z"/></svg>; }
export function PromoteMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 3a1.5 1.5 0 0 0-1.5 1.5v11a1.5 1.5 0 0 0 1.5 1.5h11a1.5 1.5 0 0 0 1.5-1.5v-3.5h-2v3h-10v-10h3v-2h-3.5zm12.5 1a1 1 0 0 0-1-1h-5a1 1 0 1 0 0 2h2.586l-5.293 5.293a.999.999 0 1 0 1.414 1.414l5.293-5.293v2.586a1 1 0 1 0 2 0v-5z"/></svg>; }
export function QuestionMarkInverseMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm-7.071.929a10 10 0 1 1 14.141 14.141 10 10 0 0 1-14.14-14.14z"/><path d="M11.126 13.002h-2.136v-1.142c.01-1.966.492-2.254 1.374-2.782.093-.056.19-.114.293-.178.73-.459 1.292-1.038 1.292-1.883 0-.948-.743-1.564-1.666-1.564-.852 0-1.657.398-1.712 1.533h-2.266c.06-2.294 1.877-3.487 3.99-3.487 2.306 0 3.894 1.447 3.894 3.488 0 1.382-.695 2.288-1.806 2.952l-.237.144c-.79.475-1.009.607-1.02 1.777v1.142zm.17 2.012a1.344 1.344 0 0 1-1.327 1.328 1.32 1.32 0 0 1-1.227-1.834 1.318 1.318 0 0 1 1.227-.81c.712 0 1.322.592 1.328 1.316h-.001z"/></svg>; }
export function QuestionMarkInverseMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 12h-2v-.148c0-.876.306-1.499 1-1.852.385-.195 1-.568 1-1a1.001 1.001 0 0 0-2 0h-2c0-1.654 1.346-3 3-3s3 1 3 3-2 2.165-2 3zm-2 3h2v-2h-2v2z"/><path d="M10 4a6 6 0 1 0 0 12 6 6 0 0 0 0-12zm-8 6a8 8 0 1 1 16 0 8 8 0 0 1-16 0z"/></svg>; }
export function QuestionMarkMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M0 10c0-5.522 4.478-10 10-10 5.523 0 10 4.478 10 10 0 5.523-4.477 10-10 10-5.522 0-10-4.477-10-10zm11.125 2.002h-2.136v-.141c.01-1.966.492-2.254 1.374-2.782.093-.056.19-.114.293-.178.73-.459 1.292-1.038 1.292-1.883 0-.948-.743-1.564-1.666-1.564-.851 0-1.657.398-1.712 1.533h-2.266c.06-2.294 1.876-3.487 3.99-3.487 2.306 0 3.894 1.447 3.894 3.488 0 1.382-.695 2.288-1.805 2.952l-.238.144c-.79.475-1.009.607-1.02 1.777v.139zm.17 3.012a1.344 1.344 0 0 1-1.327 1.328 1.32 1.32 0 0 1-1.328-1.328 1.318 1.318 0 0 1 1.328-1.316c.712 0 1.322.592 1.328 1.316z"/></svg>; }
export function QuestionMarkMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M11 11h-2v-.148c0-.876.306-1.499 1-1.852.385-.195 1-.568 1-1a1.001 1.001 0 0 0-2 0h-2c0-1.654 1.346-3 3-3s3 1 3 3-2 2.165-2 3zm-2 4h2v-2h-2v2zm1-13a8 8 0 1 0 0 16 8 8 0 0 0 0-16z"/></svg>; }
export function QuickSaleMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5 0a1 1 0 0 0 0 2h1v1a1 1 0 0 0 .917.997l10.943.911-.727 5.092h-10.133a1 1 0 0 0-1 1v3.17a3.001 3.001 0 1 0 3.83 1.83h3.34a3 3 0 1 0 2.83-2h-8v-2h10a1 1 0 0 0 .99-.859l1-7a1 1 0 0 0-.907-1.138l-11.083-.923v-1.08a1 1 0 0 0-1-1h-2zm11 16a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/><path d="M0 3a1 1 0 0 1 1-1h1a1 1 0 0 1 0 2h-1a1 1 0 0 1-1-1zm1 3a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2h-6zm-1 5a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1zm1 3a1 1 0 1 0 0 2h1a1 1 0 1 0 0-2h-1z"/></svg>; }
export function ReadTimeMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 4h9v-.5a1.5 1.5 0 0 0-1.5-1.5h-7.5a3 3 0 0 0-3 3v11.5a1.5 1.5 0 0 0 1.5 1.5h9a1.5 1.5 0 0 0 1.5-1.5v-9a1.5 1.5 0 0 0-1.5-1.5h-.5v5l-2-2-2 2v-5h-4v-1a1 1 0 0 1 1-1z"/></svg>; }
export function ReceiptMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M15.5 0h-11c-.828 0-1.5.677-1.5 1.513v18.207a.2.2 0 0 0 .334.15l1.664-1.515a.497.497 0 0 1 .67 0l1.664 1.514c.19.173.48.173.67 0l1.663-1.514a.497.497 0 0 1 .67 0l1.663 1.514c.19.173.48.173.67 0l1.664-1.514a.497.497 0 0 1 .67 0l1.664 1.515a.2.2 0 0 0 .334-.15v-18.207c0-.836-.672-1.513-1.5-1.513zm-.5 5v-1h-10v2h10v-1zm0 3v2h-10v-2h10zm-4 5v-1h-6v2h6v-1z"/></svg>; }
export function RecentSearchesMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8 2c-3.309 0-6 2.691-6 6s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6zm6.312 10.897 5.395 5.396a1 1 0 1 1-1.414 1.414l-5.396-5.395a7.954 7.954 0 0 1-4.897 1.688c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8a7.946 7.946 0 0 1-1.688 4.897zm-5.312-7.897a1 1 0 1 0-2 0v3a1 1 0 0 0 .293.707l2 2a1 1 0 0 0 1.414-1.414l-1.707-1.707v-2.586z"/></svg>; }
export function RedoMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M16.512 5c-2.149-1.934-3.99-3-6.512-3a8 8 0 0 0-8 8 8 8 0 0 0 8 8 1 1 0 1 1 0 2c-5.523 0-10-4.477-10-10s4.477-10 10-10c3.297 0 5.644 1.496 8 3.647v-2.647a1 1 0 1 1 2 0v5a.998.998 0 0 1-.29.705l-.005.004a.997.997 0 0 1-.705.291h-5a1 1 0 1 1 0-2h2.512z"/></svg>; }
export function ReferralCodeMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4 3h-1v1h-2v-1.5a1.5 1.5 0 0 1 1.5-1.5h1.5v2Z"/><path d="M16.707 6.255a1 1 0 1 0-1.414 1.414l1.293 1.292h-4.586a1 1 0 1 0 0 2h4.586l-1.293 1.293a1 1 0 1 0 1.414 1.414l3-3a1.001 1.001 0 0 0 0-1.415l-3-3v.002Z"/><path d="M7 6h2v8h-2v-8Z"/><path d="M19 18a1 1 0 0 1-1 1h-2v-2h1v-1h2v2Z"/><path d="M2 19a1 1 0 0 1-1-1v-2h2v1h1v2h-2Z"/><path d="M17 3v1h2v-1.5a1.5 1.5 0 0 0-1.5-1.5h-1.5v2h1Z"/><path d="M5 6h-4v8h4v-8Z"/></svg>; }
export function ReferralMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M0 10c0-5.514 4.486-10 10-10s10 4.486 10 10-4.486 10-10 10a9.944 9.944 0 0 1-4.924-1.305l-3.04 1.204c-1.209.459-2.393-.725-1.935-1.934l1.203-3.041a9.962 9.962 0 0 1-1.304-4.924Zm15.7-.645a1 1 0 0 1 0 1.4l-3 3a1 1 0 0 1-1.4 0 1 1 0 0 1 0-1.4l1.29-1.3h-6.59a1 1 0 0 1 0-2h6.59l-1.3-1.3a1 1 0 1 1 1.42-1.4l3 3h-.01Z"/></svg>; }
export function RefreshMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M19.707 3.293a1 1 0 0 1 0 1.414l-3 3a.997.997 0 0 1-1.631-.324 1 1 0 0 1 .217-1.09l1.293-1.293h-9.586c-2.206 0-4 1.794-4 4a1 1 0 0 1-2 0c0-3.309 2.691-6 6-6h9.586l-1.293-1.293a1 1 0 1 1 1.414-1.414l3 3zm-2.707 6.707a1 1 0 0 1 1 1c0 3.31-2.69 6-6 6h-8.586l1.293 1.293a1 1 0 1 1-1.414 1.414l-3-3a1 1 0 0 1 0-1.414l3-3a1 1 0 1 1 1.414 1.414l-1.293 1.293h8.586c2.206 0 4-1.794 4-4a1 1 0 0 1 1-1z"/></svg>; }
export function RefreshMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17 11a1 1 0 0 1 1 1c0 1.654-1.346 3-3 3h-9.586l1.293 1.293a.999.999 0 1 1-1.414 1.414l-3-3a.999.999 0 0 1 0-1.414l3-3a.999.999 0 1 1 1.414 1.414l-1.293 1.293h9.586c.552 0 1-.449 1-1a1 1 0 0 1 1-1zm-14-2a1 1 0 0 1-1-1c0-1.654 1.346-3 3-3h9.586l-1.293-1.293a.999.999 0 1 1 1.414-1.414l3 3a.999.999 0 0 1 0 1.414l-3 3a.997.997 0 0 1-1.414 0 .999.999 0 0 1 0-1.414l1.293-1.293h-9.586c-.552 0-1 .449-1 1a1 1 0 0 1-1 1z"/></svg>; }
export function RefundMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 0h11c.828 0 1.5.677 1.5 1.512v18.21a.2.2 0 0 1-.334.149l-1.664-1.515a.497.497 0 0 0-.67 0l-1.664 1.514a.497.497 0 0 1-.67 0l-1.663-1.514a.497.497 0 0 0-.67 0l-1.663 1.514a.497.497 0 0 1-.67 0l-1.664-1.514a.497.497 0 0 0-.67 0l-1.664 1.515a.2.2 0 0 1-.334-.15v-18.209c0-.835.672-1.512 1.5-1.512zm5.5 7.596h-1.585l1.293-1.293a1 1 0 0 0-1.414-1.414l-3 3a1 1 0 0 0 0 1.414l3 3a1 1 0 1 0 1.414-1.414l-1.294-1.293h1.586c2.069 0 2.999 1.116 2.999 3a1 1 0 1 0 2 0c0-2.916-1.736-5-5-5z"/></svg>; }
export function RefundMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15 12h-2v-1c0-.551-.449-1-1-1h-2.586l.586.586a1 1 0 1 1-1.414 1.414l-2.293-2.293a1 1 0 0 1 0-1.414l2.293-2.293a1 1 0 1 1 1.414 1.414l-.586.586h2.586c1.654 0 3 1.346 3 3v1zm2-8.5a1.5 1.5 0 0 0-1.5-1.5h-11a1.5 1.5 0 0 0-1.5 1.5v13.5a1 1 0 0 0 1.3.954c.18-.057.317-.195.439-.338l1.121-1.321 1.349 1.399a1.002 1.002 0 0 0 1.415.026l1.364-1.318 1.305 1.305a.997.997 0 0 0 1.414 0l1.42-1.42 1.136 1.332c.12.141.257.277.434.334a1 1 0 0 0 1.303-.953v-13.5z"/></svg>; }
export function RemoveProductMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M18 1h-7a1 1 0 0 0-.71.29l-9 9a1 1 0 0 0 0 1.42l7 7a1 1 0 0 0 1.42 0l.29-.29v-2.42a6 6 0 0 1 6-6h2.42l.29-.29a1 1 0 0 0 .29-.71v-7a1 1 0 0 0-1-1zm-2.5 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/><path d="M19.707 14.293a1 1 0 0 0-.707-.293h-6a1 1 0 0 0 0 2h6a1 1 0 0 0 .707-1.707z"/></svg>; }
export function RepeatOrderMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6 8a.992.992 0 0 1 .294-.707l2.999-3a1 1 0 1 1 1.414 1.414l-1.293 1.293h6.086a2.5 2.5 0 0 0 0-5 1 1 0 1 1 0-2c2.481 0 4.5 2.018 4.5 4.5 0 2.481-2.019 4.5-4.5 4.5h-6.086l1.293 1.293a.999.999 0 0 1-.707 1.709.997.997 0 0 1-.706-.294l-3-3a.99.99 0 0 1-.217-.326.992.992 0 0 1-.077-.382zm-3-5v11h3.5c.775 0 1.388.662 1.926 1.244l.11.12a2 2 0 0 0 2.927-.001l.11-.119h.001c.538-.582 1.151-1.244 1.926-1.244h3.5v-2a1 1 0 1 1 2 0v5.5a1.5 1.5 0 0 1-1.5 1.5h-15a1.5 1.5 0 0 1-1.5-1.5v-15a1.5 1.5 0 0 1 1.5-1.5h4.5a1 1 0 0 1 0 2h-4z"/></svg>; }
export function ReplaceMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 0a1.5 1.5 0 0 0-1.5 1.5v5a1.5 1.5 0 0 0 1.5 1.5h5a1.5 1.5 0 0 0 1.5-1.5v-5a1.5 1.5 0 0 0-1.5-1.5h-5zm11.793 7.707a1 1 0 1 0 1.414-1.414l-1.293-1.293h4.086a.5.5 0 0 1 .5.5v3.5a1 1 0 1 0 2 0v-3.5a2.5 2.5 0 0 0-2.5-2.5h-4.086l1.293-1.293a1 1 0 0 0-1.414-1.414l-3 3a1 1 0 0 0 0 1.414l3 3zm-6.586 4.586a1 1 0 0 0-1.414 1.414l1.293 1.293h-4.086a.5.5 0 0 1-.5-.5v-3.5a1 1 0 1 0-2 0v3.5a2.5 2.5 0 0 0 2.5 2.5h4.086l-1.293 1.293a1 1 0 1 0 1.414 1.414l3-3a1 1 0 0 0 0-1.414l-3-3zm13.293 6.207a1.5 1.5 0 0 1-1.5 1.5h-5a1.5 1.5 0 0 1-1.5-1.5v-5a1.5 1.5 0 0 1 1.5-1.5h5a1.5 1.5 0 0 1 1.5 1.5v5z"/></svg>; }
export function ReplayMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M13.635 7.666c0 .272.106.528.298.72a1.044 1.044 0 0 0 1.441 0l2.326-2.327a1.013 1.013 0 0 0 0-1.442l-2.326-2.327a1.042 1.042 0 0 0-1.44 0 1.008 1.008 0 0 0-.299.72c0 .272.106.528.3.72l.588.59h-5.687c-3.77-.002-6.836 3.068-6.836 6.838 0 3.775 3.066 6.842 6.836 6.842 3.346 0 6.252-2.495 6.757-5.8.002-.009.016-.115.016-.194l.002-.352a1.02 1.02 0 0 0-1.018-1.019 1.02 1.02 0 0 0-1.019 1.019v.19c0 .318-.117.658-.139.728-.628 1.97-2.51 3.39-4.599 3.39a4.806 4.806 0 0 1-4.8-4.802 4.807 4.807 0 0 1 4.8-4.803h5.687l-.589.589a1.012 1.012 0 0 0-.299.72z"/></svg>; }
export function ReportMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.5 3a1.5 1.5 0 0 0-1.5 1.5v11a1.5 1.5 0 0 0 1.5 1.5h11a1.5 1.5 0 0 0 1.5-1.5v-11a1.5 1.5 0 0 0-1.5-1.5h-11zm10.5 12h-2v-6h2v6zm-6 0h2v-9h-2v9zm-2 0h-2v-3h2v3z"/></svg>; }
export function ReportsMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M18 5a1 1 0 0 0-.293-.707l-4-4a1.002 1.002 0 0 0-.707-.293h-9.5a1.5 1.5 0 0 0-1.5 1.5v4.5a1 1 0 1 0 2 0v-4h8.586l3.414 3.414v12.586h-12v-1a1 1 0 1 0-2 0v1.5a1.5 1.5 0 0 0 1.5 1.5h13a1.5 1.5 0 0 0 1.5-1.5v-13.5z"/><path d="M9 6a1 1 0 0 0 0 2h3v3a1 1 0 1 0 2 0v-4a1 1 0 0 0-1-1h-4z"/><path d="m7 13-.768.64a1 1 0 0 0 1.475.067l-.707-.707zm-2.5-3 .768-.64a1 1 0 0 0-1.52-.018l.752.658zm-4.253 3.341a1 1 0 0 0 1.506 1.318l-1.506-1.319zm11.046-6.048-5 5 1.414 1.414 5-5-1.414-1.414zm-3.525 5.067-2.5-3-1.536 1.28 2.5 3 1.536-1.28zm-4.02-3.018-3.5 4 1.505 1.316 3.5-4-1.506-1.316z"/></svg>; }
export function ResetMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17 9a1 1 0 0 1-1-1c0-.551-.448-1-1-1h-9.586l1.293 1.293a.999.999 0 1 1-1.414 1.414l-3-3a.999.999 0 0 1 0-1.414l3-3a.997.997 0 0 1 1.414 0 .999.999 0 0 1 0 1.414l-1.293 1.293h9.586c1.654 0 3 1.346 3 3a1 1 0 0 1-1 1zm-14 2a1 1 0 0 1 1 1c0 .551.448 1 1 1h9.586l-1.293-1.293a.999.999 0 1 1 1.414-1.414l3 3a.999.999 0 0 1 0 1.414l-3 3a.999.999 0 1 1-1.414-1.414l1.293-1.293h-9.586c-1.654 0-3-1.346-3-3a1 1 0 0 1 1-1z"/></svg>; }
export function ResourcesMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m19.986 10.835-.987-5.926a3.004 3.004 0 0 0-2.999-2.909 1 1 0 1 0 0 2c.552 0 1 .449 1 1 0 .055.005.11.014.165l.806 4.835h-5.82a1 1 0 0 0-1 1v.19a2.92 2.92 0 0 0-1-.19c-.35 0-.688.076-1 .19v-.19a1 1 0 0 0-1-1h-5.82l.806-4.835a.92.92 0 0 0 .014-.165c0-.551.449-1 1-1a1 1 0 0 0 0-2 3.004 3.004 0 0 0-2.999 2.909l-.988 5.926a2.29 2.29 0 0 0-.013.165v2.5c0 2.481 2.019 4.5 4.5 4.5s4.5-2.019 4.5-4.5v-.039c.311-.234.714-.461 1-.461.286 0 .689.227 1 .461v.039c0 2.481 2.019 4.5 4.5 4.5s4.5-2.019 4.5-4.5v-2.5c0-.029-.009-.136-.014-.165zm-12.986 2.665c0 1.379-1.122 2.5-2.5 2.5a2.503 2.503 0 0 1-2.5-2.5v-1.5h5v1.5zm11 0c0 1.379-1.121 2.5-2.5 2.5a2.502 2.502 0 0 1-2.5-2.5v-1.5h5v1.5z"/></svg>; }
export function ReturnMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 13h-8.086l1.293 1.293a.999.999 0 1 1-1.414 1.414l-3-3a.999.999 0 0 1 0-1.414l3-3a.997.997 0 0 1 1.414 0 .999.999 0 0 1 0 1.414l-1.293 1.293h8.086c1.379 0 2.5-1.121 2.5-2.5s-1.121-2.5-2.5-2.5h-10.5a1 1 0 1 1 0-2h10.5c2.481 0 4.5 2.019 4.5 4.5s-2.019 4.5-4.5 4.5z"/></svg>; }
export function RiskMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="m1.16 16.829 7.498-15c.553-1.106 2.13-1.106 2.683 0l7.498 15a1.5 1.5 0 0 1-1.341 2.171h-14.996a1.5 1.5 0 0 1-1.342-2.171zm8.84-9.829a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1zm1 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/></svg>; }
export function RiskMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 11a1 1 0 1 0 2 0v-2a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0zm8.895 1.549-7-14.04c-.339-.679-1.45-.679-1.79 0l-7 14.04a1.004 1.004 0 0 0 .895 1.451h14a1 1 0 0 0 .895-1.451z"/></svg>; }
export function SandboxMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M19.976 13.879a.356.356 0 0 1-.001-.051c0-.023 0-.046-.005-.07l-1.645-6.58a1 1 0 1 0-1.94.485l1.335 5.337h-15.44l1.501-6h4.772a1 1 0 1 0 0-2h-5.553a1 1 0 0 0-.97.757l-2 8c-.006.023-.005.046-.005.07 0 .016.001.033-.001.05-.002.02-.007.037-.012.055a.25.25 0 0 0-.012.068v4.509a1.49 1.49 0 0 0 1.491 1.491h17.018a1.49 1.49 0 0 0 1.491-1.491v-4.509c0-.024-.006-.046-.012-.068l-.001-.004c-.005-.016-.01-.032-.011-.049z"/><path d="m14.239 5 .556-2.294.961.242a1.001 1.001 0 0 0 .488-1.94l-3.879-.978a1 1 0 0 0-.488 1.94l.978.247-1.725 7.127a3.042 3.042 0 0 1-.683-.24c-2.403-1.199-4.759-.065-4.891-.001l-.003.001a1.001 1.001 0 0 0 .894 1.79l.003-.001c.081-.037 1.628-.735 3.103.001a5.251 5.251 0 0 0 2.366.549c1.398 0 2.45-.51 2.527-.547l.001-.001a1.002 1.002 0 0 0-.894-1.79c-.11.049-.223.092-.338.128l.54-2.233.484-2z"/></svg>; }
export function SaveMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 5v10h10v-8h-2v-2h2.5a1.5 1.5 0 0 1 1.5 1.5v9a1.5 1.5 0 0 1-1.5 1.5h-11a1.5 1.5 0 0 1-1.5-1.5v-11a1.5 1.5 0 0 1 1.5-1.5h3.293a1.5 1.5 0 0 1 1.06.44l1.708 1.706a1.5 1.5 0 0 1 .439 1.061v3.379l.793-.793a1 1 0 1 1 1.414 1.414l-2.5 2.5a1 1 0 0 1-1.414 0l-2.5-2.5a1 1 0 0 1 1.414-1.414l.793.793v-3.172l-1.414-1.414h-2.586z"/></svg>; }
export function SearchMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 8c0-3.309 2.691-6 6-6s6 2.691 6 6-2.691 6-6 6-6-2.691-6-6zm17.707 10.293-5.395-5.396a7.946 7.946 0 0 0 1.688-4.897c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8a7.954 7.954 0 0 0 4.897-1.688l5.396 5.395a.998.998 0 0 0 1.707-.707 1 1 0 0 0-.293-.707z"/></svg>; }
export function SearchMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm9.707 4.293-4.82-4.82a5.968 5.968 0 0 0 1.113-3.473 6 6 0 0 0-12 0 6 6 0 0 0 6 6 5.968 5.968 0 0 0 3.473-1.113l4.82 4.82a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414z"/></svg>; }
export function SectionMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 1a1 1 0 0 0-1 1v2h2v-1h1v-2h-2zm16 0a1 1 0 0 1 1 1v2h-2v-1h-1v-2h2zm-17 5v8h18v-8h-18zm1 13a1 1 0 0 1-1-1v-2h2v1h1v2h-2zm17-1a1 1 0 0 1-1 1h-2v-2h1v-1h2v2zm-13-1h3v2h-3v-2zm8 0h-3v2h3v-2zm0-16v2h-3v-2h3zm-5 2v-2h-3v2h3z"/></svg>; }
export function SecureMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9.128.233c-2.37 1.383-5.37 2.33-7.635 2.646-.821.115-1.495.79-1.493 1.62l.001.497c-.03 6.043.477 11.332 9.462 14.903a1.45 1.45 0 0 0 1.062 0c8.993-3.571 9.503-8.86 9.473-14.903v-.501c-.001-.828-.674-1.51-1.492-1.638-2.148-.337-5.281-1.274-7.65-2.628a1.733 1.733 0 0 0-1.728.004zm4.577 8.478a1 1 0 0 0-1.414-1.415l-3.293 3.294-1.293-1.293a1 1 0 1 0-1.415 1.413l2 2.001a1 1 0 0 0 1.414 0l4-4.001z"/></svg>; }
export function SelectMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.676 9h4.648c.563 0 .879-.603.53-1.014l-2.323-2.746a.708.708 0 0 0-1.062 0l-2.324 2.746c-.347.411-.032 1.014.531 1.014Zm4.648 2h-4.648c-.563 0-.878.603-.53 1.014l2.323 2.746c.27.32.792.32 1.062 0l2.323-2.746c.349-.411.033-1.014-.53-1.014Z"/></svg>; }
export function SendMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3.415.189a1 1 0 0 1 1.1-.046l15 9a1 1 0 0 1 0 1.714l-15 9a1 1 0 0 1-1.491-1.074l1.73-7.783h5.246a1 1 0 1 0 0-2h-5.247l-1.73-7.783a1 1 0 0 1 .393-1.028z"/></svg>; }
export function SettingsMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9.027 0a1 1 0 0 0-.99.859l-.37 2.598a6.993 6.993 0 0 0-1.925 1.113l-2.437-.98a1 1 0 0 0-1.239.428l-1.132 1.963a1 1 0 0 0 .248 1.287l2.066 1.621a7.06 7.06 0 0 0 0 2.222l-2.066 1.621a1 1 0 0 0-.248 1.287l1.132 1.962a1 1 0 0 0 1.239.428l2.438-.979a6.995 6.995 0 0 0 1.923 1.113l.372 2.598a1 1 0 0 0 .99.859h2.265a1 1 0 0 0 .99-.859l.371-2.598a6.995 6.995 0 0 0 1.924-1.112l2.438.978a1 1 0 0 0 1.238-.428l1.133-1.962a1 1 0 0 0-.249-1.287l-2.065-1.621a7.063 7.063 0 0 0 0-2.222l2.065-1.621a1 1 0 0 0 .249-1.287l-1.133-1.962a1 1 0 0 0-1.239-.428l-2.437.979a6.994 6.994 0 0 0-1.924-1.113l-.371-2.597a1 1 0 0 0-.99-.859h-2.266zm1.133 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/></svg>; }
export function SettingsMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 13a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm7.476-1.246c-1.394-.754-1.394-2.754 0-3.508a1 1 0 0 0 .376-1.404l-1.067-1.733a1 1 0 0 0-1.327-.355l-.447.243c-1.329.719-2.945-.244-2.945-1.755v-.242a1 1 0 0 0-1-1h-2.132a1 1 0 0 0-1 1v.242c0 1.511-1.616 2.474-2.945 1.755l-.447-.243a1 1 0 0 0-1.327.355l-1.067 1.733a1 1 0 0 0 .376 1.404c1.394.754 1.394 2.754 0 3.508a1 1 0 0 0-.376 1.404l1.067 1.733a1 1 0 0 0 1.327.355l.447-.243c1.329-.719 2.945.244 2.945 1.755v.242a1 1 0 0 0 1 1h2.132a1 1 0 0 0 1-1v-.242c0-1.511 1.616-2.474 2.945-1.755l.447.243a1 1 0 0 0 1.327-.355l1.067-1.733a1 1 0 0 0-.376-1.404z"/></svg>; }
export function ShareIosMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6.293 6.703a.997.997 0 0 0 1.414 0l1.293-1.294v6.589a1 1 0 1 0 2 0v-6.589l1.293 1.294a1 1 0 1 0 1.414-1.415l-3-3.001a.994.994 0 0 0-.298-.198c-.027-.013-.055-.021-.082-.03l-.012-.003a.944.944 0 0 0-.257-.05l-.027-.002a.937.937 0 0 0-.385.06c-.004 0-.008 0-.012.002a.028.028 0 0 0-.013.003h-.004a1.002 1.002 0 0 0-.294.197c-.004.004-.01.007-.015.01l-.015.01-3 3.002a1 1 0 0 0 0 1.415zm-2.793 2.297a1.5 1.5 0 0 0-1.5 1.5v6a1.5 1.5 0 0 0 1.5 1.5h13a1.5 1.5 0 0 0 1.5-1.5v-6a1.5 1.5 0 0 0-1.5-1.5h-3.5v2h3v5h-12v-5h3v-2h-3.5z"/></svg>; }
export function ShareMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15 12c-.807 0-1.537.324-2.077.844l-4.96-2.481c.015-.12.037-.238.037-.363s-.022-.243-.037-.363l4.96-2.481c.54.52 1.27.844 2.077.844 1.654 0 3-1.346 3-3s-1.346-3-3-3-3 1.346-3 3c0 .125.022.243.037.363l-4.96 2.481a2.986 2.986 0 0 0-2.077-.844c-1.654 0-3 1.346-3 3s1.346 3 3 3c.807 0 1.537-.324 2.077-.844l4.96 2.481c-.015.12-.037.238-.037.363 0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3z"/></svg>; }
export function ShipmentMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M1.5 2a1.5 1.5 0 0 0-1.5 1.5v11a1.5 1.5 0 0 0 1.5 1.5h.5a3 3 0 1 0 6 0h4a3 3 0 1 0 6 0h.5a1.5 1.5 0 0 0 1.5-1.5v-3.361a1.5 1.5 0 0 0-.214-.772l-2.783-4.639a1.5 1.5 0 0 0-1.286-.728h-2.717v-1.5a1.5 1.5 0 0 0-1.5-1.5h-10zm13.5 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm-11-1a1 1 0 1 0 2 0 1 1 0 0 0-2 0zm13.234-6h-4.234v-3h2.434l1.8 3z"/></svg>; }
export function ShopcodesMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.5 1a1.5 1.5 0 0 0-1.5 1.5v4.5h6v-6h-4.5zm4.5 12h-6v4.5a1.5 1.5 0 0 0 1.5 1.5h4.5v-6zm6-12h4.5a1.5 1.5 0 0 1 1.5 1.5v4.5h-6v-6zm-12 8v2h2v-2h-2zm8-4h2v2h-2v-2zm2-4h-2v2h2v-2zm0 16h1v2h-3v-3h2v1zm8 0h-2v2h.5a1.5 1.5 0 0 0 1.5-1.5v-.5zm-2-4h2v3h-2v-3zm-1 0h-2v6h2v-6zm-3-4h6v2h-6v-2zm-2 0h-6v2h4v3h2v-5zm2 4h1v3h-1z"/></svg>; }
export function SidebarLeftMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3h3v-2h-3v2zm7-2a1 1 0 0 1 1 1v2h-2v-1h-1v-2h2zm1 17a1 1 0 0 1-1 1h-2v-2h1v-1h2v2zm-16.5 1h6.5v-18h-6.5a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5zm11.5 0h-3v-2h3v2zm5-5h-2v-3h2v3zm-2-5h2v-3h-2v3z"/></svg>; }
export function SidebarRightMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 1h-6.5v18h6.5a1.5 1.5 0 0 0 1.5-1.5v-15a1.5 1.5 0 0 0-1.5-1.5zm-16.5 1a1 1 0 0 1 1-1h2v2h-1v1h-2v-2zm1 17a1 1 0 0 1-1-1v-2h2v1h1v2h-2zm4-18h3v2h-3v-2zm3 16h-3v2h3v-2zm-6-11v3h-2v-3h2zm0 8v-3h-2v3h2z"/></svg>; }
export function SlideshowMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M1 2.5a1.5 1.5 0 0 1 1.5-1.5h15a1.5 1.5 0 0 1 1.5 1.5v12a1.5 1.5 0 0 1-1.5 1.5h-15a1.5 1.5 0 0 1-1.5-1.5v-12zm8 2.5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm6.57 9h-11.143c-.351 0-.548-.368-.343-.632l3.046-3.24a.448.448 0 0 1 .617-.009l1.396 1.481 2.623-3.825a.446.446 0 0 1 .72.016l3.462 5.609c.154.272-.052.6-.377.6z"/><path d="M6 20a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm5-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/></svg>; }
export function SmileyHappyMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 20c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm-2.293-11.293a1 1 0 1 1-1.414-1.414 1 1 0 0 1 1.414 1.414zm4.586-1.414a1 1 0 1 1 1.414 1.414 1 1 0 0 1-1.414-1.414zm.011 4.988c-.03.03-.786.72-2.304.72-1.497 0-2.252-.67-2.303-.718a1 1 0 0 0-1.404 1.424c.132.133 1.36 1.293 3.707 1.293 2.346 0 3.575-1.16 3.707-1.293a.993.993 0 0 0 .005-1.397 1.006 1.006 0 0 0-1.408-.029z"/></svg>; }
export function SmileyJoyMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 0c-5.514 0-10 4.486-10 10s4.486 10 10 10 10-4.486 10-10-4.486-10-10-10zm-2.293 7.707a1 1 0 1 1-1.414-1.414 1 1 0 0 1 1.414 1.414zm6 0a1 1 0 1 1-1.414-1.414 1 1 0 0 1 1.414 1.414zm2.082 3.87a5.997 5.997 0 0 1-5.789 4.423 6 6 0 0 1-5.789-4.422c-.081-.299.155-.578.465-.578h10.649c.309 0 .545.279.464.578z"/></svg>; }
export function SmileyNeutralMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M0 10c0-5.514 4.486-10 10-10s10 4.486 10 10-4.486 10-10 10-10-4.486-10-10zm7.707-1.293a1 1 0 1 1-1.414-1.414 1 1 0 0 1 1.414 1.414zm4.586-1.414a1 1 0 1 1 1.414 1.414 1 1 0 0 1-1.414-1.414zm1.414 5a1 1 0 0 0-.707-.293h-6a1 1 0 0 0 0 2h6a1 1 0 0 0 .707-1.707z"/></svg>; }
export function SmileySadMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M0 10c0-5.514 4.486-10 10-10s10 4.486 10 10-4.486 10-10 10-10-4.486-10-10zm7.707-.293a1 1 0 1 1-1.414-1.414 1 1 0 0 1 1.414 1.414zm4.586-1.414a1 1 0 1 1 1.414 1.414 1 1 0 0 1-1.414-1.414zm-2.293 3.707c-.247 0-2.451.036-3.707 1.293a1 1 0 1 0 1.414 1.414c.57-.57 1.881-.705 2.29-.707.415.002 1.726.137 2.296.707a.998.998 0 0 0 1.707-.707 1 1 0 0 0-.293-.707c-1.256-1.257-3.46-1.293-3.707-1.293z"/></svg>; }
export function SocialAdMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15.5 20a1.5 1.5 0 0 0 1.5-1.5v-7.5a1 1 0 0 0-2 0v7h-13v-14h7a1 1 0 0 0 0-2h-7.5a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h14zm-.056-15.168a.999.999 0 0 0 1.263-.125l2-2a1 1 0 0 0-1.414-1.414l-2 2a1 1 0 0 0 .15 1.54zm1.556 3.168h2a1 1 0 1 0 0-2h-2a1 1 0 1 0 0 2zm-4.707-4.293a1 1 0 0 0 1.707-.707v-2a1 1 0 0 0-2 0v2a1 1 0 0 0 .293.707zm-1.293 2.293h-7v6h9v-6h-2zm-7 8h9v2h-9v-2z"/></svg>; }
export function SocialPostMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4 7h9v2h-9zm0 4h7v2h-7z"/><path d="M16 10a1 1 0 0 1 1 1v4.5a1.5 1.5 0 0 1-1.5 1.5h-7.864l-2.818 2.707a1 1 0 0 1-1.707-.707v-2h-1.611a1.5 1.5 0 0 1-1.5-1.5v-11a1.5 1.5 0 0 1 1.5-1.5h7.5a1 1 0 0 1 0 2h-7v10h2.111a1 1 0 0 1 1 1v.697l1.404-1.404a1 1 0 0 1 .707-.293h7.778v-4a1 1 0 0 1 1-1zm0-5a.999.999 0 0 1-.707-1.707l2-2a1 1 0 1 1 1.414 1.414l-2 2a.996.996 0 0 1-.707.293zm3 1a1 1 0 1 1 0 2h-2a1 1 0 1 1 0-2h2zm-6-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 2 0v2a1 1 0 0 1-1 1z"/></svg>; }
export function SoftPackMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 1.5v17c0 .8.7 1.5 1.5 1.5h13.1c.8 0 1.5-.7 1.5-1.5v-17c0-.8-.7-1.5-1.5-1.5h-13.1c-.8 0-1.5.7-1.5 1.5zm14 16.5h-12v-16h12v16z"/><path d="M6 4h-3v2h3v-2zm11 0h-3v2h3v-2zm-5 0h-4v2h4v-2zm-2 6h-4v2h4v-2zm2 4h-6v2h6v-2z"/></svg>; }
export function SortAscendingMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.293 5.707a1 1 0 0 0 1.414-1.414l-3-3a1 1 0 0 0-1.414 0l-3 3a1 1 0 0 0 1.414 1.414l1.293-1.293v13.586a1 1 0 1 0 2 0v-13.586l1.293 1.293zm2.707 2.293a1 1 0 0 1 1-1h7a1 1 0 1 1 0 2h-7a1 1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1h7a1 1 0 1 1 0 2h-7a1 1 0 0 1-1-1zm1 3a1 1 0 1 0 0 2h7a1 1 0 1 0 0-2h-7z"/></svg>; }
export function SortDescendingMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 1a1 1 0 0 1 1 1v13.586l1.293-1.293a1 1 0 0 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 1 1 1.414-1.414l1.293 1.293v-13.586a1 1 0 0 1 1-1zm5 3a1 1 0 0 1 1-1h7a1 1 0 1 1 0 2h-7a1 1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1h7a1 1 0 1 1 0 2h-7a1 1 0 0 1-1-1zm1 3a1 1 0 1 0 0 2h7a1 1 0 1 0 0-2h-7z"/></svg>; }
export function SortMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5.293 2.293a.997.997 0 0 1 1.414 0l3 3a1 1 0 0 1-1.414 1.414l-1.293-1.293v7.586a1 1 0 1 1-2 0v-7.586l-1.293 1.293a1 1 0 0 1-1.414-1.414l3-3zm7.707 4.707a1 1 0 0 1 2 0v7.585l1.293-1.292a.999.999 0 1 1 1.414 1.414l-3 3a.997.997 0 0 1-1.414 0l-3-3a.997.997 0 0 1 0-1.414.999.999 0 0 1 1.414 0l1.293 1.292v-7.585z"/></svg>; }
export function SoundMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M13.548 2.892a1 1 0 1 1 .904-1.784c3.303 1.674 5.378 5.05 5.378 8.892 0 3.832-2.08 7.212-5.376 8.891a1 1 0 1 1-.908-1.782c2.628-1.338 4.284-4.03 4.284-7.109 0-3.087-1.651-5.774-4.282-7.108zm-1.214 4.154a1 1 0 1 1 1.332-1.492c1.284 1.146 2.007 2.714 2.007 4.446 0 1.733-.723 3.3-2.007 4.446a1 1 0 1 1-1.332-1.492c.865-.771 1.339-1.8 1.339-2.954 0-1.154-.474-2.182-1.339-2.954zm-10.835 6.955a1.5 1.5 0 0 1-1.499-1.501v-5a1.5 1.5 0 0 1 1.5-1.5h2.15l4.725-3.78a1 1 0 0 1 1.625.78v14a1 1 0 0 1-1.625.781l-4.726-3.78h-2.149z"/></svg>; }
export function StarFilledMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5.2 18a.8.8 0 0 1-.792-.914l.743-5.203-2.917-2.917a.8.8 0 0 1 .434-1.355l4.398-.733 2.218-4.435a.8.8 0 0 1 1.435.008l2.123 4.361 4.498.801a.8.8 0 0 1 .425 1.353l-2.917 2.917.744 5.203a.8.8 0 0 1-1.154.828l-4.382-2.22-4.502 2.223a.792.792 0 0 1-.354.083z"/></svg>; }
export function StarOutlineMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m6.71 15.116 3.357-1.658.892.452 2.327 1.178-.56-3.912.708-.707 1.29-1.29-3.235-.576-.445-.915-1.059-2.176-1.585 3.171-1.005.168-2.098.35 1.975 1.975-.141.99-.422 2.95zm-1.51 2.884a.8.8 0 0 1-.792-.914l.743-5.203-2.917-2.917a.8.8 0 0 1 .434-1.355l4.398-.733 2.218-4.435a.8.8 0 0 1 1.435.008l2.123 4.361 4.498.801a.8.8 0 0 1 .425 1.353l-2.917 2.917.744 5.203a.8.8 0 0 1-1.154.828l-4.382-2.22-4.502 2.223a.792.792 0 0 1-.354.083z"/></svg>; }
export function StoreMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m1.791 2.253-.597 3.583a1 1 0 0 0 .986 1.164h.893a1.5 1.5 0 0 0 1.342-.83l.585-1.17.585 1.17a1.5 1.5 0 0 0 1.342.83h1.146a1.5 1.5 0 0 0 1.342-.83l.585-1.17.585 1.17a1.5 1.5 0 0 0 1.342.83h1.146a1.5 1.5 0 0 0 1.342-.83l.585-1.17.585 1.17a1.5 1.5 0 0 0 1.342.83h.893a1 1 0 0 0 .986-1.164l-.597-3.583a1.5 1.5 0 0 0-1.48-1.253h-13.458a1.5 1.5 0 0 0-1.48 1.253z"/><path d="M18 9h-16v8.5a1.5 1.5 0 0 0 1.5 1.5h3.5v-7h6v7h3.5a1.5 1.5 0 0 0 1.5-1.5v-8.5z"/></svg>; }
export function StoreMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m2.554 3.128-.542 3.224a.893.893 0 0 0 .513.964c.12.055.25.084.382.084h.81a1.369 1.369 0 0 0 1.217-.747l.53-1.053.531 1.053a1.354 1.354 0 0 0 1.218.747h1.04a1.369 1.369 0 0 0 1.216-.747l.531-1.053.53 1.053a1.355 1.355 0 0 0 1.218.747h1.04a1.369 1.369 0 0 0 1.217-.747l.53-1.053.531 1.053a1.354 1.354 0 0 0 1.217.747h.81a.913.913 0 0 0 .692-.318.898.898 0 0 0 .203-.73l-.542-3.224a1.347 1.347 0 0 0-.463-.808 1.367 1.367 0 0 0-.88-.32h-12.207c-.322 0-.633.113-.88.32-.245.206-.41.492-.462.808Z"/><path d="M17 16.65v-7.65h-14v7.65c0 .358.141.701.393.955.252.253.594.395.95.395h2.673v-6h5.968v6h2.673c.356 0 .698-.142.95-.395.252-.254.393-.597.393-.955Z"/></svg>; }
export function StoreStatusMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M14 10a3.987 3.987 0 0 0-1.172-2.828l1.415-1.415a6 6 0 1 1-5.243-1.674v-2.021a8.001 8.001 0 0 0 1 15.938 8 8 0 0 0 5.657-13.657l1.413-1.413a9.969 9.969 0 0 1 2.93 7.07c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10c.338 0 .671.017 1 .05v8.218a2 2 0 1 1-2 0v-2.142a4.002 4.002 0 0 0 1 7.874 4 4 0 0 0 4-4z"/></svg>; }
export function TabletMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M2 1.5a1.5 1.5 0 0 1 1.5-1.5h13a1.5 1.5 0 0 1 1.5 1.5v17a1.5 1.5 0 0 1-1.5 1.5h-13a1.5 1.5 0 0 1-1.5-1.5v-17zm2 .5h12v14h-12v-14zm6 17a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/></svg>; }
export function TapChipMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5 1.5a1.5 1.5 0 0 1 1.5-1.5h12a1.5 1.5 0 0 1 1.5 1.5v8a1.5 1.5 0 0 1-1.5 1.5h-4.5v5.5a2.5 2.5 0 0 1-2.5 2.5h-8a2.5 2.5 0 0 1-2.5-2.5v-10.5a4 4 0 0 1 4-4v-.5zm0 2.5a2 2 0 0 0-2 2v7h9v-2h-5.5a1.5 1.5 0 0 1-1.5-1.5v-5.5zm3 2a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2h-1a1 1 0 0 1-1-1zm-5 10.5v-1.5h9v1.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5z"/></svg>; }
export function TaxMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.5 0h11c.828 0 1.5.677 1.5 1.512v18.21a.2.2 0 0 1-.334.149l-1.664-1.515a.497.497 0 0 0-.67 0l-1.664 1.514a.497.497 0 0 1-.67 0l-1.663-1.514a.497.497 0 0 0-.67 0l-1.663 1.514a.497.497 0 0 1-.67 0l-1.664-1.514a.497.497 0 0 0-.67 0l-1.664 1.559a.2.2 0 0 1-.334-.15v-18.253c0-.835.672-1.512 1.5-1.512zm3 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm6.5 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-.293-4.793a1 1 0 0 0-1.414-1.414l-6 6a1 1 0 1 0 1.414 1.414l6-6z"/></svg>; }
export function TeamMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6.086 9.882a5 5 0 1 1 3.91-5.063l4.378.73a3 3 0 0 1 5.626 1.451 3 3 0 0 1-3.919 2.857l-2.866 3.763a4 4 0 1 1-5.77-.697l-1.36-3.041zm1.826-.817 1.342 3.005a4.022 4.022 0 0 1 2.407.29l2.83-3.716a2.983 2.983 0 0 1-.446-1.123l-4.375-.729a5.015 5.015 0 0 1-1.757 2.273z"/></svg>; }
export function TemplateMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 1h-5.5v6h7v-4.5a1.5 1.5 0 0 0-1.5-1.5zm1.5 8h-5v10h3.5a1.5 1.5 0 0 0 1.5-1.5v-8.5zm-18 0h11v10h-9.5a1.5 1.5 0 0 1-1.5-1.5v-8.5zm1.5-8a1.5 1.5 0 0 0-1.5 1.5v4.5h11v-6h-9.5z"/></svg>; }
export function TextAlignmentCenterMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 3h14a1 1 0 1 1 0 2h-14a1 1 0 0 1 0-2zm2 4h10a1 1 0 1 1 0 2h-10a1 1 0 0 1 0-2zm-2 4h14a1 1 0 0 1 0 2h-14a1 1 0 0 1 0-2zm2 4h10a1 1 0 0 1 0 2h-10a1 1 0 0 1 0-2z"/></svg>; }
export function TextAlignmentLeftMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 3h14a1 1 0 1 1 0 2h-14a1 1 0 0 1 0-2zm0 4h10a1 1 0 1 1 0 2h-10a1 1 0 0 1 0-2zm0 4h14a1 1 0 0 1 0 2h-14a1 1 0 0 1 0-2zm0 4h10a1 1 0 0 1 0 2h-10a1 1 0 0 1 0-2z"/></svg>; }
export function TextAlignmentRightMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 3h14a1 1 0 1 1 0 2h-14a1 1 0 0 1 0-2zm4 4h10a1 1 0 1 1 0 2h-10a1 1 0 0 1 0-2zm-4 4h14a1 1 0 0 1 0 2h-14a1 1 0 0 1 0-2zm4 4h10a1 1 0 0 1 0 2h-10a1 1 0 0 1 0-2z"/></svg>; }
export function TextBlockMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M2.5 1a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-15a1.5 1.5 0 0 0-1.5-1.5h-15zm13.5 4h-12v2h12v-2zm-12 4h12v2h-12v-2zm6 4h-6v2h6v-2z"/></svg>; }
export function TextMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="m8.24 9 .816 2.33a1 1 0 0 0 1.888-.66l-3.335-9.528a1.705 1.705 0 0 0-3.218 0l-3.335 9.528a1 1 0 0 0 1.888.66l.815-2.33h4.482zm-.7-2-1.54-4.401-1.54 4.401h3.08zm7.96-2c.608 0 1.18.155 1.68.428a.999.999 0 0 1 1.82.572v5a1 1 0 0 1-1.82.572 3.5 3.5 0 1 1-1.68-6.572zm0 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/><path d="M2 14a1 1 0 1 0 0 2h16a1 1 0 1 0 0-2h-16zm0 4a1 1 0 1 0 0 2h12a1 1 0 1 0 0-2h-12z"/></svg>; }
export function ThemeEditMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M0 1.5a1.5 1.5 0 0 1 1.5-1.5h5a1.5 1.5 0 0 1 1.5 1.5v.5h5a1 1 0 1 1 0 2h-5v1.5a1.5 1.5 0 0 1-1.5 1.5h-1.5v2h-2v-2h-1.5a1.5 1.5 0 0 1-1.5-1.5v-4zm2 3.5v-3h4v3h-4z"/><path d="M5 17v1h1v2h-1.5a1.5 1.5 0 0 1-1.5-1.5v-1.5h2zm-2-3v-3h2v3h-2zm9 6h-4v-2h4v2zm2-2h1v-1h2v1.5a1.5 1.5 0 0 1-1.5 1.5h-1.5v-2zm2.907-13.159 2.252 2.252.375-.376a1.592 1.592 0 0 0-2.252-2.25l-.375.374zm1.218 3.283-2.251-2.251-7.143 7.143-.731 2.984 2.982-.731 7.143-7.144z"/></svg>; }
export function ThemesMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 1h-3.5v6h5v-4.5a1.5 1.5 0 0 0-1.5-1.5zm1.5 8h-5v10h3.5a1.5 1.5 0 0 0 1.5-1.5v-8.5zm-18 5h11v5h-9.5a1.5 1.5 0 0 1-1.5-1.5v-3.5zm1.5-13a1.5 1.5 0 0 0-1.5 1.5v9.5h11v-11h-9.5z"/></svg>; }
export function ThemeStoreMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M1.5 0a1.5 1.5 0 0 0-1.5 1.5v4a1.5 1.5 0 0 0 1.5 1.5h1.5v11.5a1.5 1.5 0 0 0 1.5 1.5h3.5a1 1 0 1 0 0-2h-3v-11h1.5a1.5 1.5 0 0 0 1.5-1.5v-.5h7a1 1 0 1 0 2 0v-.5a1.5 1.5 0 0 0-1.5-1.5h-7.5v-1.5a1.5 1.5 0 0 0-1.5-1.5h-5zm.5 2v3h4v-3h-4z"/><path fillRule="evenodd" d="M9 8a1 1 0 0 0-1 1v5a1 1 0 0 0 .293.707l5 5a1 1 0 0 0 1.414 0l5-5a1 1 0 0 0 0-1.414l-5-5a1 1 0 0 0-.707-.293h-5zm4 4a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/></svg>; }
export function ThumbsDownMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M1.008 13a.904.904 0 0 0 .896 1.023c.387 0 2.01-.007 3.488-.013l2.608-.01c-.059 0-1.234 2.103-1.234 3.29a2.711 2.711 0 0 0 2.713 2.71l2.712-6h1.809v-11h-2.382a1.81 1.81 0 0 1-.809-.19l-.753-.377c-1.056-.433-2.456-.433-4.282-.433h-1.915c-.68 0-1.255.503-1.345 1.176l-1.506 9.824zm16.492 1a1.5 1.5 0 0 0 1.5-1.5v-8a1.5 1.5 0 0 0-1.5-1.5h-1.5v11h1.5z"/></svg>; }
export function ThumbsDownMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.437 15.996a.502.502 0 0 1-.437-.496v-3.5a1 1 0 0 0-1-1h-3.752l1.554-7h3.896l2.302 1.535v6.188l-2.563 4.273zm7.563-11.996h-3.697l-2.748-1.832a.995.995 0 0 0-.555-.168h-5c-.468 0-.874.326-.976.783l-2 9a1 1 0 0 0 .976 1.217h4v2.5c0 1.378 1.122 2.5 2.5 2.5h.5a1 1 0 0 0 .858-.485l2.709-4.515h3.433a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1z"/></svg>; }
export function ThumbsUpMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M18.992 7a.904.904 0 0 0-.896-1.023c-.387 0-2.01.007-3.488.013l-2.608.01c.059 0 1.234-2.103 1.234-3.29a2.711 2.711 0 0 0-2.713-2.71l-2.711 6h-1.81v11h2.382c.28 0 .557.065.808.19l.754.377c1.056.433 2.456.433 4.282.433h1.915c.68 0 1.255-.503 1.345-1.176l1.506-9.824zm-16.492-1a1.5 1.5 0 0 0-1.5 1.5v8a1.5 1.5 0 0 0 1.5 1.5h1.5v-11h-1.5z"/></svg>; }
export function ThumbsUpMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M14.198 16h-3.896l-2.302-1.535v-6.188l2.563-4.273a.502.502 0 0 1 .437.496v3.5a1 1 0 0 0 1 1h3.753l-1.555 7zm3.582-8.625a1 1 0 0 0-.78-.375h-4v-2.5c0-1.378-1.12-2.5-2.5-2.5h-.5a1 1 0 0 0-.858.485l-2.709 4.515h-3.433a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h3.697l2.748 1.832c.164.109.357.168.555.168h5a1 1 0 0 0 .976-.783l2-9a1 1 0 0 0-.196-.842z"/></svg>; }
export function TickMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m7.293 14.707-3-3a.999.999 0 1 1 1.414-1.414l2.236 2.236 6.298-7.18a.999.999 0 1 1 1.518 1.3l-7 8a1 1 0 0 1-.72.35 1.017 1.017 0 0 1-.746-.292z"/></svg>; }
export function TickSmallMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M14.723 6.237a.94.94 0 0 1 .053 1.277l-5.366 6.193a.834.834 0 0 1-.611.293.83.83 0 0 1-.622-.264l-2.927-3.097a.94.94 0 0 1 0-1.278.82.82 0 0 1 1.207 0l2.297 2.43 4.763-5.498a.821.821 0 0 1 1.206-.056Z"/></svg>; }
export function TimelineAttachmentMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17 4a1 1 0 0 1 1 1v13.5a1.5 1.5 0 0 1-1.5 1.5h-13a1.5 1.5 0 0 1-1.5-1.5v-17a1.5 1.5 0 0 1 1.5-1.5h4.5v2h-4v16h12v-13a1 1 0 0 1 1-1z"/><path d="M8 5v6c0 1.206.799 3 3 3s3-1.794 3-3v-8c0-1.206-.799-3-3-3h-1v2h1c.805 0 .988.55 1 1v7.988c-.012.462-.195 1.012-1 1.012-.805 0-.988-.55-1-1v-6a1 1 0 1 0-2 0z"/></svg>; }
export function TipsMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M17.5 0h-11c-.828 0-1.5.677-1.5 1.512v1.488h4.5a1.5 1.5 0 0 1 1.5 1.5v9a1.5 1.5 0 0 1-1.5 1.5h-4.5v4.721a.2.2 0 0 0 .334.15l1.664-1.515a.497.497 0 0 1 .67 0l1.664 1.514c.19.173.48.173.67 0l1.663-1.514a.497.497 0 0 1 .67 0l1.663 1.514c.19.173.48.173.67 0l1.664-1.514a.497.497 0 0 1 .67 0l1.664 1.515a.2.2 0 0 0 .334-.15v-18.209c0-.835-.672-1.512-1.5-1.512zm-8.793 6.707a1 1 0 0 0-1.414-1.414l-6 6a1 1 0 1 0 1.414 1.414l6-6zm-6.207 1.293a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm6.5 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm8-8.5h-4v2h4v-2zm-4 4h4v2h-4v-2zm4 4h-4v2h4v-2z"/></svg>; }
export function TitleMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3 2a1 1 0 0 0-1 1v3a1 1 0 0 0 2 0 2 2 0 0 1 2-2h2v10.999a1 1 0 0 1-1 1.001h-.001a1 1 0 0 0 .001 2h6a1 1 0 1 0 0-2 1 1 0 0 1-1-1v-11h2a2 2 0 0 1 2 2 1 1 0 1 0 2 0v-3a1 1 0 0 0-1-1h-14z"/></svg>; }
export function ToggleMinor() { return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2 10a6 6 0 0 1 6-6h4a6 6 0 0 1 0 12h-4a6 6 0 0 1-6-6Zm6 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"/></svg>; }
export function ToolsMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M18.414 1.586a2 2 0 0 1 0 2.828l-3 3-1.115 1.115-2.828-2.828 1.232-1.233.015-.015 2.868-2.867a2 2 0 0 1 2.828 0zm-8.47 11.299-2.788-2.787a4.67 4.67 0 0 1-5.919-5.901l2.523 2.522a1.5 1.5 0 0 0 2.121 0l.84-.84a1.5 1.5 0 0 0 0-2.12l-2.524-2.523a4.67 4.67 0 0 1 5.9 5.919l2.787 2.787 5.506 5.506a2.08 2.08 0 0 1-2.942 2.942l-5.506-5.506zm-1.415 1.414-3.287 3.287-4.242 1.414 1.414-4.243 3.287-3.286 2.828 2.828z"/></svg>; }
export function TransactionFeeDollarMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 13c-1.103 0-2-.897-2-2v-7h5.185a2.995 2.995 0 0 0 2.815 2c1.654 0 3-1.346 3-3s-1.346-3-3-3a2.997 2.997 0 0 0-2.816 2h-8.368a2.997 2.997 0 0 0-2.816-2c-1.654 0-3 1.346-3 3s1.346 3 3 3a2.997 2.997 0 0 0 2.816-2h1.184v7c0 2.206 1.794 4 4 4a1 1 0 0 0 0-2zm5.977-2c.026.001.649.04 1.316.707a1 1 0 0 0 1.414-1.414 4.49 4.49 0 0 0-1.707-1.093v-.2a1 1 0 0 0-2 0v.185a2.995 2.995 0 0 0-2 2.815c0 2.28 1.725 2.712 2.756 2.97h.002c1.115.279 1.242.384 1.242 1.03 0 .552-.448 1-.976 1-.026-.001-.65-.04-1.317-.707a1 1 0 0 0-1.414 1.414c.479.49 1.062.863 1.707 1.093v.2a1 1 0 0 0 2 0v-.185a2.992 2.992 0 0 0 2-2.815c0-2.28-1.725-2.712-2.756-2.97h-.001c-1.115-.279-1.243-.384-1.243-1.03 0-.552.449-1 .977-1z"/></svg>; }
export function TransactionFeeEuroMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 13a1 1 0 0 1 0 2c-2.206 0-4-1.794-4-4v-7h-1.184a2.997 2.997 0 0 1-2.816 2c-1.654 0-3-1.346-3-3s1.346-3 3-3c1.302 0 2.401.839 2.816 2h8.368a2.997 2.997 0 0 1 2.816-2c1.654 0 3 1.346 3 3s-1.346 3-3 3a2.995 2.995 0 0 1-2.815-2h-5.185v7c0 1.103.897 2 2 2zm3.014.992c-.011-.333-.006-.667.014-1h-.278a.75.75 0 1 1 0-1.5h.49c.456-2.024 1.567-3.492 3.086-3.492 1.142 0 1.911.334 2.47 1.385a.992.992 0 0 1-1.75.932c-.16-.298-.24-.333-.72-.333-.335 0-.757.581-1.036 1.508h.959a.75.75 0 1 1 0 1.5h-1.232a8.24 8.24 0 0 0-.019 1h1.251a.75.75 0 1 1 0 1.5h-1.042c.272 1.102.749 1.812 1.119 1.812h.073c.313 0 .426 0 .496-.054a.45.45 0 0 0 .106-.152l.004-.007a.993.993 0 0 1 1.738.958c-.555 1.006-1.205 1.24-2.366 1.239h-.051c-1.594 0-2.739-1.617-3.151-3.796h-.426a.75.75 0 1 1 0-1.5h.265z"/></svg>; }
export function TransactionFeePoundMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 13a1 1 0 0 1 0 2c-2.206 0-4-1.794-4-4v-7h-1.184a2.997 2.997 0 0 1-2.816 2c-1.654 0-3-1.346-3-3s1.346-3 3-3c1.302 0 2.401.839 2.816 2h8.368a2.997 2.997 0 0 1 2.816-2c1.654 0 3 1.346 3 3s-1.346 3-3 3a2.995 2.995 0 0 1-2.815-2h-5.185v7c0 1.103.897 2 2 2zm3.244 3h-.244a1 1 0 0 1 0-2h.472c.019-.34.028-.682.028-1.027 0-1.894 1.057-3.246 2.555-3.483 1.504-.239 2.867.785 2.867 2.4a1 1 0 0 1-2 0c0-.323-.21-.48-.555-.425-.487.077-.867.563-.867 1.508 0 .345-.009.687-.026 1.027h1.526a1 1 0 0 1 0 2h-1.73a18.706 18.706 0 0 1-.422 2h2.652a1 1 0 0 1 0 2h-4a1 1 0 0 1-1-1 .999.999 0 0 1 .056-.354c.302-.86.53-1.742.688-2.646z"/></svg>; }
export function TransactionFeeRupeeMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 13a1 1 0 0 1 0 2c-2.206 0-4-1.794-4-4v-7h-1.184a2.997 2.997 0 0 1-2.816 2c-1.654 0-3-1.346-3-3s1.346-3 3-3c1.302 0 2.401.839 2.816 2h8.368a2.997 2.997 0 0 1 2.816-2c1.654 0 3 1.346 3 3s-1.346 3-3 3a2.995 2.995 0 0 1-2.815-2h-5.185v7c0 1.103.897 2 2 2zm4.848 2.562 2.08 2.761a1 1 0 1 1-1.596 1.204l-3.01-3.993a1 1 0 0 1 1.259-1.49c.233.037.492.056.776.056.702 0 1.242-.478 1.494-1.235h-3.101a.75.75 0 1 1 0-1.5h3.23c-.091-.72-.395-1.283-.844-1.58h-2.136a1 1 0 1 1 0-2h5a1 1 0 1 1 0 2h-.944c.232.467.38 1.004.431 1.58h.763a.75.75 0 1 1 0 1.5h-.849c-.298 1.407-1.21 2.486-2.553 2.697z"/></svg>; }
export function TransactionFeeYenMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 13a1 1 0 0 1 0 2c-2.206 0-4-1.794-4-4v-7h-1.184a2.997 2.997 0 0 1-2.816 2c-1.654 0-3-1.346-3-3s1.346-3 3-3c1.302 0 2.401.839 2.816 2h8.368a2.997 2.997 0 0 1 2.816-2c1.654 0 3 1.346 3 3s-1.346 3-3 3a2.995 2.995 0 0 1-2.815-2h-5.185v7c0 1.103.897 2 2 2zm6.475 3.454h1.25a.75.75 0 1 1 0 1.5h-1.25v1a1 1 0 0 1-2 0v-1h-1.25a.75.75 0 1 1 0-1.5h1.25v-.75h-1.25a.75.75 0 1 1 0-1.5h.859l-1.85-3.204a1 1 0 0 1 1.732-1l1.515 2.624 1.515-2.624a1 1 0 1 1 1.732 1l-1.85 3.204h.847a.75.75 0 1 1 0 1.5h-1.25v.75z"/></svg>; }
export function TransactionMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m11.293 8.293 1.293-1.293h-9.586v11a1 1 0 1 1-2 0v-16a1 1 0 0 1 2 0v3h9.586l-1.293-1.293a1 1 0 0 1 1.414-1.414l2.999 2.999a1 1 0 0 1 0 1.416l-2.999 2.999a.998.998 0 0 1-1.707-.707 1 1 0 0 1 .293-.707zm6.707-7.293a1 1 0 0 1 1 1v16a1 1 0 0 1-2 0v-3h-9.586l1.293 1.293a1 1 0 1 1-1.414 1.414l-2.999-2.999a1 1 0 0 1 0-1.416l2.999-2.999a1 1 0 0 1 1.414 1.414l-1.293 1.293h9.586v-11a1 1 0 0 1 1-1z"/></svg>; }
export function TransferInMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8.586 13h-6.586a1 1 0 0 1 0-2h6.586l-1.293-1.293a1 1 0 0 1 1.414-1.414l3 3a1 1 0 0 1 0 1.414l-3 3a.997.997 0 0 1-1.631-.324 1 1 0 0 1 .217-1.09l1.293-1.293zm10.414 5.5a1.5 1.5 0 0 1-1.5 1.5h-15a1.5 1.5 0 0 1-1.5-1.5v-3.5h2v3h14v-12.382l-7-3.5-7 3.5v3.382h-2v-4c0-.379.214-.725.553-.895l8-4c.281-.14.613-.14.894 0l8 4c.339.17.553.516.553.895v13.5z"/></svg>; }
export function TransferMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 14h8v-11h-11v8h-2v-8.5a1.5 1.5 0 0 1 1.5-1.5h12a1.5 1.5 0 0 1 1.5 1.5v12a1.5 1.5 0 0 1-1.5 1.5h-8.5v-2z"/><path d="M9 5a1 1 0 0 0 0 2h2.586l-3.293 3.293a1 1 0 1 0 1.414 1.414l3.293-3.293v2.586a1 1 0 1 0 2 0v-5c0-.025 0-.05-.003-.075a1 1 0 0 0-.997-.925h-5zm-9 9.5a1.5 1.5 0 0 1 1.5-1.5h4a1.5 1.5 0 0 1 1.5 1.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a1.5 1.5 0 0 1-1.5-1.5v-4z"/></svg>; }
export function TransferOutMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m4.414 13 1.293 1.293a1 1 0 1 1-1.414 1.414l-3-3a1 1 0 0 1 0-1.414l3-3a1 1 0 0 1 1.414 1.414l-1.293 1.293h6.586a1 1 0 0 1 0 2h-6.586zm14.586 5.5a1.5 1.5 0 0 1-1.5 1.5h-15.214c-.71 0-1.286-.576-1.286-1.286v-.714a1 1 0 1 1 2 0h14v-12.382l-7-3.5-7 3.5v.382a1 1 0 0 1-2 0v-1c0-.379.214-.725.553-.895l8-4c.281-.14.613-.14.894 0l8 4c.339.17.553.516.553.895v13.5z"/></svg>; }
export function TransferWithinShopifyMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m7 2.202 2.801 1.868-3.331 2.082a1 1 0 0 0-.47.848v2h2v-1.446l5-3.125 5 3.125v10.446h-10v-3h-2v3.5a1.5 1.5 0 0 0 1.5 1.5h11a1.5 1.5 0 0 0 1.5-1.5v-11.5a1 1 0 0 0-.47-.848l-6-3.75a1.003 1.003 0 0 0-1.06 0l-.807.505-4.108-2.739a1 1 0 0 0-1.11 0l-6 4a1.001 1.001 0 0 0-.445.832v13h2v-12.465l5-3.333zm-2 10.798h6.586l-1.293 1.293a1 1 0 1 0 1.414 1.414l3-3a1 1 0 0 0 0-1.414l-3-3a1 1 0 1 0-1.414 1.414l1.293 1.293h-6.586a1 1 0 0 0 0 2z"/></svg>; }
export function TransportMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M1.282 13.469a.506.506 0 0 1-.132-.814l1.093-1.087a1.537 1.537 0 0 1 1.084-.447h2.43l1.963-1.953-6.392-2.826a.507.507 0 0 1-.154-.823l1.972-1.96a1.538 1.538 0 0 1 1.357-.422l7.855 1.42 2.98-2.964a2.174 2.174 0 0 1 3.027.038 2.15 2.15 0 0 1 .038 3.01l-3.013 2.997 1.442 8.607a1.52 1.52 0 0 1-.662 1.518l-1.736 1.151a.512.512 0 0 1-.75-.216l-2.898-6.482-1.964 1.953v2.417c0 .404-.162.791-.45 1.077l-1.092 1.087a.512.512 0 0 1-.818-.132l-1.726-3.433-3.453-1.716z"/></svg>; }
export function TroubleshootMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M19 5.893a4.893 4.893 0 0 1-6.681 4.557l-8.167 8.166a1.957 1.957 0 0 1-2.768-2.768l8.166-8.166a4.893 4.893 0 0 1 5.454-6.6c.363.068.463.508.199.766l-2.97 2.898 2.936 2.936 2.985-2.913c.26-.254.692-.152.76.205.056.298.086.605.086.92z"/></svg>; }
export function TypeMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 0c-.6 0-1.1.4-1.4 1l-5.6 16.3a1 1 0 0 1-.9.7 1 1 0 1 0 0 2h4a1 1 0 1 0 0-2 1 1 0 0 1-.9-1.2l.8-2.8h7l.9 2.8a1 1 0 0 1-.9 1.2 1 1 0 1 0 0 2h7a1 1 0 1 0 0-2 1 1 0 0 1-1-.7l-5.5-16.3c-.3-.6-.8-1-1.5-1h-2zm-.5 4.3-2.7 7.7h5.4l-2.7-7.7z"/></svg>; }
export function TypeMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="m10.824 3.58 3.93 11.006a1 1 0 0 0 .942.664h.429a.875.875 0 0 1 0 1.75h-4.375a.875.875 0 0 1 0-1.75c.248 0 .42-.245.337-.479l-.766-2.146h-4.392l-.766 2.146a.358.358 0 0 0 .337.479.875.875 0 0 1 0 1.75h-2.625a.875.875 0 0 1 0-1.75.367.367 0 0 0 .345-.243l4.081-11.426a.875.875 0 0 1 .824-.581h.875c.37 0 .7.232.824.58Zm-3.27 7.295 1.571-4.398 1.57 4.398h-3.14Z"/></svg>; }
export function UndoMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3.488 5c2.149-1.934 3.99-3 6.512-3a8 8 0 0 1 8 8 8 8 0 0 1-8 8 1 1 0 1 0 0 2c5.523 0 10-4.477 10-10s-4.477-10-10-10c-3.297 0-5.644 1.496-8 3.647v-2.647a1 1 0 0 0-2 0v5a.998.998 0 0 0 1 1h5a1 1 0 0 0 0-2h-2.512z"/></svg>; }
export function UnfulfilledMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 3v11h3.5c.775 0 1.388.662 1.926 1.244l.11.12a2 2 0 0 0 2.927-.001l.111-.119c.538-.582 1.151-1.244 1.926-1.244h3.5v-11h-14zm3-2h-3.5a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h15a1.5 1.5 0 0 0 1.5-1.5v-15a1.5 1.5 0 0 0-1.5-1.5h-11.5z"/></svg>; }
export function UnknownDeviceMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4 2h1v-2h-1.5a1.5 1.5 0 0 0-1.5 1.5v1.5h2v-1zm4.685 10.502h2.136v-.141c.011-1.17.23-1.302 1.02-1.777l.238-.144c1.11-.664 1.805-1.57 1.805-2.952 0-2.04-1.588-3.488-3.894-3.488-2.113 0-3.93 1.193-3.99 3.487h2.267c.054-1.135.86-1.533 1.711-1.533.924 0 1.666.616 1.666 1.564 0 .845-.561 1.424-1.292 1.883-.102.064-.2.122-.293.178-.882.528-1.363.816-1.374 2.782v.14zm.979 4.34c.712 0 1.322-.591 1.328-1.328a1.342 1.342 0 0 0-1.328-1.316c-.737 0-1.334.592-1.328 1.316a1.32 1.32 0 0 0 1.328 1.328zm-5.664 1.158v-1h-2v1.5a1.5 1.5 0 0 0 1.5 1.5h1.5v-2h-1zm11 0h1v-1h2v1.5a1.5 1.5 0 0 1-1.5 1.5h-1.5v-2zm-11-7v4h-2v-4h2zm0-2v-4h-2v4h2zm14 2v4h-2v-4h2zm0-2v-4h-2v4h2zm-2-6v-1h-1v-2h1.5a1.5 1.5 0 0 1 1.5 1.5v1.5h-2z"/></svg>; }
export function UpdateInventoryMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m18 5.65-8-3.555-8 3.555v13.35h-2v-14c0-.396.232-.753.594-.913l9-4a.999.999 0 0 1 .812 0l9 4a1 1 0 0 1 .594.913v14h-2v-13.35z"/><path d="M9 15a.997.997 0 0 1-.707-.293l-2-2a1 1 0 0 1 1.414-1.414l1.293 1.293 3.293-3.293a1 1 0 1 1 1.414 1.415l-4 4a.999.999 0 0 1-.707.292z"/></svg>; }
export function UploadMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M20 10c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10 10 4.477 10 10zm-14.707-1.707 4-4a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1-1.414 1.414l-2.293-2.293v7.586a1 1 0 1 1-2 0v-7.586l-2.293 2.293a1 1 0 0 1-1.414-1.414z"/></svg>; }
export function VariantMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 1a1.5 1.5 0 0 1 1.5 1.5v12a1.5 1.5 0 0 1-1.5 1.5h-3.5v2.5a1.5 1.5 0 0 1-1.5 1.5h-11a1.5 1.5 0 0 1-1.5-1.5v-11a1.5 1.5 0 0 1 1.5-1.5h2.5v-3.5a1.5 1.5 0 0 1 1.5-1.5h12zm-6.5 13h6v-6l-6 6zm-9 4h10v-2.027l-6.495.022a1.5 1.5 0 0 1-1.505-1.5v-6.495h-2v10zm10-15h-6v6l6-6z"/></svg>; }
export function ViewMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M19.928 9.629c-2.137-5.343-6.247-7.779-10.355-7.565-4.06.21-7.892 3.002-9.516 7.603l-.118.333.118.333c1.624 4.601 5.455 7.393 9.516 7.603 4.108.213 8.218-2.222 10.355-7.565l.149-.371-.149-.371zm-9.928 5.371a5 5 0 1 0 0-10 5 5 0 0 0 0 10z"/><circle cx="10" cy="10" r="3"/></svg>; }
export function ViewMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.928 9.628c-.091-.229-2.317-5.628-7.928-5.628s-7.838 5.399-7.93 5.628a1.017 1.017 0 0 0 0 .744c.093.229 2.319 5.628 7.93 5.628s7.837-5.399 7.928-5.628a1.017 1.017 0 0 0 0-.744zm-7.928 4.372a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-6a2 2 0 1 0 .002 4.001 2 2 0 0 0-.003-4.001z"/></svg>; }
export function ViewportNarrowMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M14.414 9h4.586a1 1 0 1 1 0 2h-4.586l1.293 1.293a1 1 0 0 1-1.414 1.414l-3-3a1 1 0 0 1 0-1.414l3-3a1 1 0 1 1 1.414 1.414l-1.293 1.293zm-8.707-2.707 3 3a.999.999 0 0 1 0 1.414l-3 3a.997.997 0 0 1-1.631-.324 1 1 0 0 1 .217-1.09l1.293-1.293h-4.586a1 1 0 0 1 0-2h4.586l-1.293-1.293a1 1 0 0 1 1.414-1.414zm2.293-4.293v4l-2-2v-2.5a1.5 1.5 0 0 1 1.5-1.5h5a1.5 1.5 0 0 1 1.5 1.5v2.5l-2 2v-4h-4zm6 14-2-2v4h-4v-4l-2 2v2.5a1.5 1.5 0 0 0 1.5 1.5h5a1.5 1.5 0 0 0 1.5-1.5v-2.5z"/></svg>; }
export function ViewportWideMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m16.707 6.293 3 3a.998.998 0 0 1 0 1.414l-3 3a.997.997 0 0 1-1.631-.324 1 1 0 0 1 .217-1.09l1.293-1.293h-4.586a1 1 0 1 1 0-2h4.586l-1.293-1.293a1 1 0 1 1 1.414-1.414zm-13.414 0a1 1 0 1 1 1.414 1.414l-1.293 1.293h4.586a1 1 0 0 1 0 2h-4.586l1.293 1.293a1.003 1.003 0 0 1 0 1.414 1 1 0 0 1-1.414 0l-3-3a.998.998 0 0 1 0-1.414l3-3z"/><path d="M1 1.5a1.5 1.5 0 0 1 1.5-1.5h15a1.5 1.5 0 0 1 1.5 1.5v4.5l-2-2v-2h-14v2l-2 2v-4.5zm16 16.5v-2l2-2v4.5a1.5 1.5 0 0 1-1.5 1.5h-15a1.5 1.5 0 0 1-1.5-1.5v-4.5l2 2v2h14z"/></svg>; }
export function VocabularyMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M20 1.5a1.5 1.5 0 0 0-1.5-1.5h-6.5c-.768 0-1.469.29-2 .766a2.987 2.987 0 0 0-2-.766h-6.5a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h6.5a1 1 0 0 1 1 1 1 1 0 1 0 2 0 1 1 0 0 1 1-1h6.5a1.5 1.5 0 0 0 1.5-1.5v-15zm-2 14.5h-6c-.352 0-.687.067-1 .179v-13.179a1 1 0 0 1 1-1h6v14zm-10 0h-6v-14h6a1 1 0 0 1 1 1v13.179a2.959 2.959 0 0 0-1-.179z"/><path d="M4 4h3v2h-3zm9 0h3v2h-3zm-9 4h3v2h-3zm9 0h3v2h-3zm-9 4h3v2h-3z"/></svg>; }
export function WandMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15.666.376c.495-.501 1.189-.501 1.684 0l2.278 2.307c.496.502.496 1.204 0 1.706l-3.665 3.611-3.963-4.013 3.666-3.61Z"/><path d="m10.993 5 4.007 4.007-10.618 10.617c-.5.501-1.202.501-1.703 0l-2.303-2.303c-.501-.501-.501-1.203 0-1.703l10.617-10.618Z"/><path d="M16 13c0-.6.4-1 1-1s1 .4 1 1 .4 1 1 1 1 .4 1 1-.4 1-1 1-1 .4-1 1-.4 1-1 1-1-.4-1-1-.4-1-1-1-1-.4-1-1 .4-1 1-1 1-.4 1-1Z"/><path d="M4 1c0-.6.4-1 1-1s1 .4 1 1 .4 1 1 1 1 .4 1 1-.4 1-1 1-1 .4-1 1-.4 1-1 1-1-.4-1-1-.4-1-1-1-1-.4-1-1 .4-1 1-1 1-.4 1-1Z"/></svg>; }
export function WandMinor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M3.44 15.56a1.5 1.5 0 0 1 0-2.12l6.792-6.793 3.121 3.12-6.792 6.794a1.5 1.5 0 0 1-2.122 0l-1-1Zm11.328-7.206 1.792-1.794a1.5 1.5 0 0 0 0-2.122l-1-1a1.5 1.5 0 0 0-2.122 0l-1.793 1.793 3.122 3.122Zm-1.768 3.646c1 0 2-1 2-2 0 1 1 2 2 2-1 0-2 1-2 2 0-1-1-2-2-2Zm-7-7c1 0 2-1 2-2 0 1 1 2 2 2-1 0-2 1-2 2 0-1-1-2-2-2Z"/></svg>; }
export function WearableMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 4a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3v2.5a1.5 1.5 0 0 0 1.5 1.5h5a1.5 1.5 0 0 0 1.5-1.5v-2.5a3 3 0 0 0 3-3v-1a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1v-1a3 3 0 0 0-3-3v-2.5a1.5 1.5 0 0 0-1.5-1.5h-5a1.5 1.5 0 0 0-1.5 1.5v2.5zm2 0v-2h4v2h-4zm4 12v2h-4v-2h4zm-6-10a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-8z"/></svg>; }
export function WholesaleMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M17 3a1 1 0 1 0-2 0v5.392a2.5 2.5 0 0 0-.32-1.223l-2.184-3.892a2.5 2.5 0 0 0-2.18-1.277h-2.816a2.5 2.5 0 0 0-2.5 2.5v1.5h-2.5a1.5 1.5 0 0 0-1.5 1.5v2.585a1.5 1.5 0 0 0-1 1.415v5.5a3 3 0 1 0 6 0h3a3 3 0 1 0 6 0v-2h4a1 1 0 1 0 0-2h-2v-10zm-14 7h2v-2h-2v2zm10-1.608v1.608h-6v-5.5a.5.5 0 0 1 .5-.5h2.816a.5.5 0 0 1 .436.255l2.184 3.892a.5.5 0 0 1 .064.245zm-9 8.608a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm8 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/></svg>; }
export function WifiMajor() { return <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 18a1.501 1.501 0 0 1 0-3 1.501 1.501 0 0 1 0 3zm-8.996-10a.997.997 0 0 1-.607-1.795c2.709-2.067 6.119-3.205 9.603-3.205 3.483 0 6.892 1.137 9.598 3.202a1 1 0 1 1-1.212 1.59c-2.36-1.801-5.338-2.792-8.386-2.792-3.05 0-6.029.992-8.391 2.795a.988.988 0 0 1-.605.205zm2.996 3a.996.996 0 0 1-.958-1.293.998.998 0 0 1 .352-.502c1.861-1.422 4.208-2.205 6.606-2.205 2.397 0 4.742.782 6.601 2.203a1 1 0 0 1-1.213 1.59c-1.513-1.156-3.427-1.793-5.388-1.793-1.962 0-3.877.638-5.392 1.795a.989.989 0 0 1-.605.205h-.002zm8.994 3a.994.994 0 0 1-.594-.197 4.052 4.052 0 0 0-4.804 0 .999.999 0 1 1-1.192-1.606c2.108-1.565 5.079-1.566 7.187 0a1 1 0 0 1-.595 1.803z"/></svg>; }
