import React, { useState, useMemo } from 'react';

import {
  Page,
  Card,
  TextField,
  FormLayout,
  Button,
  TextContainer,
  Heading,
  Layout
} from './Polaris';

import { useFetch, useProfile } from "../contexts/Auth";
import { MultiTable } from "./MultiTable";
import { useRedirect } from "../contexts/Route";

export default () => {
  const [profile] = useProfile();
  const redirect = useRedirect();
  const authFetch = useFetch();
  const params = new URLSearchParams(window.location.search);
  const [price, name, shop] = [params.get("price"), params.get("name"), params.get("shop") || (profile && profile.shop && profile.shop.shop_origin)];
  const [isLoading, setIsLoading] = useState(false);
  const valid = price && name && shop && /\.myshopify\.com$/.test(shop);
  const [potentialCharge, setPotentialCharge] = useState({ shop: shop || "" });
  return (
      <Page
      title="Billing"
      subtitle="Review proposed one-time-charges, and redirect to Shopify to confirm them."
      >
        {valid && <Card sectioned title="One-Time Charge"
          actions={[{
            content: <Button loading={isLoading} disabled={isLoading} primary>Confirm on Shopify</Button>,
            onAction: () => {
              setIsLoading(true);
              authFetch("/api/subscriptions/onetime", { json: { name: name, price: price, shop: shop } }).then((r) => r.json()).then((r) => {
                redirect(r.redirect_to);
              });
            }
          }]}>
          <MultiTable
            headings={["Name", "Amount", "Price", "Total"]}
            rows={[
              [name, 1, "$" + price + " USD", "$" + price + " USD"]
            ]}
          />
        </Card>}
        {!valid && (<Card sectioned title="One-Time Charge" actions={[{
          loading: isLoading,
          content: <Button primary>Create Landing Page</Button>,
          onAction: () => {
            const s = new URLSearchParams();
            s.append("name", potentialCharge.name);
            s.append("shop", potentialCharge.shop)
            s.append("price", potentialCharge.price)
            redirect("/subscriptions/onetime?" + s.toString());
          }}]}>
            <FormLayout>
              <TextField placeholder="Description of Charge" label="Name" value={potentialCharge.name || ""} onChange={(val) => setPotentialCharge({ ...potentialCharge, name: val })}/>
              <TextField type="number" label="Price" value={potentialCharge.price || "0"} onChange={(val) => setPotentialCharge({ ...potentialCharge, price: val })}/>
              <TextField label="Shop" value={potentialCharge.shop} onChange={(val) => setPotentialCharge({ ...potentialCharge, shop: val })}/>
            </FormLayout>
          </Card>)}
      </Page>
  );
};
